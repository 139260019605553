<template>
  <SidePanel>
    <PanelSection>
      <FormErrors :errors="errors" />

      <FormControl>
        <template #label>URL</template>
        <input v-model="form.url" class="input" name="url" />
      </FormControl>

      <FormControl>
        <template #label>Existing Agency</template>
        <select class="input" v-model="form.agencyId">
          <option v-for="agency of agencies" :value="agency.id">
            {{ agency.name }}
          </option>
        </select>
      </FormControl>

      <FormControl v-if="!form.agencyId">
        <template #label>New Agency</template>
        <input v-model="form.newAgencyName" class="input" />
      </FormControl>

      <FormControl class="mb-2">
        <template #label>Existing Group</template>
        <select class="input" v-model="form.groupId">
          <option v-for="group of availableGroups" :value="group.id">
            {{ group.name }}
          </option>
        </select>
      </FormControl>

      <FormControl v-if="!form.groupId">
        <template #label>New Group</template>
        <input v-model="form.newGroupName" class="input" />
      </FormControl>
    </PanelSection>

    <template #buttons-left>
      <SaveBtn :saveStatus="saveStatus" @save="save" />
    </template>
  </SidePanel>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import SidePanel from "../../global/SidePanel.vue";
import PanelSection from "../../global/SidePanel/PanelSection.vue";
import FormErrors from "../../global/FormErrors.vue";
import FormControl from "../../global/FormControl.vue";
import SaveBtn from "../../global/SaveBtn.vue";
import useModelApi from "../../global/useModelApi";

const props = defineProps<{
  form: types.PeopleScrapeForm;
  agencies: schema.Agency[];
  groups: types.Group[];
}>();

const emit = defineEmits<{
  (event: "create", peopleScrape: types.PeopleScrape): void;
}>();

const { errors, saveStatus, api } = useModelApi<schema.PeopleScrape>();

const save = async () => {
  const responseData = await api({
    method: "post",
    url: `dashboard/super_admin/api/people_scrapes.json`,
    data: {
      peopleScrape: { ...props.form, kind: "espn" },
      newGroupName: props.form.newGroupName,
      newAgencyName: props.form.newAgencyName,
    },
  });
  if (responseData) {
    emit("create", responseData.peopleScrape);
  }
};

const availableGroups = computed(() => {
  return props.groups.filter((group) => group.agencyId === props.form.agencyId);
});

watch(
  () => props.form.agencyId,
  () => {
    props.form.groupId = null;
  }
);
</script>
