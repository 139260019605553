<template>
  <TableRow
    :selectable="selectable"
    :selected="selected || multiSelected"
    @select="emit('select')"
  >
    <TableCell
      class="text-center cursor-pointer"
      @click.prevent.stop="emit('multiselect', !multiSelected)"
    >
      <input
        type="checkbox"
        :checked="multiSelected"
        class="cursor-pointer pointer-events-none accent-purple-500"
      />
    </TableCell>
    <TableCell class="whitespace-nowrap !px-1 font-medium sm:pl-2">
      <ReferenceFace
        :face="primaryReferenceFace"
        :s3="s3"
        :s3-bucket="s3Bucket"
        :key="primaryReferenceFace?.id"
        class="w-12 h-12 rounded"
        @credentialsexpired="emit('credentialsexpired')"
      />
    </TableCell>
    <TableCell class="whitespace-nowrap font-bold">
      {{ person.name }}
    </TableCell>
    <TableCell>
      {{ prefixes }}
    </TableCell>
    <TableCell>
      {{ postfixes }}
    </TableCell>
    <TableCell>
      {{ groupNames }}
    </TableCell>
    <TableCell class="whitespace-nowrap">
      {{ createdAgo }}
    </TableCell>
    <TableCell class="whitespace-nowrap text-base">
      <b>{{ personReferenceFaces.length }}</b
      ><small class="text-gray-400">/9</small>
    </TableCell>
  </TableRow>
</template>

<script setup lang="ts">
import { S3Client } from "@aws-sdk/client-s3";
import { computed } from "vue";
import ReferenceFace from "./ReferenceFace.vue";
import { timeAgo } from "../../../utility/time";
import TableRow from "../../global/AdminTable/TableRow.vue";
import TableCell from "../../global/AdminTable/TableCell.vue";

const props = defineProps<{
  person: types.Person;
  s3?: S3Client;
  s3Bucket: string;
  allFaces: schema.Face[];
  selected: boolean;
  selectable: boolean;
  groups: schema.Group[];
  multiSelected: boolean;
  filteredGroupIds: number[];
}>();

const emit = defineEmits<{
  (e: "credentialsexpired"): void;
  (e: "select"): void;
  (e: "multiselect", value: boolean): void;
}>();

const personReferenceFaces = computed(() =>
  props.allFaces
    .filter((f) => f.personId === props.person.id && !f.celebrityId)
    .sort((a, b) => a.id - b.id)
);

const primaryReferenceFace = computed(() => {
  return personReferenceFaces.value[0];
});

const groups = computed(() => {
  return props.person.groupMemberships
    .map((gm) => {
      return props.groups.find((g) => g.id === gm.groupId);
    })
    .filter(Boolean);
});

const createdAgo = computed(() => {
  return timeAgo(props.person.createdAt!);
});

const prefixes = computed(() => {
  if (
    props.filteredGroupIds.length &&
    props.person.groupMemberships.length >= 2
  ) {
    const groupPostfixes = props.person.groupMemberships
      .filter((gm) => props.filteredGroupIds.includes(gm.groupId))
      .map((gm) => gm.prefix)
      .filter(Boolean)
      .join(", ");
    return groupPostfixes || props.person.postfix;
  } else {
    return props.person.prefix;
  }
});

const postfixes = computed(() => {
  if (
    props.filteredGroupIds.length &&
    props.person.groupMemberships.length >= 2
  ) {
    const groupPostfixes = props.person.groupMemberships
      .filter((gm) => props.filteredGroupIds.includes(gm.groupId))
      .map((gm) => gm.postfix)
      .filter(Boolean)
      .join(", ");
    return groupPostfixes || props.person.postfix;
  } else {
    return props.person.postfix;
  }
});

const groupNames = computed(() => {
  return groups.value
    .map((g) => {
      if (g?.userLabel) {
        return `${g.name} (${g.userLabel})`;
      } else {
        return g?.name;
      }
    })
    .filter(Boolean)
    .sort()
    .join(", ");
});
</script>
