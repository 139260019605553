<template>
  <div
    data-name="Pagination"
    class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 select-none"
  >
    <div class="flex flex-1 justify-between sm:hidden">
      <a
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >Previous</a
      >
      <a
        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >Next</a
      >
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-400">
          <template v-if="totalCount && totalCount > 1">
            Showing
            <span class="text-gray-700 font-bold">
              {{ firstItem }}
              to
              {{ lastItem }}
            </span>
            of
            <span class="text-gray-700 font-bold">
              {{ totalCount }}
            </span>
            results
          </template>
          <template v-else-if="totalCount === 1">
            Showing <span class="text-gray-700 font-bold">1</span> result
          </template>
          <template v-else>
            Showing <span class="text-gray-700 font-bold">0</span> results
          </template>
        </p>
      </div>

      <div class="flex items-center gap-x-2">
        <div class="text-sm text-gray-600">page-size:</div>
        <div>
          <select
            name="pagination-page-size"
            class="input"
            :value="perPage"
            @change="handlePerChange($event)"
          >
            <!-- <option v-if="maxPerPage >= 1">1</option> -->
            <option v-if="maxPerPage >= 10">10</option>
            <option v-if="maxPerPage >= 25">25</option>
            <option v-if="maxPerPage >= 50">50</option>
            <option v-if="maxPerPage >= 100">100</option>
            <option v-if="maxPerPage >= 250">250</option>
          </select>
        </div>
      </div>
      <div>
        <nav
          class="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <div
            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 focus:z-20"
            :class="{
              'hover:bg-gray-50 cursor-pointer': currentPage > 1,
            }"
            @click.prevent="handlePreviousPage"
          >
            <span class="sr-only">Previous</span>
            <!-- Heroicon name: mini/chevron-left -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                clip-rule="evenodd"
              />
            </svg>
          </div>

          <template v-for="(page, i) in visiblePages">
            <a
              v-if="page > 1 && page - visiblePages[i - 1] > 1"
              class="page-button is-disabled"
              >...</a
            >
            <a
              class="page-button"
              :class="{ 'is-active': page === currentPage }"
              @click="emit('pagechange', page)"
              >{{ page }}</a
            >
          </template>
          <div
            class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 focus:z-20"
            :class="{
              'hover:bg-gray-50 cursor-pointer':
                totalPages && currentPage < totalPages,
            }"
            @click.prevent="handleNextPage"
          >
            <span class="sr-only">Next</span>
            <!-- Heroicon name: mini/chevron-right -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { uniq } from "lodash";
import { computed } from "vue";

const props = defineProps<{
  currentPage: number;
  perPage: number;
  totalPages?: number;
  totalCount?: number;
  maxPerPage: number;
}>();

const emit = defineEmits<{
  (event: "pagechange", page: number): void;
  (event: "perchange", perPage: number): void;
}>();

const firstItem = computed(() => {
  return (props.currentPage - 1) * props.perPage + 1;
});
const lastItem = computed(() => {
  if (!props.totalCount) return 0;
  return Math.min(props.currentPage * props.perPage, props.totalCount);
});

const visiblePages = computed(() => {
  if (!props.totalPages) return [];
  const startPages = [1, 2, 3];
  const endPages = [
    props.totalPages - 2,
    props.totalPages - 1,
    props.totalPages,
  ];

  const middlePages = [
    props.currentPage - 2,
    props.currentPage - 1,
    props.currentPage,
    props.currentPage + 1,
    props.currentPage + 2,
  ];

  return uniq(
    [...startPages, ...middlePages, ...endPages].filter(
      (page) => page > 0 && page <= props.totalPages!
    )
  );
});

const handleNextPage = () => {
  if (!props.totalPages) return;
  if (props.currentPage < props.totalPages) {
    emit("pagechange", Math.min(props.currentPage + 1, props.totalPages));
  }
};

const handlePreviousPage = () => {
  if (props.currentPage > 1) {
    emit("pagechange", Math.max(props.currentPage - 1, 1));
  }
};

const handlePerChange = (event: Event) => {
  const val = Number((event.target as HTMLSelectElement).value);
  emit("perchange", val);
  if (props.currentPage > 1) {
    emit("pagechange", 1);
  }
};
</script>

<style scoped lang="scss">
.page-button {
  @apply relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20;

  &.is-active {
    @apply z-10 border-purple-500 bg-purple-50 text-purple-600;
  }

  &.is-disabled {
    @apply hover:bg-white focus:z-10 cursor-default text-gray-400;
  }
}
</style>
