<template>
  <div
    class="relative flex justify-center items-center w-full h-[13px] bg-gray-200 rounded overflow-hidden ring-1 ring-black ring-opacity-10 px-3"
  >
    <div
      class="absolute h-full left-0"
      :class="{
        'bg-purple-300': percent <= 99,
        'bg-orange-300': percent >= 100,
      }"
      :style="`width: ${percent}%`"
    />
    <div class="z-10 text-gray-800 flex text-xs leading-none">
      {{ niceNumber(count) }} /
      {{ limit ? niceNumber(limit) : "∞" }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { niceNumber } from "../../utility/helpers";

const props = defineProps<{
  count: number;
  limit?: number | null;
}>();

const percent = computed(() => {
  if (!props.limit) return 0;
  return Math.min((props.count / props.limit) * 100, 100);
});
</script>
