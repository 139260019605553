<template>
  <div class="h-64">
    <LineChart :options="chartOptions" :data="processCounts" />
  </div>
</template>

<script setup lang="ts">
import { Line as LineChart } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Filler,
} from "chart.js";
import { computed } from "vue";
import { getRandomColor } from "../../../utility/color";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Filler
);

const props = defineProps<{
  processCounts: types.DateData;
  userProcessCounts: Record<string, types.DateData>;
}>();

const datasets = computed(() => {
  if (Object.keys(props.userProcessCounts).length > 0) {
    return Object.entries(props.userProcessCounts).map(([userId, data]) => {
      const color = getRandomColor(userId, 0.7, 0.4, 0.75);
      return {
        label: userId,
        data: data.map((entry) => entry[1]),
        backgroundColor: "transparent",
        borderColor: color,
        fill: "start",
      };
    });
  } else {
    return [
      {
        label: "All users",
        data: props.processCounts.map((entry) => entry[1]),
        backgroundColor: "#9f46fe11",
        borderColor: "#9f46febb",
        fill: "start",
      },
    ];
  }
});

const processCounts = computed(() => {
  return {
    labels: props.processCounts.map((entry) => entry[0]),
    datasets: datasets.value,
  };
});

const chartOptions = computed(() => {
  return {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
});
</script>
