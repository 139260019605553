<template>
  <div class="flex items-center flex-shrink-0 gap-x-3">
    <Btn
      class="flex-shrink-0"
      @click="emit('save')"
      :busy="saveStatus === 'saving'"
      :disabled="disabled"
      v-bind="$attrs"
      ><i class="fa fa-save mr-1" /> {{ label }}</Btn
    >

    <SaveStatus :status="saveStatus" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Btn from "./Btn.vue";
import SaveStatus from "./SaveStatus.vue";

const props = defineProps<{
  saveStatus: types.SaveStatus;
  disabled?: boolean;
  label?: string;
}>();

defineOptions({
  inheritAttrs: false,
});

const label = computed(() => props.label ?? "Save");

const emit = defineEmits<{
  (e: "save"): void;
}>();
</script>
