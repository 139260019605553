<template>
  <div>
    <div class="text-xl font-bold mb-6">
      {{ billingAccount.agencyId ? "Agency" : "" }} AI Process Usage History
    </div>
    <AdminTable
      :pagination="pagination"
      :max-per-page="100"
      @paginate="paginate"
    >
      <template #head>
        <TableHeader>Start Date</TableHeader>
        <TableHeader>End Date</TableHeader>
        <TableHeader>AI Processes</TableHeader>
        <TableHeader>Included AI Processes</TableHeader>
        <TableHeader></TableHeader>
      </template>

      <template #body>
        <BillingPeriodRow
          :billing-period="billingPeriod"
          :billing-account="billingAccount"
          :key="billingPeriod.id"
          :index="i"
          v-for="(billingPeriod, i) of paginatedBillingPeriods"
        />
      </template>
    </AdminTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { orderedByTimeField } from "../../utility/time";
import AdminTable from "../global/AdminTable.vue";
import BillingPeriodRow from "./BillingPeriodRow.vue";
import TableHeader from "../global/AdminTable/TableHeader.vue";

const props = defineProps<{
  billingAccount: types.BillingAccount;
}>();

const pagination = ref<types.Pagination>({
  currentPage: 1,
  perPage: 25,
  totalCount: props.billingAccount.billingPeriods.length,
  totalPages: Math.ceil(props.billingAccount.billingPeriods.length / 25),
});

const paginate = () => {
  pagination.value.totalPages = Math.ceil(
    props.billingAccount.billingPeriods.length / pagination.value.perPage
  );
};

const orderedPeriods = computed(() => {
  return orderedByTimeField(props.billingAccount.billingPeriods, "startAt");
});

const paginatedBillingPeriods = computed(() => {
  const start = (pagination.value.currentPage - 1) * pagination.value.perPage;
  const end = start + pagination.value.perPage;
  return orderedPeriods.value.slice(start, end);
});
</script>

<style scoped></style>
