import { camelizeKeys } from "../utility/helpers";
import { Component, createApp } from "vue";

let mountedApps: Record<string, boolean> = {};

export default function mountApp(name: string, RootComponent: Component) {
  if (mountedApps[name]) return;
  const el = document.getElementById(name);
  if (!el) return;

  const props: Record<string, any> = {};
  // Takes all the props in the data of the element and gets them with the correct prop names
  // Eg. takes data-vue-prop-name=val and puts it into { name: val }
  for (const key of Object.keys(el.dataset)) {
    if (!key.includes("vueProp")) continue;

    const value = el.dataset[key];
    if (typeof value === "undefined") continue;

    let propName = key.replace("vueProp", "");
    propName = `${propName[0].toLowerCase()}${propName.slice(1)}`;
    try {
      props[propName] = camelizeKeys(JSON.parse(value));
    } catch {
      props[propName] = value;
    }
  }

  const app = createApp(
    RootComponent,
    camelizeKeys(props) as Record<string, any>
  );

  app.mount(el);
  mountedApps[name] = true;
}
