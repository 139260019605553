<template>
  <div class="relative" data-name="GroupSelect">
    <div class="relative rounded-md shadow-sm w-full">
      <input
        class="input !pr-10"
        v-model="query"
        @focus="showResults = true"
        @blur="resetQuery()"
        :placeholder="
          disabled ? '' : placeholder ?? 'Search or create a new group'
        "
        :disabled="disabled"
      />

      <div
        class="absolute inset-y-0 right-0 flex items-center mr-4 text-gray-500"
      >
        <Loader v-if="creating" class="pr-4" />
        <div
          v-if="!creating && query"
          class="px-1 hover:opacity-80 transition-colors cursor-pointer"
        >
          <i class="fa fa-times text-lg select-none" @click="clearGroup()" />
        </div>
      </div>
    </div>

    <GroupPanel
      v-if="showGroupPanel"
      :group="groupForm"
      :axios="axios"
      @save="handleNewGroup"
      @close="showGroupPanel = false"
      class="w-full"
    />

    <div
      class="absolute bg-white rounded shadow-lg shadow-black/30 z-20 w-full overflow-hidden"
      data-name="GroupSelect->Results"
      v-else-if="showResults"
    >
      <Btn
        class="mt-2 w-full"
        size="small"
        color="primary"
        @mousedown="showNewGroupModal()"
      >
        <i class="fa fa-plus-circle mr-0.5" /> Create new
      </Btn>
      <div
        v-if="!filteredGroups.length"
        class="px-4 py-2 flex justify-center text-gray-400 text-sm mt-2"
      >
        No existing groups found
      </div>
      <div
        class="divide-y divide-gray-200 overflow-hidden max-h-96 overflow-y-auto"
      >
        <div
          v-for="group of filteredGroups"
          :key="group.id"
          @mousedown="selectExistingGroup(group)"
          class="text-gray-700 px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
          data-name="GroupSelect->Group"
        >
          <div>
            <div>
              {{ group.name }}
            </div>
            <div v-if="group.userLabel" class="text-xs text-gray-500">
              ({{ group.userLabel }})
            </div>
          </div>
          <div
            class="text-gray-500 px-2 h-6 text-sm bg-gray-200 rounded-full flex items-center justify-center"
          >
            {{ group.peopleCount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AxiosInstance } from "axios";
import { computed, nextTick, ref, watch } from "vue";
import Btn from "../../../global/Btn.vue";
import Loader from "../../../global/Loader.vue";
import { compareTwoStrings } from "string-similarity";
import GroupPanel from "../GroupPanel.vue";

const props = defineProps<{
  axios: AxiosInstance;
  groups: types.Group[];
  allowedGroupIds?: number[];
  disabled?: boolean;
  placeholder?: string;
}>();

const selectedGroupId = defineModel<number | undefined>();

const creating = ref(false);
const showResults = ref(false);
const query = ref("");
const showGroupPanel = ref(false);
const groupForm = ref({
  name: "",
  userLabel: "",
});

const selectedGroup = computed(() => {
  return props.groups.find((group) => group.id === selectedGroupId.value);
});

const filteredGroups = computed(() => {
  return props.groups
    .filter((group) => {
      if (props.allowedGroupIds) {
        return props.allowedGroupIds.includes(group.id);
      }
      return true;
    })
    .filter((group) =>
      query.value ? compareTwoStrings(group.name, query.value) > 0.1 : true
    )
    .sort((a, b) => {
      let aName = `${a.name}`;
      if (a.userLabel) aName += ` (${a.userLabel})`;
      let bName = `${b.name}`;
      if (b.userLabel) bName += ` (${b.userLabel})`;
      return (
        compareTwoStrings(bName, query.value) -
        compareTwoStrings(aName, query.value)
      );
    });
});

const loadQueryFromSelectedGroup = () => {
  query.value = selectedGroup.value?.name ?? "";
  if (selectedGroup.value?.userLabel)
    query.value += ` (${selectedGroup.value.userLabel})`;
};

watch(
  selectedGroup,
  () => {
    loadQueryFromSelectedGroup();
  },
  { immediate: true }
);

const selectExistingGroup = (group: schema.Group) => {
  selectedGroupId.value = group.id;
  showResults.value = false;
};

const clearGroup = () => {
  selectedGroupId.value = undefined;
};

const resetQuery = () => {
  if (creating.value) return;
  nextTick(() => {
    loadQueryFromSelectedGroup();
    showResults.value = false;
  });
};

const showNewGroupModal = () => {
  showGroupPanel.value = true;
  showResults.value = false;
  groupForm.value.name = query.value;
};

const handleNewGroup = (group: types.Group) => {
  props.groups.push(group);
  selectedGroupId.value = group.id;
  showGroupPanel.value = false;
};
</script>
