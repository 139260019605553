<template>
  <TableRow :selectable="false">
    <TableCell>
      {{ niceDate(billingPeriod.startAt) }}
    </TableCell>
    <TableCell>
      {{ niceDate(billingPeriod.endAt) }}
    </TableCell>
    <TableCell>
      {{ niceNumber(billingPeriod.aiProcessesCount) }}
    </TableCell>
    <TableCell>
      {{
        billingPeriod.includedAiProcesses
          ? niceNumber(billingPeriod.includedAiProcesses)
          : ""
      }}
    </TableCell>
    <TableCell>
      <div class="flex items-center gap-2">
        <a
          v-if="billingPeriod.invoice"
          :href="billingPeriod.invoice.pdfUrl!"
          class="underline whitespace-nowrap flex items-center gap-1"
          target="_blank"
          >Invoice of £{{ totalAmount }}
          <ArrowTopRightOnSquareIcon class="w-3 h-3" />
        </a>

        <div
          v-if="billingPeriod.invoice?.status === 'paid'"
          class="rounded bg-purple-200 text-purple-700 px-1.5 text-xs py-px flex items-center gap-1"
        >
          <CheckCircleIcon class="w-3 h-3" /> Paid
        </div>
        <div
          class="rounded bg-red-200 text-red-700 px-1.5 text-xs py-px flex items-center gap-1"
          v-else-if="!!billingPeriod.invoice?.chargeFailureMessage"
        >
          <XCircleIcon class="w-3 h-3" />
          {{ billingPeriod.invoice.chargeFailureMessage }}
        </div>

        <span
          v-if="billingPeriod.freeTrial"
          class="bg-blue-200 text-blue-800 text-xs py-px rounded px-2 flex justify-start gap-1 items-center"
          ><CurrencyPoundIcon class="w-3 h-3" /> Free Trial</span
        >
        <span
          v-if="willRenew"
          class="bg-blue-200 text-blue-800 text-xs py-px rounded px-2 flex justify-start gap-1 items-center"
          ><ArrowPathIcon class="w-3 h-3" /> Renews in
          {{ renewDays }} days</span
        >
      </div>
    </TableCell>
  </TableRow>
</template>

<script setup lang="ts">
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  CurrencyPoundIcon,
  XCircleIcon,
} from "@heroicons/vue/24/solid";
import { niceDate } from "../../utility/time";
import { computed } from "vue";
import { differenceInDays, startOfToday } from "date-fns";
import TableRow from "../global/AdminTable/TableRow.vue";
import TableCell from "../global/AdminTable/TableCell.vue";
import { niceNumber } from "../../utility/helpers";

const props = defineProps<{
  billingPeriod: types.BillingPeriod;
  billingAccount: types.BillingAccount;
  index: number;
}>();

const endsInFuture = computed(() => {
  if (!props.billingPeriod) return false;

  const today = startOfToday();
  const endAt = new Date(props.billingPeriod.endAt);

  return today <= endAt;
});

const willRenew = computed(() => {
  return (
    !!props.billingAccount.periodInterval &&
    endsInFuture.value &&
    props.index === 0
  );
});

const renewDays = computed(() => {
  if (!props.billingPeriod) return null;

  const today = startOfToday();
  const endAt = new Date(props.billingPeriod.endAt);

  return differenceInDays(endAt, today);
});

const paid = computed(() => {
  return props.billingPeriod.invoice?.status === "paid";
});

const totalAmount = computed(() => {
  if (!props.billingPeriod.invoice) return null;
  const amountInPence = props.billingPeriod.invoice.invoiceItems.reduce(
    (acc, item) => acc + item.amount,
    0
  );

  return (amountInPence / 100).toFixed(2);
});
</script>

<style scoped></style>
