import { ref, watch } from "vue";

type Config = {
  initialStatus?: types.SaveStatus;
  timeout?: number;
};

export default function useSaveStatus(config?: Config) {
  const initialStatus = config?.initialStatus ?? "idle";
  const timeout = config?.timeout ?? 4000;

  const saveStatus = ref<types.SaveStatus>(initialStatus);

  let timeoutId: number | null = null;

  watch(saveStatus, (newStatus) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }

    if (newStatus === "success" || newStatus === "error") {
      timeoutId = setTimeout(() => {
        saveStatus.value = "idle";
      }, timeout);
    }
  });

  return saveStatus;
}
