<template>
  <div class="relative">
    <div
      v-if="fetchStatus === 'fetching'"
      class="absolute inset-0 flex justify-center items-center z-10"
    >
      <Loader shade="dark" />
    </div>
    <div
      v-else-if="fetchStatus === 'error'"
      class="absolute inset-0 flex justify-center items-center text-red-700 text-sm z-10"
    >
      <div class="flex justify-start items-center gap-0.5">
        <XMarkIcon class="w-4 h-4" />
        Error loading data
      </div>
    </div>

    <div
      v-if="slots.default"
      :class="{ 'blur-sm opacity-50': fetchStatus !== 'idle' }"
      class="transition-all duration-200"
      v-bind="$attrs"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from "vue";
import Loader from "./Loader.vue";
import { XMarkIcon } from "@heroicons/vue/24/solid";

defineProps<{
  fetchStatus: types.FetchStatus;
}>();

defineOptions({
  inheritAttrs: false,
});

const slots = useSlots();
</script>
