<template>
  <button :class="classes" :disabled="disabled" @click="handleClick">
    <Loader v-if="busy" :shade="loaderShade" class="mr-1 ml-2" />
    <span :class="textClasses">
      <slot />
    </span>
  </button>
</template>

<script setup lang="ts">
import Loader from "./Loader.vue";
import useBtn, { BtnFill } from "./useBtn";
const emit = defineEmits<{
  (e: "click"): void;
}>();

const props = defineProps<{
  color?: types.Color;
  fill?: BtnFill;
  size?: types.Size;
  disabled?: boolean;
  busy?: boolean;
}>();

const { classes, textClasses, loaderShade } = useBtn(props);

const handleClick = () => {
  if (!props.disabled && !props.busy) emit("click");
};
</script>
