<template>
  <TableRow
    :selectable="true"
    :selected="props.selected"
    @select="emit('select')"
  >
    <TableCell class="font-bold">
      {{ peopleScrape.url }}
    </TableCell>
    <TableCell class="font-bold">
      {{ peopleScrape.kind }}
    </TableCell>
    <TableCell>
      {{ peopleScrape.agency?.name }}
    </TableCell>
    <TableCell>
      {{ peopleScrape.group?.name }}
    </TableCell>
    <TableCell
      :class="{
        '!text-gray-500': peopleScrape.status === 'pending',
        '!text-green-500': peopleScrape.status === 'complete',
        '!text-yellow-500': peopleScrape.status === 'in_progress',
        '!text-red-500': peopleScrape.status === 'failed',
      }"
    >
      {{ peopleScrape.status }}
    </TableCell>
    <TableCell>
      {{ importedPeople.length }} /
      {{ props.peopleScrape.scrapedPeople.length }}
    </TableCell>
    <TableCell class="whitespace-nowrap">
      {{ timeAgo(peopleScrape.createdAt) }}
    </TableCell>
  </TableRow>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { timeAgo } from "../../../utility/time";
import TableRow from "../../global/AdminTable/TableRow.vue";
import TableCell from "../../global/AdminTable/TableCell.vue";

const props = defineProps<{
  peopleScrape: types.PeopleScrape;
  selected: boolean;
}>();

const emit = defineEmits<{
  (e: "select"): void;
}>();

const importedPeople = computed(() => {
  return props.peopleScrape.scrapedPeople.filter(
    (p) => p.status === "complete"
  );
});
</script>
