<template>
  <div
    data-name="Group"
    class="px-3 py-2 cursor-pointer hover:bg-purple-100/50 rounded-xl flex justify-between gap-x-4 items-center"
    :class="{
      'bg-purple-100/50': showEditPanel,
    }"
    @click="toggle()"
    ref="el"
  >
    <div class="flex justify-start gap-x-3">
      <input
        type="checkbox"
        class="cursor-pointer accent-purple-500"
        :checked="active"
      />
      <div class="font-bold">
        {{ group.name }}
        <div class="text-xs text-gray-500 font-normal" v-if="group.userLabel">
          ({{ group.userLabel }})
        </div>
      </div>
    </div>

    <div class="flex gap-x-2 items-center">
      <div
        class="text-xs rounded-full bg-gray-200 w-5 h-5 justify-center items-center flex text-gray-500"
      >
        {{ group.peopleCount }}
      </div>

      <div
        class="rounded p-2 hover:bg-gray-200 transition-all hover:text-gray-600 text-gray-400"
        @click.stop="showEditPanel = true"
      >
        <PencilSquareIcon class="w-5 h-5" />
      </div>
    </div>
  </div>
  <Teleport to="body">
    <GroupPanel
      v-if="showEditPanel"
      :group="group"
      :axios="axios"
      @delete="emit('delete')"
      @save="handleUpdate"
      @close="showEditPanel = false"
      class="w-96"
      :style="editPanelStyles"
    />
  </Teleport>
</template>

<script setup lang="ts">
import { PencilSquareIcon } from "@heroicons/vue/24/solid";
import { AxiosInstance } from "axios";
import { computed, ref } from "vue";
import GroupPanel from "../../GroupPanel.vue";
import { useElementBounding } from "@vueuse/core";

const props = defineProps<{
  group: types.Group;
  active: boolean;
  axios: AxiosInstance;
}>();

const emit = defineEmits<{
  (e: "activate"): void;
  (e: "deactivate"): void;
  (e: "delete"): void;
  (e: "update", group: types.Group): void;
}>();

const toggle = () => {
  props.active ? emit("deactivate") : emit("activate");
};

const el = ref(null);
const boundingBox = useElementBounding(el);
const showEditPanel = ref(false);

const editPanelStyles = computed(() => {
  return {
    top: `${boundingBox.top.value}px`,
    left: `${boundingBox.right.value}px`,
  };
});

const handleUpdate = (group: types.Group) => {
  emit("update", group);
  showEditPanel.value = false;
};
</script>
