import { times } from "lodash";

export const cpuCount = Math.max(
  2,
  Math.floor((navigator.hardwareConcurrency ?? 4) / 2)
);

export async function runParallel<T>(
  items: T[],
  fn: (item: T) => Promise<void>,
  max?: number
): Promise<void> {
  if (!max) max = cpuCount;

  const promises = times(max, async () => {
    let item = items.shift();
    while (item) {
      await fn(item);
      item = items.shift();
    }
  });

  await Promise.all(promises);
}

// This is how we get around insane CORS issues with Web Workers
const workerBlobs = new Map<string, string>();
export async function createWorker(href: string) {
  if (workerBlobs.has(href)) {
    return new Worker(workerBlobs.get(href)!);
  } else {
    const response = await fetch(href);
    const blobUrl = URL.createObjectURL(await response.blob());
    workerBlobs.set(href, blobUrl);
    return new Worker(blobUrl);
  }
}
