<template>
  <div
    :style="styles"
    :class="classes"
    class="w-full h-full relative reference-face"
  >
    <div class="absolute inset-0 flex-center">
      <Loader v-if="!referenceFaceUrl" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { computed, ref, watch } from "vue";
import Loader from "../../global/Loader.vue";

const props = defineProps<{
  s3?: S3Client;
  s3Bucket?: string;
  face: Partial<types.Face> | null;
}>();

const emit = defineEmits<{
  (e: "credentialsexpired"): void;
}>();

const referenceFaceUrl = ref<string | null>(null);
const downloading = ref(false);

const downloadReferenceFace = async () => {
  if (!props.face?.s3Name || !props.s3 || !props.s3Bucket) return;
  if (referenceFaceUrl.value) return;
  if (downloading.value) return;

  downloading.value = true;

  const command = new GetObjectCommand({
    Bucket: props.s3Bucket,
    Key: props.face.s3Name,
  });
  let response;

  try {
    response = await props.s3.send(command);
  } catch (e: any) {
    downloading.value = false;

    if (e.message && e.message.match(/.*(expired|token).*/i)) {
      emit("credentialsexpired");
      return;
    } else {
      throw e;
    }
  }

  const reader = (response.Body as ReadableStream).getReader();

  let chunks: string[] = [];
  let done, value;
  while (!done) {
    ({ value, done } = await reader.read());
    if (value) chunks = chunks.concat(Array.from(value));
  }
  const string = chunks.map((c) => String.fromCharCode(c)).join("");
  referenceFaceUrl.value = `data:image/jpeg;base64,${btoa(string)}`;
  downloading.value = false;
};

watch(() => props.s3, downloadReferenceFace, { immediate: true });
watch(() => props.face, downloadReferenceFace, { immediate: true });

const classes = computed(() => {
  return {
    "bg-cover bg-center": referenceFaceUrl.value,
    "bg-gray-200": !referenceFaceUrl.value,
  };
});

const styles = computed(() => {
  if (referenceFaceUrl.value) {
    return {
      backgroundImage: `url(${referenceFaceUrl.value})`,
    };
  } else {
    return {};
  }
});
</script>
