<template>
  <div v-if="slots.label"><slot name="label" /></div>
  <div v-else class="text-gray-500" :title="title">{{ label }}:</div>
  <div :title="title" v-bind="$attrs"><slot /></div>
</template>

<script setup lang="ts">
import { useSlots } from "vue";

defineProps<{
  label?: string;
  title?: string;
}>();

defineOptions({
  inheritAttrs: false,
});

const slots = useSlots();
</script>
