<template>
  <TableRow :selectable="true" :selected="selected" @select="emit('select')">
    <TableCell class="whitespace-nowrap font-bold">
      {{ agency.name }}
    </TableCell>
    <TableCell
      :class="{
        'text-orange-400':
          agency.userLimit && agency.usersCount === agency.userLimit,
        'text-red-600 font-bold':
          agency.userLimit && agency.usersCount > agency.userLimit,
      }"
    >
      {{ agency.usersCount }} / {{ agency.userLimit ?? "∞" }}
    </TableCell>
    <TableCell
      :class="{
        'text-orange-400': peopleLimitPercent > 66 && peopleLimitPercent < 100,
        'text-red-600 font-bold': peopleLimitPercent >= 100,
      }"
    >
      {{ niceNumber(agency.peopleCount ?? agency.peopleCount ?? 0) }} /
      {{ agency.peopleLimit ? niceNumber(agency.peopleLimit) : "∞" }}
      <span class="opacity-60" v-if="agency.peopleLimit"
        >({{ Math.round(peopleLimitPercent) }}%)</span
      >
    </TableCell>
    <TableCell>
      <div v-if="agency.currentPeriod" class="flex items-center gap-2">
        <ProgressBar
          :count="agency.currentPeriod.aiProcessesCount"
          :limit="agency.currentPeriod.includedAiProcesses"
        />
        <span class="text-gray-500 text-xs flex-shrink-0"
          >({{ Math.round(agency.currentPeriod.percentComplete) }}%)</span
        >
      </div>
    </TableCell>
    <TableCell class="whitespace-nowrap">
      {{ timeAgo(agency.createdAt) }}
    </TableCell>
  </TableRow>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { timeAgo } from "../../../utility/time";
import TableCell from "../../global/AdminTable/TableCell.vue";
import TableRow from "../../global/AdminTable/TableRow.vue";
import { niceNumber } from "../../../utility/helpers";
import ProgressBar from "../../global/ProgressBar.vue";

const props = defineProps<{
  agency: types.Agency;
  selected: boolean;
}>();

const emit = defineEmits<{
  (e: "select"): void;
}>();

const peopleLimitPercent = computed(() => {
  if (!props.agency.peopleLimit) return 0;
  return Math.min(
    (props.agency.peopleCount / props.agency.peopleLimit) * 100,
    100
  );
});
</script>
