import axios, { AxiosInstance } from "axios";
import applyConverters from "axios-case-converter";

interface Config {
  baseUrl?: string;
  headers?: {
    uid?: string;
    accessToken?: string;
    client?: string;
  };
}

export default function createClient(config: Config = {}): AxiosInstance {
  const baseUrl = config.baseUrl ?? "/";
  const headers = config.headers ?? {};
  const axiosConfig = {
    baseURL: baseUrl,
    headers,
  };
  const client = applyConverters(axios.create(axiosConfig));

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      console.error(error);
      if (error?.response?.status === 401 || error?.response?.status === 402) {
        // API token has expired, reload the page to refresh their API token
        window.location.reload();
      } else {
        throw error;
      }
    }
  );

  return client;
}
