<template>
  <div
    data-name="BulkPeopleImage"
    class="bg-white rounded overflow-hidden shadow-sm relative flex flex-col select-none"
    :class="{
      'ring-4 ring-red-500/30 ring-offset-0':
        !image.personName && shouldShowErrorBorder,
    }"
    :style="{
      width: `138px`,
      height: image.status === 'success' ? `180px` : `220px`,
    }"
  >
    <LoadingBar
      v-if="image.status === 'processing'"
      class="absolute w-full top-0 left-0 right-0 z-10"
    />
    <XCircleIcon
      v-if="
        (image.status === 'pending' && !processing) || image.status === 'error'
      "
      class="absolute top-px right-px w-6 h-6 text-slate-200/50 cursor-pointer hover:text-red-500/90 transition-all z-10"
      @click="emit('remove')"
      role="button"
    />
    <div
      class="bg-black flex justify-center items-center overflow-hidden relative flex-shrink-0"
      :class="{
        'h-[138px]': image.status === 'success',
        'h-44': image.status !== 'success',
      }"
    >
      <ReferenceFace
        :s3="s3"
        :s3Bucket="s3Bucket"
        :face="image.face"
        v-if="image.face"
      />
      <img :src="imageSrc" class="max-h-full" :title="image.filename" v-else />

      <div
        v-if="image.status === 'error'"
        class="max-h-16 p-1 bg-red-200 text-red-800 text-xs absolute bottom-0 w-full overflow-y-auto overflow-x-hidden"
      >
        {{ image.errorMessage }}
      </div>
    </div>
    <div class="p-0 flex-grow">
      <textarea
        v-model="image.personName"
        class="input is-sm resize-none h-full !leading-4 !rounded-t-none"
        :class="{ '!border-red-500': !image.personName }"
        :disabled="image.status !== 'pending' || processing"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { BulkImportImage } from "../BulkPeople.vue";
import { XCircleIcon } from "@heroicons/vue/24/solid";
import ReferenceFace from "../People/ReferenceFace.vue";
import { S3Client } from "@aws-sdk/client-s3";
import LoadingBar from "../../global/LoadingBar.vue";

const props = defineProps<{
  image: BulkImportImage;
  file: File | Blob;
  s3: S3Client;
  s3Bucket: string;
  processing: boolean;
  shouldShowErrorBorder: boolean;
}>();

const emit = defineEmits<{
  (event: "remove"): void;
}>();

const imageSrc = computed(() => {
  return URL.createObjectURL(props.file);
});
</script>
