<template>
  <AdminLayout>
    <div class="space-y-6 max-w-3xl ml-auto mr-auto">
      <div>
        <div class="font-bold text-2xl">Account Management</div>
        <div class="text-gray-500">{{ user.email }}</div>
      </div>

      <div class="flex gap-4">
        <div
          v-if="user.agency"
          class="rounded border border-gray-200 flex justify-start bg-white divide-gray-200 divide-x shadow-lg basis-1/2"
        >
          <div
            class="basis-12 flex-shrink flex-grow-0 flex items-center justify-center p-6"
          >
            <BuildingOffice2Icon class="w-8 h-8" />
          </div>
          <div class="flex-grow flex flex-col justify-center px-4">
            <div class="text-xs font-bold text-gray-400 uppercase">Agency:</div>
            <div class="font-bold text-lg">
              {{ user.agency.name }}
            </div>
          </div>
        </div>

        <div
          class="rounded border border-gray-200 flex justify-start bg-white divide-gray-200 divide-x shadow-lg basis-1/2"
          v-if="user.fullReasonCantUseApp"
        >
          <div
            class="basis-12 flex-shrink flex-grow-0 flex items-center justify-center p-6"
          >
            <ExclamationTriangleIcon class="w-8 h-8 text-red-600" />
          </div>
          <div class="flex-grow flex flex-col justify-center px-4 py-3 gap-1">
            <div class="text-xs font-bold text-gray-400 uppercase">
              Account Status:
            </div>
            <div class="leading-tight">
              {{ user.fullReasonCantUseApp }}
            </div>
          </div>
        </div>

        <div
          class="rounded border border-gray-200 flex justify-start bg-white divide-gray-200 divide-x shadow-lg basis-1/2"
          v-else-if="markedForCancellation"
        >
          <div
            class="basis-12 flex-shrink flex-grow-0 flex items-center justify-center p-6"
          >
            <ExclamationTriangleIcon class="w-8 h-8 text-red-600" />
          </div>
          <div class="flex-grow flex flex-col justify-center px-4 py-3 gap-1">
            <div class="text-xs font-bold text-gray-400 uppercase">
              Account Status:
            </div>
            <div class="leading-tight">
              Your subscription will end on
              <b>{{ new Date(currentPeriod!.endAt).toLocaleDateString() }}</b>
            </div>
          </div>
        </div>

        <div
          class="rounded border border-gray-200 flex justify-start bg-white divide-gray-200 divide-x shadow-lg basis-1/2"
          v-else
        >
          <div
            class="basis-12 flex-shrink flex-grow-0 flex items-center justify-center p-6"
          >
            <CheckCircleIcon class="w-8 h-8 text-purple-600" />
          </div>
          <div class="flex-grow flex flex-col justify-center px-4 py-3">
            <div class="text-xs font-bold text-gray-400 uppercase">
              Account Status:
            </div>
            <div class="leading-tight font-bold text-lg">Active</div>
          </div>
        </div>
      </div>

      <div class="flex gap-4 flex-wrap">
        <Btn v-if="canResumeSub" @click="resumeSub" color="primary"
          ><ArrowPathIcon class="w-4 h-4 mr-1" /> Resume Subscription</Btn
        >
        <Link
          v-if="canManageStripe"
          href="/dashboard/stripe_billing_portal/edit"
          color="primary"
          ><CreditCardIcon class="w-4 h-4 mr-1" /> Manage payment details with
          stripe</Link
        >
        <Btn
          v-if="canCancelSub"
          color="danger"
          fill="outline"
          @click="cancelSub"
          :disabled="fetchStatus === 'fetching'"
          ><XCircleIcon class="w-4 h-4 mr-1" /> Cancel Subscription</Btn
        >
      </div>

      <BillingAccount
        v-if="user.billingAccount"
        :billingAccount="user.billingAccount"
      />
    </div>
  </AdminLayout>
</template>

<script setup lang="ts">
import {
  BuildingOffice2Icon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/vue/24/outline";
import AdminLayout from "./global/AdminLayout.vue";
import Link from "./global/Link.vue";
import { computed, ref } from "vue";
import { ArrowPathIcon, CreditCardIcon } from "@heroicons/vue/24/solid";
import Btn from "./global/Btn.vue";
import useFetchApi from "./global/useFetchApi";
import BillingAccount from "./Account/BillingAccount.vue";

const props = defineProps<{
  user: types.AccountUser;
}>();

const user = ref(props.user);

const canResumeSub = computed(() => {
  return (
    (user.value.fullReasonCantUseApp &&
      !user.value.agency &&
      user.value.status !== "invoice_unpaid") ||
    markedForCancellation.value
  );
});

const canManageStripe = computed(() => {
  return (
    !user.value.agency &&
    user.value?.billingAccount?.stripeCustomerId &&
    user.value.stripeCustomerPortalUrl &&
    !markedForCancellation.value &&
    (!user.value.fullReasonCantUseApp || user.value.status === "invoice_unpaid")
  );
});

const canCancelSub = computed(() => {
  return (
    user.value.status !== "free_trial" &&
    !user.value.agency &&
    !user.value.fullReasonCantUseApp &&
    !markedForCancellation.value
  );
});

// If the user is active, billed manually, during a period, but the period will not refresh, then they are marked for cancellation
const markedForCancellation = computed(() => {
  if (!user.value.billingAccount) return false;

  return !!(
    user.value.status === "active" &&
    !!user.value.billingAccount.automaticInvoicingStartDate &&
    !user.value.billingAccount.periodInterval &&
    currentPeriod.value
  );
});

const currentPeriod = computed(() => {
  return user.value.billingAccount?.billingPeriods.find(
    (period) =>
      new Date(period.startAt) < new Date() &&
      new Date(period.endAt) > new Date()
  );
});

const { fetchStatus, fetchApi } = useFetchApi();

const cancelSub = async () => {
  let message =
    "Are you sure you want to cancel your subscription? You may lose access to your facial recognition database";
  if (
    currentPeriod.value &&
    !!user.value.billingAccount?.automaticInvoicingStartDate
  ) {
    message += `. You will no longer have access after ${new Date(
      currentPeriod.value!.endAt
    ).toLocaleDateString()}.`;
  }
  if (confirm(message)) {
    const responseData = await fetchApi({
      url: `dashboard/api/account`,
      method: "delete",
    });

    if (responseData) {
      user.value = responseData.user;
    } else {
      alert(
        "There was an error cancelling your subscription. Please try again. If it continues to fail please contact support."
      );
    }
  }
};

const resumeSub = async () => {
  if (markedForCancellation.value) {
    if (
      confirm(
        "Are you sure you want to resume your subscription? You will be billed as normal at the end of your current billing period."
      )
    ) {
      const responseData = await fetchApi({
        url: `dashboard/api/account/resume`,
        method: "post",
      });

      if (responseData) {
        user.value = responseData.user;
      } else {
        alert(
          "There was an error resuming your subscription. Please try again. If it continues to fail please contact support."
        );
      }
    }
  } else {
    window.location.href = "/checkout/new";
  }
};
</script>
