<template>
  <SidePanel>
    <PanelSection v-if="errors">
      <FormErrors :errors="errors" />
    </PanelSection>

    <PanelSection>
      <div class="mb-1" v-if="peopleScrape.url">
        <a :href="peopleScrape.url" class="underline">{{ peopleScrape.url }}</a>
      </div>

      <div class="mb-1">
        <span class="text-gray-400">Agency:</span>
        {{ peopleScrape.agency.name }}
      </div>

      <div class="mb-1">
        <span class="text-gray-400">Group:</span>
        {{ peopleScrape.group?.name }}
      </div>

      <div class="mb-1">
        <span class="text-gray-400">Status:</span>
        {{ peopleScrape.status }}
      </div>

      <div class="mb-1" v-if="peopleScrape.errorMessage">
        <span class="text-gray-400">Error:</span>
        {{ peopleScrape.errorMessage }}
      </div>
    </PanelSection>

    <PanelSection>
      <div class="mb-2">Added ({{ importedPeople?.length ?? 0 }})</div>
      <div
        class="max-h-96 overflow-y-auto text-sm bg-gray-800 p-1 rounded text-white"
      >
        <div v-for="importedPerson of importedPeople">
          {{ importedPerson.name }}
        </div>
      </div>
    </PanelSection>

    <PanelSection>
      <div class="mb-2">Skipped ({{ skippedPeople?.length ?? 0 }})</div>
      <div
        v-if="skippedPeople?.length > 0"
        class="max-h-96 overflow-y-auto text-sm bg-gray-800 p-1 rounded text-white"
      >
        <div v-for="skippedPerson of skippedPeople">
          {{ skippedPerson.name }}
          <small class="text-gray-400"
            >({{ skippedPerson.errorMessage }})</small
          >
        </div>
      </div>
    </PanelSection>

    <template #buttons-left v-if="peopleScrape.status === 'failed'">
      <Btn
        class="flex-shrink-0"
        @click="retryPeopleScrape"
        :busy="saveStatus === 'saving'"
        ><i class="fa fa-refresh mr-1" /> Retry</Btn
      >

      <SaveStatus :status="saveStatus" />
    </template>
  </SidePanel>
</template>

<script setup lang="ts">
import { AxiosInstance } from "axios";
import { computed } from "vue";
import Btn from "../../global/Btn.vue";
import SidePanel from "../../global/SidePanel.vue";
import PanelSection from "../../global/SidePanel/PanelSection.vue";
import SaveStatus from "../../global/SaveStatus.vue";
import useModelApi from "../../global/useModelApi";
import FormErrors from "../../global/FormErrors.vue";

const props = defineProps<{
  peopleScrape: types.PeopleScrape;
  axios: AxiosInstance;
  agencies: schema.Agency[];
  groups: schema.Group[];
}>();

const emit = defineEmits<{
  (event: "update", peopleScrape: types.PeopleScrape): void;
}>();

const importedPeople = computed(() => {
  return props.peopleScrape.scrapedPeople.filter(
    (p) => p.status === "complete"
  );
});

const skippedPeople = computed(() => {
  return props.peopleScrape.scrapedPeople.filter((p) => p.status === "skipped");
});

const { saveStatus, errors, api } = useModelApi();

const retryPeopleScrape = async () => {
  const responseData = await api({
    method: "post",
    url: `dashboard/super_admin/api/people_scrapes/${props.peopleScrape.id}/retry.json`,
  });

  if (responseData) {
    emit("update", responseData.peopleScrape);
  }
};
</script>
