import { AxiosInstance } from "axios";
import { throttle } from "lodash";
import { ref } from "vue";

export default function useAwsCredentials(
  initialAwsCredential: schema.AwsCredential | null,
  axios: AxiosInstance
) {
  const awsCredential = ref<schema.AwsCredential | null>(initialAwsCredential);
  const refreshCredentials = throttle(
    async () => {
      const result = await axios({
        method: "post",
        url: "/api/aws_credentials/refresh",
      });
      awsCredential.value = result.data.awsCredential;
    },
    5000,
    { leading: true }
  );

  return {
    awsCredential,
    refreshCredentials,
  };
}
