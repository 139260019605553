<template>
  <div class="inline-flex relative select-none flex-shrink-0">
    <div
      class="flex justify-between gap-x-2 rounded bg-white px-4 py-[6px] items-center shadow ring-1 ring-black ring-opacity-5 md:rounded-lg cursor-pointer hover:ring-4 transition-all relative"
      :class="{ 'ring-4 ring-opacity-20 ring-purple-500': active }"
      @click="active ? emit('close') : emit('open')"
    >
      <slot name="button" />
    </div>
    <Panel
      v-if="active"
      class="top-12 w-96 ring-1 ring-black ring-opacity-5 overflow-y-auto max-h-[420px]"
      ref="panel"
    >
      <slot name="panel" />
    </Panel>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";
import Panel from "./Panel.vue";

defineProps<{
  active: boolean;
}>();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "open"): void;
}>();

const panel = ref(null);

onClickOutside(
  panel,
  () => {
    emit("close");
  },
  { ignore: ["[data-name='GroupPanel']"] }
);
</script>
