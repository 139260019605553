<template>
  <div>
    <FormErrors :errors="errors" />

    <FormControl :errors="errors?.periodInterval">
      <template #label>Period Interval</template>
      <template #helper
        >Billing periods will automatically renew for the specified duration on
        the day the current one expires if enabled.</template
      >
      <div class="flex gap-2 justify-start">
        <select class="input is-sm" v-model="billingAccountForm.periodInterval">
          <option :value="null">Do not automatically refresh</option>
          <option v-for="period of periods" :value="period" :key="period">
            {{ capitalize(period) }}
          </option>
        </select>
        <SaveBtn
          :save-status="saveStatus"
          :disabled="!billingAccountFormDirty"
          size="small"
          color="primary"
          @click="saveBillingAccount"
          label="Update"
        />
      </div>
    </FormControl>

    <FormControl
      class="!mb-4"
      v-if="
        billingAccount.userId && !billingAccount.automaticInvoicingStartDate
      "
    >
      <SaveBtn
        :save-status="saveStatus"
        size="small"
        color="primary"
        @click="startAutomaticInvoicing"
        label="Start Automatic Invoicing"
      />
    </FormControl>

    <FormControl
      class="!mb-4"
      v-else-if="billingAccount.automaticInvoicingStartDate"
    >
      <span class="text-gray-500">Automatic Invoicing Started on</span>
      {{ niceDate(billingAccount.automaticInvoicingStartDate) }}
    </FormControl>

    <FormControl>
      <div class="flex justify-between gap-2">
        <div class="flex justify-start gap-2">
          <template v-if="billingPeriods.length > 0">
            <Btn size="small" color="secondary" @click="initStartPeriodForm"
              >Add Start</Btn
            >
            <Btn size="small" color="secondary" @click="initEndPeriodForm"
              >Add End</Btn
            >
          </template>
          <template v-else>
            <Btn size="small" color="secondary" @click="initPeriodForm"
              >Add</Btn
            >
          </template>
        </div>
        <div class="flex justify-end gap-2">
          <Btn
            size="small"
            color="secondary"
            :disabled="!canExtend"
            :busy="extendFetchStatus === 'fetching'"
            @click="temporallyExtend('past')"
          >
            Extend Past
          </Btn>
          <Btn
            size="small"
            color="secondary"
            :disabled="!canExtend"
            :busy="extendFetchStatus === 'fetching'"
            @click="temporallyExtend('future')"
          >
            Extend Future
          </Btn>
        </div>
      </div>
    </FormControl>

    <div
      class="max-h-96 overflow-y-auto rounded-lg divide-y divide-gray-200 border-gray-200 border mt-4"
      v-if="orderedPeriods.length || periodForm"
    >
      <BillingPeriod
        v-if="periodForm"
        :billing-period-form="periodForm"
        :billing-account="props.billingAccount"
        @save="handleCreatePeriod"
        @cancel="periodForm = null"
      />
      <BillingPeriod
        v-for="(billingPeriod, i) of orderedPeriods"
        :billing-period="billingPeriod"
        :billing-account="props.billingAccount"
        :key="billingPeriod.id"
        :renewable="i === 0 && !!billingAccountForm.periodInterval"
        @save="updatePeriod"
        @delete="deletePeriod(billingPeriod.id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import BillingPeriod from "./BillingPeriod.vue";
import {
  formatUTCDateForInput,
  niceDate,
  orderedByTimeField,
} from "../../../utility/time";
import FormControl from "../../global/FormControl.vue";
import { capitalize, cloneDeep } from "lodash";
import SaveBtn from "../../global/SaveBtn.vue";
import useModelApi from "../../global/useModelApi";
import FormErrors from "../../global/FormErrors.vue";
import Btn from "../../global/Btn.vue";
import useModelList from "../../global/useModelList";
import { add, parseISO, sub } from "date-fns";
import useFetchApi from "../../global/useFetchApi";

const props = defineProps<{
  billingAccount: types.BillingAccount;
  stripeSubscriptionId?: string | null;
}>();

const emit = defineEmits<{
  (event: "update", billingAccount: types.BillingAccount): void;
}>();

const { api, errors, saveStatus } = useModelApi<schema.BillingAccount>();

const billingAccountFormDirty = computed(() => {
  return (
    billingAccountForm.value.periodInterval !==
    props.billingAccount.periodInterval
  );
});

const {
  models: billingPeriods,
  createModel: createPeriod,
  updateModel: updatePeriod,
  initModelForm: initPeriodForm,
  modelForm: periodForm,
  deleteModel: deletePeriod,
  createModels: createPeriods,
} = useModelList<types.BillingPeriod>({
  initialModels: props.billingAccount.billingPeriods,
  defaultModel: () => {
    return {
      startAt: null,
      endAt: null,
      includedAiProcesses: null,
      billingAccountId: props.billingAccount.id,
    };
  },
});

const initStartPeriodForm = () => {
  initPeriodForm();
  if (!orderedPeriods.value.length) return;
  const firstPeriod = orderedPeriods.value.at(-1)!;
  const firstStartAt = parseISO(firstPeriod.startAt);
  const endAt = sub(firstStartAt, { minutes: 1 });
  periodForm.value!.endAt = formatUTCDateForInput(endAt);
  periodForm.value!.periodRateInPence = firstPeriod.periodRateInPence;
  periodForm.value!.overageRateInPence = firstPeriod.overageRateInPence;
  periodForm.value!.includedAiProcesses = firstPeriod.includedAiProcesses;
};

const initEndPeriodForm = () => {
  initPeriodForm();
  if (!orderedPeriods.value.length) return;
  const lastPeriod = orderedPeriods.value.at(0)!;
  const lastEndAt = parseISO(lastPeriod.endAt);
  const startAt = add(lastEndAt, { minutes: 1 });
  periodForm.value!.startAt = formatUTCDateForInput(startAt);
  periodForm.value!.periodRateInPence = lastPeriod.periodRateInPence;
  periodForm.value!.overageRateInPence = lastPeriod.overageRateInPence;
  periodForm.value!.includedAiProcesses = lastPeriod.includedAiProcesses;
};

const billingAccountForm = ref<types.Form<schema.BillingAccount>>(
  cloneDeep(props.billingAccount)
);

watch(
  () => props.billingAccount,
  (billingAccount) => {
    billingAccountForm.value = cloneDeep(billingAccount);
    billingPeriods.value = billingAccount.billingPeriods;
  }
);

const saveBillingAccount = async () => {
  const data = await api({
    method: "put",
    url: `/dashboard/super_admin/api/billing_accounts/${props.billingAccount.id}`,
    data: { billingAccount: billingAccountForm.value },
  });

  if (data) {
    emit("update", data.billingAccount as types.BillingAccount);
  }
};

const startAutomaticInvoicing = async () => {
  if (props.stripeSubscriptionId) {
    alert(
      "This account is already subscribed to automatic invoicing on stripe. Delete their subscription on stripe first, then refresh this page to start automatic invoicing."
    );
    return;
  }

  if (!props.billingAccount.periodInterval) {
    alert(
      "This account does not have a period interval set. Set a period interval first."
    );
    return;
  }

  const confirmed = confirm(
    "Are you sure you want to start automatic invoicing? All billing periods ending on or after today will be billed automatically."
  );

  if (!confirmed) return;

  const data = await api({
    method: "put",
    url: `/dashboard/super_admin/api/billing_accounts/${props.billingAccount.id}`,
    data: {
      billingAccount: {
        automaticInvoicingStartDate: new Date().toISOString(),
      },
    },
  });

  if (data) {
    emit("update", data.billingAccount as types.BillingAccount);
  }
};

const handleCreatePeriod = (period: types.BillingPeriod) => {
  createPeriod(period);
  periodForm.value = null;
};

const { fetchStatus: extendFetchStatus, fetchApi: extendFetchApi } =
  useFetchApi();

const temporallyExtend = async (direction: "past" | "future") => {
  if (!canExtend.value) return;
  const period =
    direction === "future"
      ? orderedPeriods.value.at(0)
      : orderedPeriods.value.at(-1);
  if (!period) return;

  const url = `/dashboard/super_admin/api/billing_periods/${period.id}/temporally_extend.json`;
  const data = await extendFetchApi({
    url,
    method: "post",
    params: { direction },
  });

  if (data) {
    createPeriods(data.billingPeriods);
  } else if (extendFetchStatus.value === "error") {
    alert("There was an error while extending the billing period.");
  }
};

const canExtend = computed(() => {
  return orderedPeriods.value.length > 0 && props.billingAccount.periodInterval;
});

const orderedPeriods = computed(() => {
  return orderedByTimeField(billingPeriods.value, "startAt");
});

const periods = ["monthly", "biannual", "annual"];
</script>
