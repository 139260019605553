<template>
  <Filter
    :active="active"
    @close="
      active = false;
      filterQuery = '';
    "
    @open="active = true"
    data-name="GroupFilter"
  >
    <template #button>
      <div>Group</div>
      <FolderIcon class="w-5 h-5 text-gray-400" v-if="count === 0" />
      <div
        v-else
        class="px-2 h-5 rounded-full bg-purple-100 text-purple-800 text-xs flex justify-center items-center"
      >
        {{ count }}
      </div>
    </template>
    <template #panel>
      <div class="space-y-1 px-3 py-2">
        <input
          class="input"
          v-model="filterQuery"
          placeholder="Filter groups"
        />
      </div>

      <div class="space-y-1 px-2 py-2" v-if="activeGroups.length">
        <Group
          :group="group"
          :active="true"
          v-for="group of activeGroups"
          :key="group.id"
          :axios="axios"
          @deactivate="groupIds.splice(groupIds.indexOf(group.id), 1)"
          @delete="handleDelete(group)"
        />
      </div>

      <div class="space-y-1 px-2 py-2" v-if="inactiveGroups.length">
        <Group
          :group="group"
          v-for="group of inactiveGroups"
          :key="group.id"
          :active="false"
          :axios="axios"
          @activate="groupIds.push(group.id)"
          @delete="handleDelete(group)"
          @update="handleUpdate"
        />
      </div>

      <div
        v-if="!activeGroups.length && !inactiveGroups.length"
        class="flex justify-center items-center px-4 py-4 text-gray-400"
      >
        No groups found
      </div>
    </template>
  </Filter>
</template>

<script setup lang="ts">
import { FolderIcon } from "@heroicons/vue/24/solid";
import { AxiosInstance } from "axios";
import { compareTwoStrings } from "string-similarity";
import { computed, ref } from "vue";
import Group from "./GroupFilter/Group.vue";
import Filter from "../../../global/Filter.vue";
import { sortBy } from "lodash";

const props = defineProps<{
  groupIds: number[];
  groups: types.Group[];
  axios: AxiosInstance;
  people: types.Person[];
}>();

const filterQuery = ref("");
const active = ref(false);

const count = computed(() => props.groupIds.length);

const filteredGroups = computed(() => {
  return props.groups
    .filter((group) =>
      filterQuery.value
        ? compareTwoStrings(group.name, filterQuery.value) >= 0.01
        : true
    )
    .sort((a, b) => {
      return (
        compareTwoStrings(b.name, filterQuery.value) -
        compareTwoStrings(a.name, filterQuery.value)
      );
    });
});

const activeGroups = computed(() => {
  return sortBy(
    filteredGroups.value.filter((group) => props.groupIds.includes(group.id)),
    (group) => group.name.toLowerCase()
  );
});

const inactiveGroups = computed(() => {
  return sortBy(
    filteredGroups.value.filter((group) => !props.groupIds.includes(group.id)),
    (group) => group.name.toLowerCase()
  );
});

const handleDelete = async (group: types.Group) => {
  props.groups.splice(props.groups.indexOf(group), 1);
  props.groupIds.splice(props.groupIds.indexOf(group.id), 1);
  for (const person of props.people) {
    person.groupMemberships = person.groupMemberships.filter(
      (gm) => gm.groupId !== group.id
    );
  }
};

const handleUpdate = async (group: types.Group) => {
  const index = props.groups.findIndex((g) => g.id === group.id);
  props.groups[index] = group;
};
</script>
