import { computed } from "@vue/reactivity";

export type BtnFill = "filled" | "outline" | "underline" | "none";

export default function useBtn(
  props: Partial<{
    size: types.Size;
    fill: BtnFill;
    color: types.Color;
    disabled: boolean;
    busy: boolean;
  }>
) {
  const classes = computed(() => {
    const size = props.size || "medium";
    const fill = props.fill || "filled";
    const color = props.color || "primary";

    return {
      "py-2 px-6 text-lg font-bold rounded-lg": size === "large",
      "py-1.5 px-3 text-base font-medium rounded-lg": size === "medium",
      "py-1 px-3 text-sm rounded": size === "small",
      "py-0 px-2 text-xs rounded": size === "tiny",
      "!px-1": fill === "none",
      "relative text-center focus:ring-4 focus:outline-none flex justify-center gap-2 items-center transition-all":
        true,
      "border-transparent border": fill !== "outline",
      "focus:ring-purple-200": color === "primary",
      "focus:ring-gray-50": color === "secondary",
      "focus:ring-red-200": color === "danger",
      "text-white/90": fill === "filled",
      "text-purple-600": fill !== "filled" && color === "primary",
      "text-gray-500": fill !== "filled" && color === "secondary",
      "text-red-600": fill !== "filled" && color === "danger",
      underline: fill === "underline",
      "bg-purple-600": fill === "filled" && color === "primary",
      "bg-gray-500": fill === "filled" && color === "secondary",
      "bg-red-600": fill === "filled" && color === "danger",
      "border border-gray-500": fill === "outline" && color === "secondary",
      "border border-red-600": fill === "outline" && color === "danger",
      "border border-purple-600": fill === "outline" && color === "primary",
      "hover:bg-purple-600 hover:text-white":
        fill === "outline" && color === "primary" && !props.disabled,
      "hover:bg-red-600 hover:text-white":
        fill === "outline" && color === "danger" && !props.disabled,
      "hover:bg-gray-500 hover:text-white":
        fill === "outline" && color === "secondary" && !props.disabled,
      "hover:bg-gray-200": fill === "none" && !props.disabled,
      "cursor-not-allowed grayscale opacity-40": props.disabled,
      "cursor-pointer hover:opacity-90": !props.disabled && !props.busy,
      "cursor-auto": props.busy,
      "select-none": true,
    };
  });

  const textClasses = computed(() => {
    return {
      "opacity-50": props.busy,
      "flex items-center gap-x-1": true,
    };
  });

  const loaderShade = computed(() => {
    const fill = props.fill || "filled";
    return fill === "filled" ? "light" : "dark";
  });

  return {
    classes,
    textClasses,
    loaderShade,
  };
}
