import localforage from "localforage";
import { computed, onMounted, reactive, ref } from "vue";
import { deepToRaw } from "../../utility/helpers";

export default function useDbStorage<T>(baseKey: string) {
  const data = reactive({} as Record<string, T>);
  const loaded = ref(false);

  onMounted(async () => {
    for (const key of await allActiveKeys()) {
      const id = key.replace(`${baseKey}-`, "");
      const item = await localforage.getItem<T>(key);
      data[id] = item!;
    }
    loaded.value = true;
  });

  const ids = computed(() => {
    return Object.keys(data);
  });

  const all = computed(() => {
    return Object.values(data);
  });

  const get = (id: string) => {
    return data[id];
  };

  const set = async (id: string, value: T) => {
    data[id] = value;
    await localforage.setItem(key(id), deepToRaw(data[id]));
  };

  const update = async (id: string, value: Partial<T>) => {
    data[id] = { ...data[id], ...value };
    await localforage.setItem(key(id), deepToRaw(data[id]));
  };

  const remove = async (id: string) => {
    delete data[id];
    await localforage.removeItem(key(id));
  };

  const removeAll = async () => {
    for (const key of await allActiveKeys()) {
      await localforage.removeItem(key);
    }

    for (const key of Object.keys(data)) {
      delete data[key];
    }
  };

  const key = (id: string) => `${baseKey}-${id}`;

  const allActiveKeys = async () => {
    const keys = await localforage.keys();
    return keys.filter((key) => key.startsWith(baseKey));
  };

  return reactive({
    get,
    set,
    ids,
    all,
    update,
    remove,
    removeAll,
    loaded,
    data,
  });
}
