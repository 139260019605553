<template>
  <tr class="transition-colors text-sm" :class="classes" @click="handleClick">
    <slot />
  </tr>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  selected?: boolean;
  selectable?: boolean;
}>();

const emit = defineEmits<{
  (e: "select"): void;
}>();

const classes = computed(() => {
  return {
    "bg-gray-100": props.selected,
    "cursor-pointer hover:bg-gray-50": props.selectable,
  };
});

const handleClick = (e: MouseEvent) => {
  if (props.selectable) {
    e.preventDefault();
    e.stopPropagation();
    emit("select");
  }
};
</script>
