<template>
  <SidePanel>
    <PanelSection>
      <div class="flex justify-between items-center">
        <h4
          class="text-xs uppercase font-bold tracking-wide text-gray-500 mb-2"
        >
          User Details
        </h4>

        <div class="flex gap-1 select-none">
          <div v-if="user.superAdmin" class="mb-4">
            <div
              class="bg-red-300 text-red-900 rounded px-2 py-0.5 text-sm inline-flex items-center gap-x-1"
            >
              <GlobeAltIcon class="w-4 h-4" /> Super Admin
            </div>
          </div>

          <div v-if="user.id === currentSuperAdmin.id" class="mb-4">
            <div
              class="bg-red-300 text-red-900 rounded px-2 py-0.5 text-sm inline-flex items-center gap-x-1"
            >
              <StarIcon class="w-4 h-4" /> You
            </div>
          </div>
        </div>
      </div>

      <FormErrors :errors="errors" />

      <KeyValueDetails>
        <KeyValue label="ID">{{ user.id }}</KeyValue>

        <KeyValue label="Email"
          ><input v-model="userForm.email" class="input is-xs"
        /></KeyValue>

        <KeyValue label="Status">
          <select v-model="userForm.status" class="input is-xs">
            <option value="prospect">Prospect</option>
            <option value="free_trial">Free Trial</option>
            <option value="active">Active</option>
            <option value="no_longer_active">No Longer Active</option>
            <option value="agency_disabled">Agency Disabled</option>
          </select>
        </KeyValue>

        <KeyValue label="First Name"
          ><input v-model="userForm.firstName" class="input is-xs"
        /></KeyValue>

        <KeyValue label="Last Name"
          ><input v-model="userForm.lastName" class="input is-xs"
        /></KeyValue>

        <KeyValue label="Last Active">
          <template v-if="user.lastActiveAt">
            {{ format(new Date(user.lastActiveAt), "MMM do yyyy HH:MM aa") }}
          </template>
        </KeyValue>

        <KeyValue label="Created">
          {{ format(new Date(user.createdAt), "MMM do yyyy HH:MM aa") }}
        </KeyValue>

        <KeyValue label="Free Trial End">
          <template v-if="user.freeTrialEndAt">{{
            format(new Date(user.freeTrialEndAt), "yyyy-MM-dd")
          }}</template>
        </KeyValue>

        <KeyValue label="Affiliate">{{ user.affiliate?.email }}</KeyValue>

        <KeyValue class="flex gap-x-1" label="Intercom ID">
          <input v-model="userForm.intercomId" class="input is-xs" />
          <Link
            :href="`https://app.intercom.com/a/apps/${intercomAppId}/users/${userForm.intercomId}`"
            target="_blank"
            size="tiny"
            color="secondary"
            @click.stop
            ><ArrowTopRightOnSquareIcon class="w-3 h-3"
          /></Link>
        </KeyValue>

        <KeyValue label="Stripe Customer ID">
          <a
            v-if="user.billingAccount?.stripeCustomerId"
            :href="`https://dashboard.stripe.com/customers/${user.billingAccount?.stripeCustomerId}`"
            target="_blank"
            class="text-blue-400"
            @click.stop
            >{{ user.billingAccount?.stripeCustomerId }}</a
          >
        </KeyValue>

        <KeyValue label="Stripe Subscription ID">
          <a
            v-if="user.stripeSubscriptionId"
            :href="`https://dashboard.stripe.com/subscriptions/${user.stripeSubscriptionId}`"
            target="_blank"
            class="text-blue-400"
            @click.stop
            >{{ user.stripeSubscriptionId }}</a
          >
        </KeyValue>

        <KeyValue label="Phone"
          ><input v-model="userForm.phone" class="input is-xs"
        /></KeyValue>

        <KeyValue label="Last used version">{{
          user.lastUsedVersion
        }}</KeyValue>

        <KeyValue label="Confirmed at">
          <template v-if="user.confirmedAt">{{
            format(new Date(user.confirmedAt), "MMM do yyyy HH:MM aa")
          }}</template>
        </KeyValue>

        <KeyValue label="Max Devices Override"
          ><input v-model="userForm.maxDevicesOverride" class="input is-xs"
        /></KeyValue>

        <KeyValue label="Max Device Activations Override"
          ><input
            v-model="userForm.maxDeviceActivationsOverride"
            class="input is-xs"
        /></KeyValue>
      </KeyValueDetails>
    </PanelSection>

    <PanelSection>
      <template #title>Agency</template>

      <KeyValueDetails v-if="user.agency">
        <KeyValue label="Agency">
          <a
            v-if="user.agency && agencyHref"
            :href="agencyHref"
            class="underline cursor-pointer"
            >{{ user.agency.name }}</a
          >
        </KeyValue>

        <KeyValue label="Is Admin">
          <input type="checkbox" v-model="userForm.isAgencyAdmin" />
        </KeyValue>

        <KeyValue label="Monthly Limit">
          <template v-if="typeof user.agencyMaxProcessesPerMonth === 'number'"
            >{{ niceNumber(user.agencyMaxProcessesPerMonth) }}
            <span class="text-gray-500 text-xs"> people</span></template
          >
        </KeyValue>

        <KeyValue label="Force Lock People Setting">
          <input type="checkbox" v-model="userForm.forceLockPeopleSetting" />
        </KeyValue>
      </KeyValueDetails>

      <div v-else class="flex gap-x-1">
        <input
          class="input is-xs"
          v-model="newAgencyName"
          placeholder="Enter new agency name"
        />
        <Btn
          class="flex-shrink-0"
          size="tiny"
          color="secondary"
          title="Creates an agency with the given name. The selected users's facial recognition database will become the agencies."
          :busy="creatingAgency"
          :disabled="!newAgencyName"
          @click="createAgency"
          >Create Agency</Btn
        >
      </div>
    </PanelSection>

    <PanelSection>
      <template #title>Face Database</template>

      <KeyValueDetails>
        <KeyValue label="Collection Type">{{
          stats?.rekognitionCollection?.kind
        }}</KeyValue>
        <KeyValue
          v-if="stats?.rekognitionCollection?.faceEngineUrl"
          label="Face Engine URL"
          >{{ stats?.rekognitionCollection?.faceEngineUrl }}</KeyValue
        >
        <KeyValue
          v-if="stats?.rekognitionCollection?.faceStorageUrl"
          label="Face Storage URL"
          >{{ stats?.rekognitionCollection?.faceStorageUrl }}</KeyValue
        >
        <KeyValue label="Collection ID">{{
          user.rekognitionCollectionId
        }}</KeyValue>
        <KeyValue label="Collection Face Model">{{
          stats?.rekognitionCollection?.faceModel
        }}</KeyValue>
        <KeyValue label="Collection People Records">{{
          niceNumber(stats?.rekognitionCollection?.peopleCount ?? 0)
        }}</KeyValue>
        <KeyValue label="Collection Reference Faces">
          {{
            niceNumber(stats?.rekognitionCollection?.referenceFaceCount ?? 0)
          }}
        </KeyValue>
        <KeyValue label="Orphaned Faces Removed">
          {{
            niceNumber(
              stats?.rekognitionCollection?.totalOrphanedFacesRemoved ?? 0
            )
          }}
        </KeyValue>
        <KeyValue
          label="User Reference Faces"
          title="Reference faces in the collection that the user actually created. This will be less than the collection reference faces count in agencies with multiple users"
        >
          {{ niceNumber(stats?.referenceFaces ?? 0) }}
        </KeyValue>
        <KeyValue
          label="User Temporary Faces"
          title="These will be deleted within a 30 day window if they are not converted into reference faces"
        >
          {{ niceNumber(stats?.normalFaces ?? 0) }}
        </KeyValue>
      </KeyValueDetails>
    </PanelSection>

    <PanelSection>
      <template #title>All Time Usage Stats</template>

      <KeyValueDetails>
        <KeyValue label="People Created">
          {{ niceNumber(stats?.allTimePeopleCreated ?? 0) }}
        </KeyValue>

        <KeyValue label="Images Processed">
          {{ niceNumber(stats?.allTimeImagesProcessed ?? 0) }}
        </KeyValue>

        <KeyValue label="Text Processed">
          {{ niceNumber(stats?.allTimeTextProcessed ?? 0) }}
        </KeyValue>

        <KeyValue label="Faces Processed">
          {{ niceNumber(stats?.allTimeFacesProcessed ?? 0) }}
        </KeyValue>

        <KeyValue label="Average faces per image">
          <template v-if="stats?.allTimeImagesProcessed">
            {{
              (
                (stats?.allTimeFacesProcessed ?? 0) /
                stats?.allTimeImagesProcessed
              ).toFixed(2)
            }}
          </template>
          <template v-else>-</template>
        </KeyValue>
      </KeyValueDetails>
    </PanelSection>

    <PanelSection v-if="stats?.billingAccount">
      <template #title>Billing</template>

      <BillingAccount
        :billing-account="stats.billingAccount"
        :stripe-subscription-id="user.stripeSubscriptionId"
        @update="updateBillingAccount"
      />
    </PanelSection>

    <PanelSection>
      <template #title> Time Range Usage Report </template>

      <div class="flex gap-4">
        <FormControl>
          <template #label>Start</template>
          <input type="date" v-model="startDate" class="input is-xs" />
        </FormControl>
        <FormControl>
          <template #label>End</template>
          <input type="date" v-model="endDate" class="input is-xs" />
        </FormControl>
      </div>

      <KeyValueDetails
        class="mt-3 grid grid-cols-2 gap-y-1 gap-x-2 items-center"
      >
        <KeyValue label="Face processes">{{ stats?.faceProcesses }}</KeyValue>
        <KeyValue label="Text processes">{{ stats?.textProcesses }}</KeyValue>
        <KeyValue label="Image processes">{{ stats?.imageProcesses }}</KeyValue>
        <KeyValue>
          <template #label
            ><div class="font-bold text-gray-500">
              Billable Processes:
            </div></template
          >
          <div class="font-bold">
            {{ stats?.billableProcesses }}
          </div></KeyValue
        >
      </KeyValueDetails>
    </PanelSection>

    <PanelSection>
      <template #title>Devices</template>
      <KeyValueDetails v-if="stats">
        <KeyValue label="Active Devices">
          {{ user.devices.filter((d) => d.active).length }} /
          {{ user.maxDevices }}
        </KeyValue>
        <KeyValue label="Activations this month">
          {{ stats.deviceActivationsThisMonth }} /
          {{ user.maxDeviceActivations }}
        </KeyValue>
      </KeyValueDetails>

      <div
        class="divide-y divide-gray-200 border-gray-200 rounded-md border overflow-y-auto max-h-72 mt-2"
        v-if="user.devices.length > 0"
      >
        <Device
          v-for="device of user.devices"
          :key="device.id"
          :device="device"
        />
      </div>
    </PanelSection>

    <PanelSection>
      <template #title> Actions </template>

      <div class="flex gap-2 flex-wrap items-center">
        <div v-if="user.id !== currentSuperAdmin.id">
          <Btn
            v-if="stats?.impersonating"
            @click="stopImpersonatingUser()"
            size="small"
            color="secondary"
          >
            <XMarkIcon class="w-4 h-4" /> Stop Impersonating User
          </Btn>

          <Btn
            v-else-if="!user.superAdmin"
            @click="impersonateUser()"
            size="small"
            color="secondary"
          >
            <UserPlusIcon class="w-4 h-4" /> Impersonate User
          </Btn>
        </div>

        <div v-if="passwordResetLink" class="flex gap-x-1">
          <a
            :href="passwordResetLink"
            class="underline text-blue-500 flex-grow border p-1 rounded border-gray-300"
            >{{ passwordResetLink }}</a
          >
          <Btn
            color="primary"
            size="small"
            title="Copy to clipboard"
            @click="copyPasswordResetLink"
            ><ClipboardIcon class="w-4 h-4"
          /></Btn>
        </div>

        <Btn
          v-else
          @click="generatePasswordResetLink"
          size="small"
          color="secondary"
          :busy="generatingPasswordResetLink"
          ><LockOpenIcon class="w-4 h-4" /> Generate Password Reset Link</Btn
        >

        <Btn
          v-if="!user.confirmedAt"
          @click="confirmEmail"
          size="small"
          color="secondary"
          :busy="confirmingEmail"
          ><CheckCircleIcon class="w-4 h-4" /> Confirm Email</Btn
        >

        <Link
          :href="`mailto:${user.email}`"
          target="_blank"
          size="small"
          color="secondary"
          ><EnvelopeIcon class="w-4 h-4" /> Email User</Link
        >

        <Link
          v-if="user.intercomId"
          :href="`https://app.intercom.com/a/inbox/ytdv4ne9/inbox/new-conversation?recipient=${user.intercomId}`"
          target="_blank"
          size="small"
          color="secondary"
          ><MegaphoneIcon class="w-4 h-4" /> Message User on Intercom</Link
        >
      </div>
    </PanelSection>

    <template #buttons-left>
      <SaveBtn
        :saveStatus="saveStatus"
        @save="saveUser"
        :disabled="formClean"
      />
    </template>

    <template #buttons-right>
      <Btn
        @click="deleteUser"
        :busy="saveStatus === 'saving'"
        :disabled="!stats"
        color="danger"
        fill="underline"
        size="small"
        >Delete</Btn
      >
    </template>
  </SidePanel>
</template>

<script setup lang="ts">
import { useLocalStorage } from "@vueuse/core";
import { format } from "date-fns";
import { pick } from "lodash";
import { computed, nextTick, ref, toRaw, watch, watchEffect } from "vue";
import Btn from "../../global/Btn.vue";
import {
  ArrowTopRightOnSquareIcon,
  GlobeAltIcon,
  LockOpenIcon,
  StarIcon,
  UserPlusIcon,
  XMarkIcon,
  EnvelopeIcon,
  CheckCircleIcon,
  MegaphoneIcon,
} from "@heroicons/vue/24/solid";
import { ClipboardIcon } from "@heroicons/vue/24/outline";
import Link from "../../global/Link.vue";
import { niceNumber } from "../../../utility/helpers";
import SidePanel from "../../global/SidePanel.vue";
import PanelSection from "../../global/SidePanel/PanelSection.vue";
import SaveBtn from "../../global/SaveBtn.vue";
import useModelApi from "../../global/useModelApi";
import FormErrors from "../../global/FormErrors.vue";
import FormControl from "../../global/FormControl.vue";
import KeyValueDetails from "../../global/KeyValueDetails.vue";
import KeyValue from "../../global/KeyValueDetails/KeyValue.vue";
import createAxiosClient from "../../../utility/axios_client";
import BillingAccount from "../shared/BillingAccount.vue";
import Device from "./Device.vue";

const props = defineProps<{
  user: types.User;
  intercomAppId: string;
  currentSuperAdmin: schema.User;
}>();

const emit = defineEmits<{
  (event: "update", user: types.User): void;
  (event: "delete", userId: number): void;
}>();

const axios = createAxiosClient();

const loading = ref(false);
const stats = ref<types.UserStats | null>(null);
const now = format(new Date(), "yyyy-MM-dd");
const startDate = useLocalStorage("SelectedUser:start_date", now);
const endDate = useLocalStorage("SelectedUser:end_date", now);

const fetchUser = async () => {
  loading.value = true;
  try {
    const result = await axios({
      method: "get",
      url: `dashboard/super_admin/api/users/${props.user.id}.json`,
      params: {
        start_date: startDate.value,
        end_date: endDate.value,
      },
    });
    stats.value = result.data.stats;
  } finally {
    loading.value = false;
  }
};

watchEffect(() => {
  fetchUser();
});

type UserForm = Pick<
  schema.User,
  | "email"
  | "firstName"
  | "lastName"
  | "phone"
  | "status"
  | "isAgencyAdmin"
  | "intercomId"
  | "maxDevicesOverride"
  | "maxDeviceActivationsOverride"
  | "forceLockPeopleSetting"
>;

const loadUserFrom = (user: types.User) => {
  return structuredClone(
    pick(
      toRaw(user),
      "email",
      "firstName",
      "lastName",
      "phone",
      "status",
      "isAgencyAdmin",
      "intercomId",
      "maxDevicesOverride",
      "maxDeviceActivationsOverride",
      "forceLockPeopleSetting"
    )
  );
};

const userForm = ref<UserForm>(loadUserFrom(props.user));
const { errors, saveStatus, api } = useModelApi<UserForm>();
const formClean = ref(true);

watch(
  () => props.user,
  () => {
    passwordResetLink.value = null;
    newAgencyName.value = "";
    userForm.value = loadUserFrom(props.user);
    saveStatus.value = "idle";

    nextTick(() => {
      formClean.value = true;
    });
  },
  {
    deep: true,
  }
);

watch(
  userForm,
  () => {
    formClean.value = false;
  },
  { deep: true }
);

const saveUser = async () => {
  const responseData = await api({
    axios: axios,
    method: "put",
    url: `dashboard/super_admin/api/users/${props.user.id}.json`,
    data: {
      user: userForm.value,
    },
  });

  if (responseData) {
    emit("update", responseData.user);
  }
};

const passwordResetLink = ref<string | null>(null);
const generatingPasswordResetLink = ref(false);

const generatePasswordResetLink = async () => {
  generatingPasswordResetLink.value = true;

  try {
    const result = await axios({
      method: "post",
      url: `dashboard/super_admin/api/users/${props.user.id}/password_reset_links.json`,
    });
    passwordResetLink.value = result.data.url;
  } catch (e: any) {
    alert(`Failed to generate password reset link: ${e.message}`);
  } finally {
    generatingPasswordResetLink.value = false;
  }
};

const confirmingEmail = ref(false);

const confirmEmail = async () => {
  confirmingEmail.value = true;

  try {
    const result = await axios({
      method: "post",
      url: `dashboard/super_admin/api/users/${props.user.id}/confirmations.json`,
    });
    props.user.confirmedAt = result.data.confirmedAt;
  } catch (e: any) {
    alert(`Failed to confirm email: ${e.message}`);
  } finally {
    confirmingEmail.value = false;
  }
};

const copyPasswordResetLink = () => {
  navigator.clipboard.writeText(passwordResetLink.value!);
};

const newAgencyName = ref("");
const creatingAgency = ref(false);

const createAgency = async () => {
  creatingAgency.value = true;

  try {
    const result = await axios({
      method: "post",
      url: `dashboard/super_admin/api/users/${props.user.id}/agencies.json`,
      data: {
        agency: {
          name: newAgencyName.value,
        },
      },
    });
    props.user.agency = result.data.agency;
  } catch (e: any) {
    alert(`Failed to create agency: ${e.message}`);
  } finally {
    creatingAgency.value = false;
  }
};

const deleteUser = async () => {
  if (props.user.agencyId) {
    alert(
      "Cannot delete agency users. Have a word with Josh if you need to do this. It's probably a bad idea though, because we need to keep the data to ensure agency user limits."
    );
    return;
  }

  let confirmationMessage = `Are you sure you want to delete ${props.user.email}? This should only be done if the user requests under GDPR or it's a spam account.`;
  if (stats.value!.referenceFaces > 0) {
    confirmationMessage += ` This user has a face database with ${niceNumber(
      stats.value!.referenceFaces
    )} reference faces which will all be permanently destroyed!`;
  }
  const confirmed = confirm(confirmationMessage);
  if (!confirmed) return;

  const userId = props.user.id;

  saveStatus.value = "saving";
  try {
    await axios({
      method: "delete",
      url: `dashboard/super_admin/api/users/${userId}.json`,
    });
    emit("delete", userId);
    saveStatus.value = "success";
  } catch (e: any) {
    saveStatus.value = "error";
    if (e.response?.data?.errors) {
      alert(`Failed to delete user: ${e.response.data.errors}`);
    } else {
      alert(`Failed to delete user: ${e.message}`);
    }
  }
};

const impersonateUser = () => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = `/dashboard/super_admin/users/${props.user.id}/impersonations`;
  form.classList.add("hidden");
  document.body.appendChild(form);
  form.submit();
};

const stopImpersonatingUser = () => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = `/dashboard/super_admin/users/${props.user.id}/impersonations`;
  form.classList.add("hidden");
  const input = document.createElement("input");
  input.name = "_hidden";
  input.value = "true";
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
};

const agencyHref = computed(() => {
  if (!props.user.agency) return null;
  return `/dashboard/super_admin/agencies?agencyName=${props.user.agency.name}&agencyId=${props.user.agency.id}`;
});

const updateBillingAccount = (billingAccount: types.BillingAccount) => {
  if (!stats.value) return;
  stats.value.billingAccount = billingAccount;
};
</script>
