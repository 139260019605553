<template>
  <span
    class="text-xs rounded px-2 py-0.5 whitespace-nowrap"
    :class="statusClasses"
    v-if="props.user.status"
  >
    {{ status }}
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { snakeToHuman } from "../../../utility/helpers";

const props = defineProps<{
  user: types.User;
}>();

const status = computed(() => {
  if (props.user.status === "free_trial") {
    if (freeTrialActive.value) {
      if (freeTrialDaysRemaining.value === 0) {
        return "Free Trial (expires today)";
      } else {
        return `Free Trial (${freeTrialDaysRemaining.value} ${
          freeTrialDaysRemaining.value === 1 ? "day" : "days"
        } left)`;
      }
    } else if (!startedFreeTrial.value) {
      return "Free Trial (not started)";
    } else {
      return "Free Trial (expired)";
    }
  } else if (props.user.status) {
    return snakeToHuman(props.user.status);
  }
});

const freeTrialActive = computed(() => {
  return startedFreeTrial.value && freeTrialMsRemaining.value! > 0;
});

const freeTrialDaysRemaining = computed(() => {
  if (startedFreeTrial.value) {
    return Math.round(freeTrialMsRemaining.value! / (1000 * 3600 * 24));
  }
});

const freeTrialMsRemaining = computed(() => {
  if (startedFreeTrial.value) {
    const freeTrialEnd = new Date(props.user.freeTrialEndAt!);
    const now = new Date();
    return freeTrialEnd.getTime() - now.getTime();
  }
});

const startedFreeTrial = computed(() => {
  return props.user.status === "free_trial" && props.user.freeTrialEndAt;
});

const statusClasses = computed(() => {
  if (props.user.status === "active") {
    return "bg-purple-600 text-purple-50";
  } else if (props.user.status === "free_trial" && !startedFreeTrial.value) {
    return "bg-gray-300 text-gray-700";
  } else if (freeTrialActive.value) {
    return "bg-blue-500 text-blue-50";
  } else if (
    props.user.status === "free_trial" &&
    freeTrialDaysRemaining.value! < 0
  ) {
    return "bg-yellow-300 text-yellow-800";
  } else {
    return "bg-red-300 text-red-800";
  }
});
</script>
