<template>
  <div
    class="border-b-0 shadow bg-white rounded md:rounded-lg overflow-hidden flex flex-col select-none ring-1 ring-black ring-opacity-5"
    :title="tooltip"
  >
    <div class="flex divide-x divide-gray-200 flex-grow">
      <div
        class="px-3 py-1 text-sm flex items-center text-gray-500"
        v-if="slots.default"
      >
        <slot />
      </div>
      <div class="px-3 py-1 flex items-center gap-1 text-sm">
        <span>{{ niceNumber(count) }}</span>
        <template v-if="typeof limit === 'number'">
          <span>/</span>
          <span>{{ niceNumber(limit) }}</span>
        </template>
      </div>
    </div>
    <div class="h-1 relative bg-gray-200" v-if="limit">
      <div
        class="absolute h-full left-0"
        :class="{
          'bg-purple-500': percent <= 65,
          'bg-orange-400': percent >= 66 && percent <= 99,
          'bg-red-600': percent >= 100,
        }"
        :style="`width: ${percent}%`"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from "vue";
import { niceNumber } from "../../utility/helpers";

const props = defineProps<{
  limit?: number | null;
  count: number;
  limitTooltip?: string;
}>();

const slots = useSlots();

const percent = computed(() => {
  if (!props.limit) return 0;
  return Math.min((props.count / props.limit) * 100, 100);
});

const tooltip = computed(() => {
  if (!props.limit || props.count < props.limit || !props.limitTooltip)
    return "";
  return props.limitTooltip;
});
</script>

<style scoped></style>
