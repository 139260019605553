<template>
  <div class="flex flex-col h-screen relative">
    <LoadingBar
      :percent="importing ? importPercent : processPercent"
      v-if="importing || processing"
      class="top-0 left-0 right-0 absolute z-20"
    />

    <div
      class="flex flex-col pt-4 pb-0 px-6 border-b borer-b-gray-200 flex-shrink-0 bg-white"
    >
      <div class="flex justify-start">
        <Link
          href="/dashboard/agency_admin/people"
          color="secondary"
          fill="none"
          size="small"
          ><ArrowUturnLeftIcon class="w-4 h-4 mr-1" />Back to face
          database</Link
        >
      </div>
      <div class="flex justify-between flex-wrap items-center gap-2">
        <div class="flex justify-start gap-2 pt-[15px]">
          <FileBtn
            class="max-w-[200px]"
            size="medium"
            color="primary"
            fill="filled"
            @change="importFiles"
            :multiple="true"
            accept="image/png, image/jpeg"
            title="Click to select images. The largest face in each image will be used."
            :disabled="importing || everProcessed"
            :busy="importing"
          >
            <PlusCircleIcon class="w-4 h-4 mr-1" />Select images
          </FileBtn>
          <Btn
            size="medium"
            color="primary"
            fill="filled"
            @click="processImages"
            :disabled="!canStartProcessing"
            :busy="processing"
            title="Add the selected images to the face database with their given name"
          >
            <CheckCircleIcon class="w-4 h-4 mr-1" />Process images
          </Btn>
          <Btn
            size="medium"
            color="secondary"
            fill="outline"
            @click="reset"
            :disabled="!images.length || processing"
            title="Clear the current batch of images and start a new import"
            ><XCircleIcon class="w-4 h-4 mr-1" /> Clear and start new batch</Btn
          >
        </div>
        <div
          class="flex justify-end gap-4"
          v-if="images.length && !everProcessed"
        >
          <FormControl>
            <template #label>Person name metadata source field</template>
            <select v-model="selectedMetadataField" class="input w-auto">
              <option :value="null" placeholder="true"></option>
              <option v-for="field of sortedMetadataFields" :value="field">
                {{ metadataFields[field] }}
              </option>
            </select>
          </FormControl>

          <FormControl>
            <template #label>Group to add all new people to</template>
            <GroupSelect
              :axios="axios"
              :groups="groups"
              v-model="groupId"
              placeholder=""
            />
          </FormControl>
        </div>
      </div>

      <Tabs :tabs="tabs" v-model="selectedTab" />
    </div>

    <div
      class="flex justify-start px-6 py-3 bg-white border-b gap-4"
      v-if="failedImages.length && selectedTab === 'error'"
    >
      <Btn
        size="small"
        color="primary"
        fill="filled"
        @click="reprocessFailedImages"
        :disabled="processing || !failedImages.length"
        ><ArrowPathIcon class="w-4 h-4 mr-1" />Retry failed images</Btn
      >
    </div>

    <div
      class="flex justify-start px-6 py-3 bg-white border-b gap-4"
      v-if="failedToImportCount > 0 && selectedTab === 'pending'"
    >
      <Notice color="danger" class="max-w-xl">
        {{ failedToImportCount }}
        {{ failedToImportCount === 1 ? "image" : "images" }}
        {{ failedToImportCount === 1 ? "was" : "were" }} unable to be imported.
        Only JPG and PNG images are supported.
      </Notice>
    </div>

    <div
      ref="imagesContainerEl"
      class="overflow-y-scroll flex flex-wrap gap-4 p-4"
      v-if="imagesStore.loaded && filesStore.loaded"
    >
      <BulkPeopleImage
        v-for="image of selectedTabImages"
        :file="filesStore.get(image.fileId)"
        :s3="s3!"
        :s3Bucket="props.s3Bucket"
        :image="image"
        :key="image.id"
        :processing="processing"
        :shouldShowErrorBorder="!!selectedMetadataField"
        @remove="removeImage(image)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue";
import createAxiosClient from "../../utility/axios_client";
import FileBtn from "../global/FileBtn.vue";
import useAwsCredentials from "./useAwsCredentials";
import BulkPeopleImage from "./BulkPeople/Image.vue";
import exifr from "exifr";
import FormControl from "../global/FormControl.vue";
import { processImage as processImageOnAws } from "../../utility/faceProcessing";
import s3Interface from "../../utility/s3Interface";
import Btn from "../global/Btn.vue";
import {
  ArrowPathIcon,
  ArrowUturnLeftIcon,
  CheckCircleIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/vue/24/solid";
import Link from "../global/Link.vue";
import Tabs from "../global/Tabs.vue";
import { capitalize, lowerCase } from "lodash";
import GroupSelect from "./People/SelectedPerson/GroupSelect.vue";
import useDbStorage from "../global/useDbStorage";
import reduceSize from "image-blob-reduce";
import LoadingBar from "../global/LoadingBar.vue";
import Notice from "../global/Notice.vue";
import { runParallel } from "../../utility/concurrency";
import FaceEngineInterface from "~/utility/faceEngineInterface";
import RekognitionInterface from "~/utility/rekognitionInterface";

const props = defineProps<{
  tokenClient: string;
  accessToken: string;
  tokenUid: string;
  s3Bucket: string;
  initialAwsCredential: schema.AwsCredential;
  userId: number;
  rekognitionCollectionId: string;
  faceStorageUrl: string;
  faceEngineUrl: string;
  rekognitionCollectionKind: string;
  railsCollectionId: number;
  groups: types.Group[];
  agency?: types.Agency;
}>();
const axios = createAxiosClient({
  headers: {
    uid: props.tokenUid,
    client: props.tokenClient,
    accessToken: props.accessToken,
  },
});

const { awsCredential, refreshCredentials } = useAwsCredentials(
  props.initialAwsCredential,
  axios
);

type ImageStatus =
  | "loadingMetadata"
  | "resizing"
  | "pending"
  | "processing"
  | "success"
  | "error";

export type BulkImportImage = {
  fileId: string;
  filename?: string;
  id: string;
  status: ImageStatus;
  metadata: Record<string, any>;
  personName: string;
  face?: schema.Face;
  person?: schema.Person;
  errorMessage?: string;
};

const tabs = computed(() => {
  const config = {
    pending: `${processing.value ? "Processing" : "Pending"} (${
      pendingImages.value.length
    })`,
    success: `Complete (${successImages.value.length})`,
  } as Record<string, string>;

  if (failedImages.value.length) {
    config.error = `Failed (${failedImages.value.length})`;
  }

  return config;
});

const filesStore = useDbStorage<File | Blob>("BulkPeople.files");
const imagesStore = useDbStorage<BulkImportImage>("BulkPeople.images");
const images = computed(() => imagesStore.all);

const pendingImages = computed(() =>
  images.value.filter(
    (i) => i.status === "pending" || i.status === "processing"
  )
);
const failedImages = computed(() =>
  images.value.filter((i) => i.status === "error")
);
const successImages = computed(() =>
  images.value.filter((i) => i.status === "success")
);
const selectedTab = ref<ImageStatus>("pending");
const selectedTabImages = computed(() => {
  switch (selectedTab.value) {
    case "pending":
      return pendingImages.value;
    case "success":
      return successImages.value;
    case "error":
      return failedImages.value;
  }
});

const groupId = ref<number | undefined>(undefined);

onMounted(() => {
  const storedId = localStorage.getItem("storedId");
  if (storedId) {
    groupId.value = parseInt(storedId);
  }
});

const importingCount = ref(0);
const importedCount = ref(0);
const failedToImportCount = ref(0);
const importing = ref(false);

const importFiles = async (fileList: File[] | FileList | null) => {
  if (!fileList) return;
  if (fileList.length + images.value.length > 500) {
    alert(
      "This tool only supports processing a maximum of 500 images at a time. Please clear your existing import, or try again with fewer images."
    );
    return;
  }
  importing.value = true;
  importingCount.value = fileList.length;
  failedToImportCount.value = 0;
  importedCount.value = 0;

  await runParallel(Array.from(fileList), importFile);
  importing.value = false;
};

const importFile = async (file: File) => {
  try {
    const fileId = crypto.randomUUID();
    await filesStore.set(fileId, file);

    const image = reactive({
      fileId,
      id: crypto.randomUUID(),
      status: "loadingMetadata",
      metadata: {},
      personName: "",
      filename: file.name,
    } as BulkImportImage);

    await imagesStore.set(image.id, image);

    const metadata = (await exifr.parse(file, true)) ?? {};
    await imagesStore.update(image.id, {
      metadata,
      status: "resizing",
    });

    if (file.size > 1 * 1024 * 1024) {
      const blob = await reduceSize().toBlob(file, { max: 2000 });
      await filesStore.set(fileId, blob);
    }
    await imagesStore.update(image.id, {
      status: "pending",
    });
  } catch (e) {
    console.error(e);
    failedToImportCount.value++;
  } finally {
    importedCount.value++;
  }
};

const importPercent = computed(() => {
  const total = importingCount.value;
  const complete = importedCount.value;
  return (complete / total) * 100;
});

const processPercent = computed(() => {
  const total =
    successImages.value.length +
    failedImages.value.length +
    pendingImages.value.length;
  const complete = successImages.value.length + failedImages.value.length;
  return (complete / total) * 100;
});

const processing = ref(false);
const everProcessed = ref(false);
const canStartProcessing = computed(() => {
  return !processing.value && pendingImages.value.length;
});

const reprocessFailedImages = async () => {
  selectedTab.value = "pending";

  for (const image of failedImages.value) {
    image.status = "pending";
    image.errorMessage = undefined;
  }

  await processImages();
};

const processImages = async () => {
  const pendingImagesWithNames = pendingImages.value.filter(
    (i) => i.personName
  );

  if (!pendingImagesWithNames.length) {
    alert("No images have names set. Please set names and try again.");
    return;
  }

  const pendingImagesWithoutNames = pendingImages.value.filter(
    (i) => !i.personName
  );
  if (pendingImagesWithoutNames.length) {
    const confirmed = confirm(
      `${pendingImagesWithoutNames.length} ${
        pendingImagesWithoutNames.length === 1
          ? "image does not have a name set"
          : "images do not have names set"
      }. Would you like to ignore these and process the others?`
    );
    if (!confirmed) return;
  }

  processing.value = true;
  everProcessed.value = true;

  const imagesToProcess = images.value.filter(
    (i) => i.status === "pending" && i.personName
  );
  await runParallel(imagesToProcess, processImage);

  processing.value = false;
};

const processImage = async (image: BulkImportImage) => {
  await imagesStore.update(image.id, { status: "processing" });
  try {
    const face = await processImageOnAws(
      filesStore.get(image.fileId),
      props.userId,
      props.s3Bucket,
      s3.value!,
      props.rekognitionCollectionId,
      rekognition.value!,
      axios,
      props.railsCollectionId
    );

    if (face) {
      await imagesStore.update(image.id, { status: "success", face });

      const personPayload = {
        name: image.personName,
        faceIds: [face.id],
      } as {
        name: string;
        faceIds: number[];
        groupMembershipsAttributes?: { groupId: number }[];
      };

      if (groupId.value) {
        personPayload.groupMembershipsAttributes = [
          {
            groupId: groupId.value,
          },
        ];
      }

      const result = await axios({
        method: "post",
        url: "api/people.json",
        data: {
          person: personPayload,
        },
      });
      await imagesStore.update(image.id, { person: result.data.person });
    } else {
      await imagesStore.update(image.id, {
        status: "error",
        errorMessage: "No face found in image",
      });
    }
  } catch (e: any) {
    console.error(e);

    if (e.message.match("422") && e.response?.data?.errors) {
      await imagesStore.update(image.id, {
        status: "error",
        errorMessage: Object.keys(e.response.data.errors)
          .map(
            (k) => `${capitalize(k)} ${e.response.data.errors[k].join(", ")}`
          )
          .join("\n"),
      });
    } else {
      await imagesStore.update(image.id, {
        status: "error",
        errorMessage: e.message,
      });
    }

    if (e.message.match(/.*(expired|token).*/i)) {
      refreshCredentials();
    }
  }
};

const removeImage = async (image: BulkImportImage) => {
  await imagesStore.remove(image.id);
  await filesStore.remove(image.fileId);
  if (!images.value.length) reset();
};

const reset = async () => {
  if (pendingImages.value.length) {
    const confirmed = confirm(
      "Are you sure you want to clear the current batch of images?"
    );
    if (!confirmed) return;
  }
  await imagesStore.removeAll();
  await filesStore.removeAll();
  groupId.value = undefined;
  everProcessed.value = false;
  processing.value = false;
  selectedTab.value = "pending";
  selectedMetadataField.value = null;
  failedToImportCount.value = 0;
  importedCount.value = 0;
  importingCount.value = 0;
};

const s3 = computed(() => {
  if (awsCredential.value) {
    return s3Interface(awsCredential.value as any, props.faceStorageUrl);
  } else {
    return undefined;
  }
});

const rekognition = computed(() => {
  if (awsCredential.value) {
    if (awsCredential.value.kind === "face_engine") {
      return new FaceEngineInterface(
        awsCredential.value as any,
        props.faceEngineUrl
      );
    } else {
      return new RekognitionInterface(awsCredential.value as any);
    }
  } else {
    return undefined;
  }
});

const selectedMetadataField = ref<string | null>(null);
const metadataFields = computed(() => {
  const fields = {} as Record<string, string>;
  for (const image of images.value) {
    for (const [key, value] of Object.entries(image.metadata)) {
      if (typeof value === "string" && !fields[key])
        fields[key] = capitalize(lowerCase(key));
    }
  }
  return fields;
});
const sortedMetadataFields = computed(() => {
  return Object.keys(metadataFields.value).sort();
});

watch(selectedMetadataField, (field) => {
  if (!field) return;

  for (const image of images.value) {
    image.personName = image.metadata[field];
  }
});

// Once the images are loaded from the database, any that were processing set to pending. only do this once
const imageLoadNormalized = ref(false);
watch(images, () => {
  if (images.value.length) {
    for (const image of images.value.filter((i) => i.status === "processing")) {
      image.status = "pending";
    }
  }
  imageLoadNormalized.value = true;
});
</script>
