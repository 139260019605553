<template>
  <SidePanel>
    <PanelSection>
      <template #title>{{ userForm.id ? "Update" : "Create" }} User</template>

      <FormErrors :errors="errors" />

      <FormControl :required="true" :errors="errors?.email">
        <template #label>Email</template>
        <input v-model="userForm.email" class="input" type="email" />
        <template #helper v-if="!userForm.id"
          >Once the user is created an email will be sent to them with
          instructions on how to set their password and download the
          app</template
        >
      </FormControl>

      <FormControl :errors="errors?.firstName">
        <template #label>First name</template>
        <input v-model="userForm.firstName" class="input" />
      </FormControl>

      <FormControl :errors="errors?.lastName">
        <template #label>Last name</template>
        <input v-model="userForm.lastName" class="input" />
      </FormControl>

      <FormControl :errors="errors?.agencyMaxProcessesPerMonth">
        <template #label>Max billable processes per calendar month</template>
        <input
          v-model="userForm.agencyMaxProcessesPerMonth"
          class="input"
          type="number"
          placeholder="unlimited"
        />
      </FormControl>

      <FormControl class="mt-4">
        <FormCheckbox v-model="status">
          <template #label>Active</template>
          <template #helper>Active users can use the software</template>
        </FormCheckbox>
      </FormControl>

      <FormControl v-if="currentUserId !== userForm.id">
        <FormCheckbox v-model="userForm.isAgencyAdmin">
          <template #label>Agency admin</template>
          <template #helper
            >Agency admins can manage users on this dashboard</template
          >
        </FormCheckbox>
      </FormControl>

      <FormControl>
        <FormCheckbox v-model="userForm.forceLockPeopleSetting">
          <template #label>Force lock people setting</template>
          <template #helper
            >Activating this ensures that reference faces will not be
            automatically added to a person record when this user confirms an
            image.</template
          >
        </FormCheckbox>
      </FormControl>
    </PanelSection>

    <PanelSection v-if="userForm.id">
      <template #title>Actions</template>

      <FormControl class="flex gap-2 items-center">
        <Btn
          @click="resendConfirmationEmail"
          size="small"
          color="secondary"
          :busy="resendStatus === 'saving'"
          ><EnvelopeIcon class="w-4 h-4" /> Resend Welcome Email</Btn
        >
        <SaveStatus :status="resendStatus" />
      </FormControl>

      <FormControl
        class="flex gap-2 items-center"
        v-if="userForm.id !== currentUserId"
      >
        <Btn
          @click="deleteUser"
          size="small"
          fill="outline"
          color="danger"
          :busy="deleteStatus === 'saving'"
          ><TrashIcon class="w-4 h-4" /> Delete User</Btn
        >
        <SaveStatus :status="deleteStatus" />
      </FormControl>
    </PanelSection>

    <PanelSection v-if="userForm.id">
      <template #title>Historic Billable Processes</template>

      <FetchLoader :fetch-status="fetchProcessesStatus" class="pt-4">
        <div
          class="border border-gray-200 text-sm grid grid-cols-3 bg-gray-200 gap-px text-gray-700"
          id="historic-process-counts"
        >
          <template v-for="[date, count] of historicProcessCounts">
            <div class="px-2 py-1 bg-white">
              {{ date }}
            </div>

            <div class="px-2 py-1 col-span-2 bg-white">
              {{ niceNumber(count) }}
            </div>
          </template>
        </div>
      </FetchLoader>
    </PanelSection>

    <template #buttons-left>
      <SaveBtn :saveStatus="saveStatus" @save="save" :disabled="formClean" />
    </template>

    <template #buttons-right>
      <Btn
        @click="emit('close')"
        :disabled="saveStatus === 'saving'"
        color="secondary"
        fill="underline"
        >Cancel</Btn
      >
    </template>
  </SidePanel>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import SidePanel from "../../global/SidePanel.vue";
import SaveBtn from "../../global/SaveBtn.vue";
import Btn from "../../global/Btn.vue";
import FormControl from "../../global/FormControl.vue";
import FormErrors from "../../global/FormErrors.vue";
import PanelSection from "../../global/SidePanel/PanelSection.vue";
import FormCheckbox from "../../global/FormCheckbox.vue";
import useModelApi from "../../global/useModelApi";
import { EnvelopeIcon, TrashIcon } from "@heroicons/vue/24/solid";
import SaveStatus from "../../global/SaveStatus.vue";
import { currentTimezone } from "../../../utility/time";
import { niceNumber } from "../../../utility/helpers";
import useFetchApi from "../../global/useFetchApi";
import FetchLoader from "../../global/FetchLoader.vue";

const props = defineProps<{
  userForm: types.Form<types.AgencyUser>;
  currentUserId: number;
}>();

const emit = defineEmits<{
  (event: "close"): void;
  (event: "save", user: types.AgencyUser): void;
  (event: "delete", userId: number): void;
}>();

const { saveStatus, errors, api } = useModelApi<types.AgencyUser>();

const save = async () => {
  const url = props.userForm.id
    ? `dashboard/agency_admin/api/users/${props.userForm.id}.json`
    : "dashboard/agency_admin/api/users.json";
  const method = props.userForm.id ? "put" : "post";
  const data = {
    user: props.userForm,
  };

  const responseData = await api({ url, method, data });

  if (responseData) {
    const user = responseData.user;
    if (props.userForm.processCount)
      user.processCount = props.userForm.processCount;
    emit("save", user);
    if (method === "post") emit("close");
  }
};

const { saveStatus: resendStatus, api: resendApi } =
  useModelApi<types.AgencyUser>();
const resendConfirmationEmail = async () => {
  await resendApi({
    url: `dashboard/agency_admin/api/users/${props.userForm.id}/resend_welcome_email.json`,
    method: "post",
  });
};

const { saveStatus: deleteStatus, api: deleteApi } =
  useModelApi<types.AgencyUser>();
const deleteUser = async () => {
  const confirm = window.confirm(
    "Are you sure you want to delete this user? This action cannot be undone. If you want to temporarily disable access for the user, you can do so by unchecking the 'Active' checkbox."
  );
  if (!confirm) return;

  const responseData = await deleteApi({
    url: `dashboard/agency_admin/api/users/${props.userForm.id}.json`,
    method: "delete",
  });

  if (responseData) {
    emit("delete", responseData.user.id);
    emit("close");
  }
};

const formClean = computed(() => {
  return !Object.values(props.userForm).some((v) => v);
});

const status = computed({
  get() {
    return props.userForm.status === "active";
  },
  set(newValue) {
    props.userForm.status = newValue ? "active" : "agency_disabled";
  },
});

const historicProcessCounts = ref<types.DateData>([]);
const { fetchStatus: fetchProcessesStatus, fetchApi: fetchProcessesApi } =
  useFetchApi();

watch(
  () => props.userForm?.id,
  async (id?: number) => {
    if (!id) return;

    const startDate = new Date(Date.parse("01/01/2018")); // Ensures this is for all time;
    const endDate = new Date();
    const dateInterval = "month";

    const data = await fetchProcessesApi({
      url: "/dashboard/agency_admin/api/billable_ai_process_counts",
      params: {
        startDate,
        endDate,
        dateInterval,
        userIds: [id],
        timezone: currentTimezone(),
      },
    });

    if (data) {
      historicProcessCounts.value = data.processCounts.reverse();

      if (props.userForm.id === id) {
        const newCounts = data.processCounts as types.DateData;
        const newCountsChronological = newCounts.reverse();
        const firstNonZeroIndex = newCountsChronological.findIndex(
          ([_date, count]) => count > 0
        );
        const relevantCounts = newCountsChronological.slice(firstNonZeroIndex);

        historicProcessCounts.value = relevantCounts.reverse();
      }
    }
  },
  { immediate: true }
);
</script>
