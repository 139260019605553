<template>
  <div>
    <div
      ref="select"
      class="inline-flex h-[38px] select-none relative cursor-pointer"
      data-name="CustomSelect"
    >
      <div
        class="rounded-md border border-gray-300 bg-white flex items-center hover:bg-gray-50 transition-colors px-3 py-2"
        @click="open = !open"
      >
        <slot v-if="selectedOption" :option="selectedOption" name="option" />
        <slot v-else name="placeholder" />
      </div>
      <div
        class="w-64 max-h-96 overflow-y-auto bg-white rounded-md shadow-lg z-10 left-0 absolute top-10 divide-y divide-gray-200 border-gray border-gray-200 text-sm"
        v-if="open"
      >
        <div
          v-if="!hideBlank"
          @click="selectOption(null)"
          class="cursor-pointer bg-white hover:bg-gray-50 transition-colors px-3 py-2 text-gray-400"
        >
          Clear
        </div>
        <div
          v-for="option of options"
          @click="selectOption(option)"
          class="cursor-pointer bg-white hover:bg-gray-50 transition-colors px-3 py-2"
          data-name="CustomSelectOption"
        >
          <slot :option="option" name="option" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Record<string, any>">
import { onClickOutside } from "@vueuse/core";
import { computed, ref } from "vue";

const props = defineProps<{
  options: T[];
  valueKey?: keyof T;
  hideBlank?: boolean;
}>();

const valueKey = computed(() => props.valueKey || "id");
const hideBlank = computed(() => props.hideBlank);

const selectedValue = defineModel<string | number | null>();

const slots = defineSlots<{
  option(props: { option: T }): any;
  placeholder(): any;
}>();

const open = ref(false);
const select = ref(null);

onClickOutside(select, () => {
  open.value = false;
});

const selectedOption = computed(() => {
  if (!selectedValue.value) return null;
  return props.options.find(
    (option) => option[valueKey.value] === selectedValue.value
  );
});

const selectOption = (option: T | null) => {
  selectedValue.value = option ? option[valueKey.value] : null;
  open.value = false;
};
</script>

<style scoped></style>
