<template>
  <div class="relative rounded-md shadow-sm flex-grow" data-name="SearchBox">
    <div
      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 w-full"
    >
      <i class="fa fa-search text-gray-500" />
    </div>
    <input
      v-model="query"
      type="text"
      class="input !pl-9 !pr-10 h-full"
      :placeholder="placeholder || 'Search'"
      ref="input"
    />
    <div
      class="absolute inset-y-0 right-0 flex items-center mr-4 text-gray-500"
    >
      <Loader v-if="searching" class="pr-4" />
      <div
        v-if="!searching && query"
        class="px-1 hover:opacity-80 transition-colors cursor-pointer"
        @click="handleClear()"
      >
        <i class="fa fa-times text-lg select-none" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDebounceFn } from "@vueuse/core";
import { onMounted, ref, watch } from "vue";
import Loader from "./Loader.vue";

const props = defineProps<{
  initialQuery?: string;
  searching: boolean;
  placeholder?: string;
}>();

const query = ref(props.initialQuery ?? "");

const emit = defineEmits<{
  (event: "update:query", value: string): void;
}>();

const emitQuery = useDebounceFn(
  (val) => {
    emit("update:query", val);
  },
  500,
  { maxWait: 5000 }
);

watch(query, emitQuery);

const input = ref<HTMLInputElement | null>(null);
onMounted(() => {
  if (input.value) input.value.focus();
});

const handleClear = () => {
  query.value = "";
  emit("update:query", "");
};
</script>
