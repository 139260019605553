import {
  IndexFacesRequest,
  IndexFacesResponse,
  SearchFacesRequest,
  SearchFacesResponse,
} from "@aws-sdk/client-rekognition";
import { Credentials } from "@aws-sdk/types";
import type { AxiosInstance } from "axios";
import axios from "axios";

export default class FaceEngineInterface {
  #client!: AxiosInstance;

  constructor(credentials: Credentials, faceEngineUrl: string) {
    const headers = {
      "X-Access-Key": credentials.accessKeyId,
      "X-Secret-Key": credentials.secretAccessKey,
    };
    const baseURL = `${faceEngineUrl}/user/faces`;
    this.#client = axios.create({
      baseURL,
      headers,
      signal: AbortSignal.timeout(45_000),
    });
  }

  async indexFaces(data: IndexFacesRequest): Promise<IndexFacesResponse> {
    const response = await this.#client!({
      method: "post",
      url: "index",
      data,
    });
    return response.data;
  }

  async searchFaces(data: SearchFacesRequest): Promise<SearchFacesResponse> {
    const response = await this.#client!({
      method: "get",
      url: "search",
      data,
    });
    return response.data;
  }
}
