<template>
  <AdminLayout>
    <div class="flex flex-col">
      <div class="flex justify-end flex-wrap items-center gap-2 mb-4">
        <Btn color="primary" @click="initModelForm"
          ><i class="fa fa-plus-circle mr-1" /> Start new scrape</Btn
        >
      </div>

      <AdminTable>
        <template #head>
          <TableHeader>URL</TableHeader>
          <TableHeader>Type</TableHeader>
          <TableHeader>Agency</TableHeader>
          <TableHeader>Group</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader>Injested</TableHeader>
          <TableHeader>Created</TableHeader>
        </template>

        <template #body>
          <PeopleScrapeRow
            v-for="peopleScrape in peopleScrapes"
            :key="peopleScrape.id"
            :peopleScrape="peopleScrape"
            :selected="selectedPeopleScrapeId === peopleScrape.id"
            @select="handleSelect(peopleScrape)"
          />
        </template>
      </AdminTable>
    </div>

    <template #sidebar v-if="peopleScrapeForm || selectedPeopleScrape">
      <NewPeopleScrape
        v-if="peopleScrapeForm"
        :form="peopleScrapeForm"
        :axios="axios"
        :agencies="agencies"
        :groups="groups"
        @create="createModel"
      />

      <ShowPeopleScrape
        v-if="selectedPeopleScrape"
        :people-scrape="selectedPeopleScrape"
        :axios="axios"
        :agencies="agencies"
        :groups="groups"
        @close="selectedPeopleScrapeId = null"
        @update="updateModel"
      />
    </template>
  </AdminLayout>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import PeopleScrapeRow from "./PeopleScrapes/PeopleScrapeRow.vue";
import createAxiosClient from "../../utility/axios_client";
import Btn from "../global/Btn.vue";
import { useIntervalFn } from "@vueuse/core";
import NewPeopleScrape from "./PeopleScrapes/NewPeopleScrape.vue";
import ShowPeopleScrape from "./PeopleScrapes/ShowPeopleScrape.vue";
import AdminLayout from "../global/AdminLayout.vue";
import AdminTable from "../global/AdminTable.vue";
import TableHeader from "../global/AdminTable/TableHeader.vue";
import useModelList from "../global/useModelList";

const props = defineProps<{
  peopleScrapes: types.PeopleScrape[];
  agencies: schema.Agency[];
  groups: types.Group[];
}>();

const {
  models: peopleScrapes,
  createModel,
  updateModel,
} = useModelList<types.PeopleScrape>({ initialModels: props.peopleScrapes });
const axios = createAxiosClient();
const selectedPeopleScrapeId = ref<number | null>(null);
const peopleScrapeForm = ref<types.PeopleScrapeForm | null>(null);

const initModelForm = () => {
  peopleScrapeForm.value = {
    url: "",
    agencyId: undefined,
    groupId: undefined,
    newGroupName: "",
    newAgencyName: "",
  };

  selectedPeopleScrapeId.value = null;
};

const handleSelect = (peopleScrape: types.PeopleScrape) => {
  selectedPeopleScrapeId.value = peopleScrape.id;
  peopleScrapeForm.value = null;
};

const { pause, resume } = useIntervalFn(async () => {
  const result = await axios({
    method: "get",
    url: "/dashboard/super_admin/api/people_scrapes.json",
    params: {
      ids: activePeopleScrapes.value.map((scrape) => scrape.id),
    },
  });

  const updatedPeopleScrapes = result.data as types.PeopleScrape[];

  for (const peopleScrape of updatedPeopleScrapes) {
    const index = peopleScrapes.value.findIndex(
      (scrape) => scrape.id === peopleScrape.id
    );
    if (index !== -1) {
      peopleScrapes.value[index] = peopleScrape;
    }
  }
}, 1000);

pause();

const activePeopleScrapes = computed(() => {
  return peopleScrapes.value.filter(
    (scrape) => scrape.status === "in_progress" || scrape.status === "pending"
  );
});

const anyActive = computed(() => {
  return activePeopleScrapes.value.length > 0;
});

watch(
  anyActive,
  (anyActive) => {
    if (anyActive) {
      resume();
    } else {
      pause();
    }
  },
  { immediate: true }
);

const selectedPeopleScrape = computed(() => {
  return peopleScrapes.value.find(
    (scrape) => scrape.id === selectedPeopleScrapeId.value
  );
});
</script>
