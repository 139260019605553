<template>
  <div class="p-3">
    <div class="grid gap-4 gap-y-0 grid-cols-2" v-if="form">
      <FormErrors :errors="errors" class="col-span-2" />

      <FormControl :errors="errors?.startAt">
        <template #label>Start Date</template>
        <input
          class="input is-sm"
          v-model="form.startAt"
          type="datetime-local"
        />
      </FormControl>

      <FormControl :errors="errors?.endAt">
        <template #label>End Date</template>
        <input class="input is-sm" v-model="form.endAt" type="datetime-local" />
      </FormControl>

      <FormControl :errors="errors?.periodRateInPence">
        <template #label>Period Rate in £</template>
        <input
          class="input is-sm"
          v-model="periodRateInPounds"
          type="number"
          step="0.01"
        />
      </FormControl>

      <FormControl :errors="errors?.overageRateInPence">
        <template #label>Overage Rate in £/1k images</template>
        <input
          class="input is-sm"
          v-model="overageRateInPoundsPer1k"
          type="number"
          step="0.01"
        />
      </FormControl>

      <FormControl :errors="errors?.includedAiProcesses">
        <template #label>Process Limit</template>
        <input
          class="input is-sm"
          v-model="form.includedAiProcesses"
          type="number"
        />
      </FormControl>

      <FormControl>
        <FormCheckbox v-model="form.freeTrial" :errors="errors?.freeTrial">
          <template #label><span class="text-base">Free Trial</span></template>
          <template #helper>Exclude from charges</template>
        </FormCheckbox>
      </FormControl>

      <div class="col-span-2 flex justify-between">
        <SaveBtn :save-status="saveStatus" size="small" @click="save" />
        <div class="flex justify-end gap-2">
          <Btn size="small" color="danger" fill="outline" @click="handleDelete"
            >Delete</Btn
          >

          <Btn
            size="small"
            color="secondary"
            fill="outline"
            @click="handleCancel"
            >Cancel</Btn
          >
        </div>
      </div>
    </div>
    <div v-else-if="billingPeriod" class="space-y-1">
      <div class="flex justify-between items-start">
        <div class="items-center flex flex-wrap gap-x-2 gap-y-1">
          <span class="text-gray-700">
            {{ niceDate(billingPeriod.startAt, "UTC") }} -
            {{ niceDate(billingPeriod.endAt, "UTC") }}
          </span>
          <span class="text-gray-500 text-xs"
            >({{ Math.round(billingPeriod.percentComplete) }}% complete)</span
          >
          <span
            v-if="billingPeriod.freeTrial"
            class="bg-blue-200 text-blue-800 text-xs py-px rounded px-2 flex justify-start gap-1 items-center"
            ><CurrencyPoundIcon class="w-3 h-3" /> Free Trial</span
          >
          <span
            v-if="willRenew"
            class="bg-blue-200 text-blue-800 text-xs py-px rounded px-2 flex justify-start gap-1 items-center"
            ><ArrowPathIcon class="w-3 h-3" /> Renews in
            {{ renewDays }} days</span
          >
        </div>

        <Btn
          @click="edit"
          size="tiny"
          fill="outline"
          color="secondary"
          :disabled="saveStatus === 'saving'"
          class="!py-1"
          ><PencilIcon class="w-3 h-3"
        /></Btn>
      </div>

      <div
        v-if="!!billingAccount.automaticInvoicingStartDate"
        class="flex justify-start gap-2"
      >
        <div>
          <span class="text-gray-400">Base rate of </span>
          <span>{{ niceCurrency(billingPeriod.periodRateInPence) }}</span>
        </div>

        <dive>
          <span class="text-gray-400">Overage rate of </span>
          <span>
            {{ niceCurrency(billingPeriod.overageRateInPence * 1000) }}</span
          >
          <span class="text-gray-400"> / 1k AI processes</span>
        </dive>
      </div>

      <div v-if="billingPeriod.invoice">
        <a
          :href="`https://dashboard.stripe.com/invoices/${billingPeriod.invoice.stripeId}`"
          target="_blank"
          :class="{
            'text-red-600':
              billingPeriod.invoice.status === 'void' ||
              billingPeriod.invoice.status === 'uncollectible' ||
              (billingPeriod.invoice.status === 'open' &&
                billingPeriod.invoice.attemptCount > 0),
            'text-green-600': billingPeriod.invoice.status === 'paid',
          }"
          class="underline"
        >
          Invoice of {{ niceCurrency(invoiceTotal!) }}
          {{ billingPeriod.invoice.status }}
          {{
            billingPeriod.invoice.status === "open" &&
            billingPeriod.invoice.attemptCount > 0
              ? " (payment failed, will retry)"
              : ""
          }}</a
        >
      </div>

      <ProgressBar
        :count="billingPeriod.aiProcessesCount"
        :limit="billingPeriod.includedAiProcesses"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { formatUTCDateForInput, niceDate } from "../../../utility/time";
import useModelApi from "../../global/useModelApi";
import { cloneDeep } from "lodash";
import FormControl from "../../global/FormControl.vue";
import Btn from "../../global/Btn.vue";
import {
  ArrowPathIcon,
  CurrencyPoundIcon,
  PencilIcon,
} from "@heroicons/vue/24/solid";
import FormErrors from "../../global/FormErrors.vue";
import SaveBtn from "../../global/SaveBtn.vue";
import FormCheckbox from "../../global/FormCheckbox.vue";
import { differenceInDays, parseISO, startOfToday } from "date-fns";
import ProgressBar from "../../global/ProgressBar.vue";
import { niceCurrency } from "../../../utility/helpers";

const props = defineProps<{
  billingPeriod?: types.BillingPeriod | null;
  billingPeriodForm?: types.Form<schema.BillingPeriod> | null;
  renewable?: boolean;
  billingAccount: types.BillingAccount;
}>();

const emit = defineEmits<{
  (event: "save", billingPeriod: types.BillingPeriod): types.BillingPeriod;
  (event: "delete"): types.BillingPeriod;
  (event: "cancel"): void;
}>();

const form = ref<types.Form<schema.BillingPeriod> | null>(
  props.billingPeriodForm ?? null
);

const overageRateInPoundsPer1k = ref<number | null>();
const periodRateInPounds = ref<number | null>();

if (props.billingPeriodForm?.periodRateInPence)
  periodRateInPounds.value = props.billingPeriodForm.periodRateInPence / 100;
if (props.billingPeriodForm?.overageRateInPence)
  overageRateInPoundsPer1k.value =
    (props.billingPeriodForm.overageRateInPence * 1000) / 100;

const { api, errors, saveStatus, reset } = useModelApi<schema.BillingPeriod>();

const edit = async () => {
  reset();
  if (!props.billingPeriod) return;
  form.value = cloneDeep(props.billingPeriod);
  form.value.startAt = formatUTCDateForInput(
    parseISO(props.billingPeriod.startAt)
  );
  form.value.endAt = formatUTCDateForInput(parseISO(props.billingPeriod.endAt));
  if (form.value.periodRateInPence)
    periodRateInPounds.value = form.value.periodRateInPence / 100;
  if (form.value.overageRateInPence)
    overageRateInPoundsPer1k.value =
      (form.value.overageRateInPence * 1000) / 100;
};

const save = async () => {
  if (!form.value) return;

  const method = form.value.id ? "put" : "post";
  const url = form.value.id
    ? `/dashboard/super_admin/api/billing_periods/${form.value.id}`
    : `/dashboard/super_admin/api/billing_periods`;

  if (periodRateInPounds.value) {
    form.value.periodRateInPence = periodRateInPounds.value * 100;
  }
  if (overageRateInPoundsPer1k.value) {
    form.value.overageRateInPence =
      (overageRateInPoundsPer1k.value / 1000) * 100;
  }
  const data = await api({
    method: method,
    url,
    data: {
      billing_period: form.value,
    },
  });

  if (data) {
    emit("save", data.billingPeriod as types.BillingPeriod);
    form.value = null;
  }
};

const handleDelete = async () => {
  if (!props.billingPeriod?.id) return;
  const data = await api({
    method: "delete",
    url: `/dashboard/super_admin/api/billing_periods/${props.billingPeriod.id}`,
  });

  if (data) {
    emit("delete");
  }
};

const handleCancel = () => {
  if (props.billingPeriod?.id) {
    form.value = null;
  } else {
    emit("cancel");
  }
};

const endsInFuture = computed(() => {
  if (!props.billingPeriod) return false;

  const today = startOfToday();
  const endAt = new Date(props.billingPeriod.endAt);

  return today <= endAt;
});

const willRenew = computed(() => {
  return !!props.renewable && endsInFuture.value;
});

const renewDays = computed(() => {
  if (!props.billingPeriod) return null;

  const today = startOfToday();
  const endAt = new Date(props.billingPeriod.endAt);

  return differenceInDays(endAt, today);
});

const invoiceTotal = computed(() => {
  return props.billingPeriod?.invoice?.invoiceItems?.reduce(
    (total, item) => total + item.amount,
    0
  );
});
</script>
