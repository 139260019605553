import { ref } from "vue";
import useSaveStatus from "./useSaveStatus";
import createAxiosClient from "../../utility/axios_client";
import { AxiosInstance } from "axios";

export default function useModelApi<T>() {
  const saveStatus = useSaveStatus();
  const errors = ref<types.FormErrors<T> | null>();

  const api = async (config: {
    axios?: AxiosInstance;
    method: types.HTTPMethod;
    url: string;
    data?: any;
  }): Promise<false | Record<string, any>> => {
    saveStatus.value = "saving";
    errors.value = null;
    const axios = config.axios ?? createAxiosClient();

    try {
      const response = await axios(config);
      saveStatus.value = "success";
      return response.data;
    } catch (e: any) {
      console.error(e);
      if (e.response?.data?.errors) {
        errors.value = e.response.data.errors;
      } else {
        errors.value = {
          error: ["Something went wrong"],
        } as types.FormErrors<T>;
      }
      saveStatus.value = "error";
    }
    return false;
  };

  const reset = () => {
    saveStatus.value = "idle";
    errors.value = null;
  };

  return {
    saveStatus,
    errors,
    api,
    reset,
  };
}
