<template>
  <a :href="href" :class="classes" :target="target" :disabled="disabled">
    <Loader v-if="busy" :shade="loaderShade" />
    <span :class="textClasses">
      <slot />
    </span>
  </a>
</template>

<script setup lang="ts">
import Loader from "./Loader.vue";
import useBtn, { BtnFill } from "./useBtn";

const props = defineProps<{
  color?: types.Color;
  fill?: BtnFill;
  size?: types.Size;
  href: string;
  target?: string;
  disabled?: boolean;
  busy?: boolean;
}>();

const { classes, textClasses, loaderShade } = useBtn(props);
</script>
