<template>
  <AdminLayout>
    <div class="flex flex-col gap-4">
      <h4 class="font-bold text-xl">
        {{ agency.name }}
        <span class="text-sm text-gray-500 font-normal ml-2"
          >Current Timezone: <span class="font-mono">{{ timezone }}</span></span
        >
      </h4>

      <DateSelection
        v-model:start-date="startDate"
        v-model:end-date="endDate"
        v-model:date-interval="dateInterval"
        v-model:selected-billing-period-id="selectedBillingPeriodId"
        :billing-periods="billingPeriods"
      />

      <FetchLoader :fetch-status="processCountsFetchStatus">
        <UsageGraph
          :user-process-counts="userProcessCountsByEmail"
          :process-counts="processCounts"
          v-if="processCounts"
        />
      </FetchLoader>

      <div class="flex justify-between flex-wrap gap-2" id="user-filters">
        <div class="flex justify-start flex-wrap gap-x-5 gap-y-2">
          <SortFilters :sort="modelSort" />
          <div class="h-full">
            <select v-model="statusFilter" class="input w-auto shadow">
              <option :value="null">All</option>
              <option value="active">Active</option>
              <option value="agency_disabled">Disabled</option>
            </select>
          </div>
        </div>

        <LimitTracker
          v-if="agency"
          :count="users.length"
          :limit="agency.userLimit"
          limit-tooltip="You have reached the user limit for your current package. Please contact us using to upgrade if you require more users."
          >Total Users</LimitTracker
        >

        <div class="flex justify-end flex-wrap items-center gap-2">
          <Btn
            color="primary"
            @click="showBulkUpdatePanel = true"
            :disabled="multiSelectedModelIds.length === 0"
            ><UsersIcon class="w-4 h-4" /> Bulk update</Btn
          >

          <Btn
            color="primary"
            @click="initModelForm"
            :disabled="userLimitReached"
            ><i class="fa fa-plus-circle mr-1" /> Add new user</Btn
          >
        </div>
      </div>

      <FetchLoader :fetch-status="userFetchStatus">
        <UserTable
          :users="filteredAndSortedUsers"
          :selected-user-id="modelForm?.id"
          :multi-selected-user-ids="multiSelectedModelIds"
          @select="selectModel"
          @multiselect="multiSelectModel"
          @bulkselect="bulkSelectUsers"
        />
      </FetchLoader>
    </div>

    <template
      #sidebar
      v-if="modelForm || (multiSelectedModelIds.length && showBulkUpdatePanel)"
    >
      <SelectedUsers
        v-if="showBulkUpdatePanel && multiSelectedModelIds.length > 0"
        :user-ids="multiSelectedModelIds"
        @update="updateModels"
        @close="showBulkUpdatePanel = false"
      />
      <SelectedUser
        v-else-if="modelForm"
        :user-form="modelForm"
        :current-user-id="currentUserId"
        @save="saveModel"
        @close="selectModel(null)"
        @delete="deleteModel"
      />
    </template>
  </AdminLayout>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import AdminLayout from "../global/AdminLayout.vue";
import DateSelection from "./Users/DateSelection.vue";
import SelectedUser from "./Users/SelectedUser.vue";
import UsageGraph from "./Users/UsageGraph.vue";
import UserTable from "./Users/UserTable.vue";
import Btn from "../global/Btn.vue";
import useModelList from "../global/useModelList";
import SelectedUsers from "./Users/SelectedUsers.vue";
import SortFilters from "../global/SortFilters.vue";
import { sortBy } from "lodash";
import { differenceInDays, sub } from "date-fns";
import { UsersIcon } from "@heroicons/vue/24/solid";
import {
  currentTimezone,
  formatDateForInput,
  orderedByTimeField,
} from "../../utility/time";
import useFetchApi from "../global/useFetchApi";
import FetchLoader from "../global/FetchLoader.vue";
import LimitTracker from "../global/LimitTracker.vue";

const props = defineProps<{
  agency: schema.Agency;
  currentUserId: number;
  billingPeriods: types.BillingPeriod[];
}>();

const {
  models: users,
  saveModel,
  updateModels,
  modelForm,
  selectModel,
  multiSelectedModelIds,
  clearMultiSelectedModels,
  multiSelectModel,
  modelSort,
  deleteModel,
  initModelForm,
} = useModelList<types.AgencyUser>({
  sortFields: {
    email: "Email",
    agencyMaxProcessesPerMonth: "Monthly process limit",
    processCount: "Processed within period",
    createdAt: "Date Created",
    lastActiveAt: "Last Active",
  },
  defaultModel: {
    email: "",
    isAgencyAdmin: false,
    firstName: "",
    lastName: "",
    status: "active",
    agencyMaxProcessesPerMonth: null,
  },
});

modelSort.value.field = "lastActiveAt";
modelSort.value.direction = "desc";

const timezone = currentTimezone();
const statusFilter = ref<schema.User["status"] | null>(null);
const startDate = ref<string | undefined>(
  formatDateForInput(sub(new Date(), { days: 30 }))
);
const endDate = ref<string | undefined>(formatDateForInput(new Date()));
const dateInterval = ref<"day" | "month">("day");
const selectedBillingPeriodId = ref<number | null>(null);
const processCounts = ref<types.DateData | null>();
const showBulkUpdatePanel = ref(false);
const userProcessCounts = ref<Record<string, types.DateData>>({});

const userProcessCountsByEmail = computed(() => {
  const result: Record<string, types.DateData> = {};
  for (const [userId, data] of Object.entries(userProcessCounts.value)) {
    const user = users.value.find((user) => user.id === parseInt(userId));
    if (user) {
      result[user.email] = data;
    }
  }
  return result;
});

const { fetchStatus: userFetchStatus, fetchApi: userFetchApi } = useFetchApi();

const fetchUsers = async () => {
  if (!startDate.value || !endDate.value) {
    return;
  }

  const data = await userFetchApi({
    url: "/dashboard/agency_admin/api/users.json",
    params: {
      startDate: startDate.value,
      endDate: endDate.value,
      timezone,
    },
  });

  if (data) {
    users.value = data.users.map((user: types.User) => {
      return {
        ...user,
        createdAt: user.createdAt ? new Date(user.createdAt) : null,
        lastActiveAt: user.lastActiveAt ? new Date(user.lastActiveAt) : null,
      };
    });
  }
};

const {
  fetchStatus: processCountsFetchStatus,
  fetchApi: processCountsFetchApi,
} = useFetchApi();

const fetchProcessCounts = async (userIds?: number[]) => {
  const data = await processCountsFetchApi({
    url: "/dashboard/agency_admin/api/billable_ai_process_counts.json",
    params: {
      startDate: startDate.value,
      endDate: endDate.value,
      dateInterval: dateInterval.value,
      userIds: userIds ? userIds : undefined,
      timezone,
    },
  });
  if (data) {
    return data.processCounts;
  }
};

const bulkSelectUsers = () => {
  if (multiSelectedModelIds.value.length === 0) {
    multiSelectedModelIds.value = filteredAndSortedUsers.value.map(
      (user) => user.id
    );
  } else {
    clearMultiSelectedModels();
  }
};

const filteredAndSortedUsers = computed(() => {
  let result = [...users.value];

  if (statusFilter.value) {
    result = result.filter((user) => user.status === statusFilter.value);
  }

  if (modelSort.value.field) {
    if (modelSort.value.field === "lastActiveAt") {
      result = orderedByTimeField(
        result,
        "lastActiveAt",
        modelSort.value.direction
      );
    } else {
      result = sortBy(result, modelSort.value.field);
      if (modelSort.value.direction === "desc") {
        result = result.reverse();
      }
    }
  } else {
    result = sortBy(result, "id").reverse();
  }

  return result;
});

const handleFilterChange = () => {
  if (!startDate.value || !endDate.value) {
    return;
  }

  if (dateInterval.value === "day") {
    const startAt = new Date(startDate.value);
    const endAt = new Date(endDate.value);
    const days = differenceInDays(endAt, startAt);
    if (days > 100) {
      alert(
        "Can't show more than 100 days of data at a time when using day interval"
      );
      return;
    }
  }

  const userIds = filteredAndSortedUsers.value.map((user) => user.id);
  fetchProcessCounts(userIds).then((newProcessCounts) => {
    processCounts.value = newProcessCounts;
  });

  if (multiSelectedModelIds.value.length > 0) {
    for (const userId of multiSelectedModelIds.value) {
      fetchProcessCounts([userId]).then((newUserProcessCounts) => {
        userProcessCounts.value[userId] = newUserProcessCounts;
      });
    }

    for (const userId of Object.keys(userProcessCounts.value)) {
      if (!multiSelectedModelIds.value.includes(parseInt(userId))) {
        delete userProcessCounts.value[userId];
      }
    }
  }
};

watch([startDate, endDate, dateInterval, statusFilter], handleFilterChange);

watch([startDate, endDate], fetchUsers);

watch(
  multiSelectedModelIds,
  (newIds, oldIds) => {
    if (!startDate.value || !endDate.value) {
      return;
    }

    const addedIds = [...newIds].filter((id) => !oldIds.includes(id));
    const removedIds = [...oldIds].filter((id) => !newIds.includes(id));

    for (const userId of addedIds) {
      fetchProcessCounts([userId]).then((newUserProcessCounts) => {
        userProcessCounts.value[userId] = newUserProcessCounts;
      });
    }

    for (const userId of removedIds) {
      delete userProcessCounts.value[userId];
    }
  },
  { deep: true }
);

watch(statusFilter, () => {
  if (!statusFilter.value) {
    return;
  }

  multiSelectedModelIds.value = multiSelectedModelIds.value.filter((userId) => {
    return (
      users.value.find((user) => user.id === userId)?.status ===
      statusFilter.value
    );
  });
});

onMounted(async () => {
  await fetchUsers();
  handleFilterChange();
});

const userLimitReached = computed(() => {
  if (!props.agency.userLimit) {
    return false;
  }

  return users.value.length >= props.agency.userLimit;
});
</script>
