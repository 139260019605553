<template>
  <SidePanel>
    <PanelSection>
      <h2 class="text-lg font-bold mb-2">
        Bulk update
        <small class="text-sm text-gray-500"
          >{{ userIds.length }}
          {{ userIds.length > 1 ? "users" : "user" }}</small
        >
      </h2>

      <FormErrors :errors="errors" />

      <FormControl :errors="errors?.agencyMaxProcessesPerMonth">
        <template #label>Max billable processes per calendar month</template>
        <input
          v-model="userForm.agencyMaxProcessesPerMonth"
          class="input"
          type="number"
          placeholder="unlimited"
        />
      </FormControl>
    </PanelSection>

    <template #buttons-left>
      <SaveBtn :saveStatus="saveStatus" @save="save" />
    </template>

    <template #buttons-right>
      <Btn
        @click="emit('close')"
        :disabled="saveStatus === 'saving'"
        color="secondary"
        fill="underline"
        >Cancel</Btn
      >
    </template>
  </SidePanel>
</template>

<script setup lang="ts">
import { ref } from "vue";
import SidePanel from "../../global/SidePanel.vue";
import SaveBtn from "../../global/SaveBtn.vue";
import Btn from "../../global/Btn.vue";
import FormControl from "../../global/FormControl.vue";
import FormErrors from "../../global/FormErrors.vue";
import PanelSection from "../../global/SidePanel/PanelSection.vue";
import useModelApi from "../../global/useModelApi";

const props = defineProps<{
  userIds: number[];
}>();

const emit = defineEmits<{
  (event: "close"): void;
  (event: "update", user: types.AgencyUser[]): void;
}>();

const { saveStatus, errors, api } = useModelApi<types.AgencyUser>();

const userForm = ref<types.Form<types.AgencyUser>>({
  agencyMaxProcessesPerMonth: null,
});

const save = async () => {
  const data = {
    user: userForm.value,
    ids: props.userIds,
  };

  const responseData = await api({
    url: `dashboard/agency_admin/api/users/bulk_update.json`,
    method: "put",
    data,
  });

  if (responseData) {
    emit("update", responseData.users as types.AgencyUser[]);
    emit("close");
  }
};
</script>
