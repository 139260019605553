<template>
  <SidePanel id="selected-person">
    <PanelSection>
      <FormErrors v-if="personErrors" :errors="personErrors" />

      <FormControl :errors="personErrors?.name">
        <template #label>Name</template>
        <input v-model="formPerson.name" class="input" name="name" />
      </FormControl>

      <FormControl :errors="personErrors?.prefix">
        <template #label>Prefix</template>
        <input v-model="formPerson.prefix" class="input" name="prefix" />
      </FormControl>

      <FormControl :errors="personErrors?.postfix">
        <template #label>Postfix</template>
        <input v-model="formPerson.postfix" class="input" name="postfix" />
      </FormControl>

      <FormControl>
        <FullName
          :prefix="formPerson.prefix"
          :name="formPerson.name"
          :postfix="formPerson.postfix"
        />
      </FormControl>
    </PanelSection>

    <PanelSection
      v-for="(groupMembership, i) of (groupMemberships as types.Form<types.GroupMembership>[])"
    >
      <GroupMembership
        :axios="axios"
        :person="formPerson"
        :groupMembership="groupMembership"
        :groups="groups"
        :allow-affixes="groupMemberships.length >= 2"
        @delete="deleteGroupMembership(i)"
      />
    </PanelSection>

    <PanelSection class="flex justify-center">
      <Btn size="small" @click="addNewGroupMembership"
        ><UsersIcon class="w-4 h-4" /> Attach to new group</Btn
      >
    </PanelSection>

    <PanelSection>
      <div v-if="celebFace" class="flex items-center gap-x-2">
        <div
          class="rounded-full text-xs border border-gray-800 inline-flex justify-start gap-x-2 items-stretch overflow-hidden"
          :class="{
            'cursor-pointer hover:opacity-80 transition-opacity': celebUrl,
          }"
          @click="visitCelebUrl"
        >
          <div
            class="bg-gray-800 px-3 pr-2 flex justify-center items-center text-white"
          >
            <i class="fa fa-database" />
          </div>
          <div
            class="px-1 pr-3 py-1.5 text-gray-800 flex items-center leading-none"
          >
            Caption Pro Celebrity Record
          </div>
        </div>
        <div
          class="underline cursor-pointer text-sm"
          @click="removeCelebrityFace"
        >
          Remove
        </div>
      </div>

      <div ref="imageDropZone" class="relative mt-6">
        <div
          v-if="isOverDropZone"
          class="absolute opacity-90 p-2 inset-0 bg-white z-10"
        >
          <div
            class="w-full h-full border-8 border-dashed border-gray-400 rounded-xl flex flex-col justify-center items-center text-xl font-bold"
          >
            <div class="text-center text-4xl">
              <i class="fa fa-image" />
            </div>
            <div class="text-center text-gray-500 text-sm">
              Drag and drop images here
            </div>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div
            data-reference-face-wrapper
            class="w-1/3 pt-[33.33%] relative"
            v-for="face in orderedReferenceFaces"
            @click="removeFace(face.id!)"
          >
            <div class="absolute inset-0">
              <ReferenceFace
                :key="face.id"
                :face="face"
                :s3="s3"
                :s3-bucket="s3Bucket"
                @credentialsexpired="emit('credentialsexpired')"
                class="w-full h-full"
              />
            </div>

            <div
              data-remove-face-overlay
              class="absolute inset-0 bg-black/50 text-4xl flex justify-center items-center z-10 opacity-0 text-white transition-opacity"
              :class="{ 'hover:opacity-80 cursor-pointer': canRemoveFace }"
              :title="'Added by: ' + face.userName"
            >
              <i class="fa fa-times"></i>
            </div>
          </div>
          <ReferenceFace
            v-for="n in processingFaceCount"
            :key="n"
            :face="{}"
            class="w-1/3 pt-[33.33%]"
          />
        </div>

        <div class="px-4 mt-4">
          <Notice color="danger" v-if="anyFaceErrors">
            <div v-if="noFaceErrorCount">
              {{ noFaceErrorCount }}
              {{ noFaceErrorCount > 1 ? "images" : "image" }} did not contain
              any faces.
            </div>
            <div v-if="failedToProcessErrorCount">
              {{ failedToProcessErrorCount }}
              {{ noFaceErrorCount > 1 ? "images" : "image" }} failed to process.
            </div>
            <div v-if="incorrectImageFormatErrorCount">
              {{ incorrectImageFormatErrorCount }}
              {{ incorrectImageFormatErrorCount > 1 ? "images" : "image" }} in
              the incorrect format. Images must be JPG or PNG files.
            </div>
          </Notice>
        </div>

        <div
          class="px-4 mt-6 flex flex-col gap-y-4 justify-center items-center gap-x-2"
        >
          <div
            v-if="!referenceFaces.length"
            class="text-red-500/80 flex items-center text-sm"
          >
            Must add at least one reference face
          </div>
          <div
            v-if="!freeFaceSlots"
            class="text-gray-500 flex items-center text-sm"
          >
            All reference faces filled. Remove an existing face to add a new
            one.
          </div>
          <FileBtn
            class="max-w-[160px]"
            size="small"
            color="primary"
            fill="filled"
            v-else
            @change="handleFileInput"
            :multiple="true"
            accept="image/png, image/jpeg"
            title="Click to upload or drag and drop images. You can upload up to 9 faces per person. The largest face in each given image will be used."
            ><i class="fa fa-plus-circle mr-1" />Upload Faces</FileBtn
          >
        </div>
      </div>
    </PanelSection>

    <PanelSection v-if="associatedUsers.length">
      <FormControl>
        <template #label>Contributing Users</template>
        <div
          class="rounded border border-gray-200 divide-gray-200 divide-y text-sm"
        >
          <div class="px-2 py-1 text-gray-700" v-for="user of associatedUsers">
            {{ user }}
          </div>
        </div>
      </FormControl>
    </PanelSection>

    <template #buttons-left>
      <SaveBtn
        :saveStatus="saveStatus"
        @save="save"
        :disabled="formClean || !referenceFaces.length"
      />
    </template>

    <template #buttons-right>
      <Btn
        v-if="person.id"
        @click="remove"
        :busy="saveStatus === 'saving'"
        color="danger"
        fill="underline"
        size="small"
        >Delete</Btn
      >
    </template>
  </SidePanel>
</template>

<script setup lang="ts">
import { S3Client } from "@aws-sdk/client-s3";
import { cloneDeep, isEqual, pick, sortBy, uniq } from "lodash";
import { computed, nextTick, ref, watch } from "vue";
import ReferenceFace from "./ReferenceFace.vue";
import Btn from "../../global/Btn.vue";
import { AxiosInstance } from "axios";
import { processImage } from "../../../utility/faceProcessing";
import FileBtn from "../../global/FileBtn.vue";
import { useDropZone } from "@vueuse/core";
import Notice from "../../global/Notice.vue";
import useSaveStatus from "../../global/useSaveStatus";
import SidePanel from "../../global/SidePanel.vue";
import PanelSection from "../../global/SidePanel/PanelSection.vue";
import FormErrors from "../../global/FormErrors.vue";
import FormControl from "../../global/FormControl.vue";
import SaveBtn from "../../global/SaveBtn.vue";
import GroupMembership from "./SelectedPerson/GroupMembership.vue";
import { UsersIcon } from "@heroicons/vue/24/solid";
import FullName from "../../global/FullName.vue";
import RekognitionInterface from "~/utility/rekognitionInterface";
import FaceEngineInterface from "~/utility/faceEngineInterface";

const props = defineProps<{
  person: types.Person;
  faces: types.Face[];
  s3?: S3Client;
  s3Bucket: string;
  axios: AxiosInstance;
  userId: number;
  rekognitionCollectionId: string;
  railsCollectionId: number;
  rekognition?: RekognitionInterface | FaceEngineInterface;
  groups: types.Group[];
}>();

const emit = defineEmits<{
  (e: "credentialsexpired"): void;
  (e: "save", person: schema.Person, faces: schema.Face[]): void;
  (e: "remove", personId: number): void;
}>();

const loadForm = (person: types.Person): types.Form<types.Person> => {
  return {
    ...pick(cloneDeep(person), "name", "prefix", "postfix", "groupMemberships"),
    faceIds: props.faces.map((f) => f.id!),
  };
};

const formPerson = ref<types.Form<types.Person>>(loadForm(props.person));
const originalFormPerson = ref<types.Form<types.Person>>(
  loadForm(props.person)
);
const saveStatus = useSaveStatus();
const personErrors = ref<types.FormErrors<types.Person> | null>();
const allFaces = ref<types.Face[]>(props.faces);
const processingFaceCount = ref(0);

const addNewGroupMembership = () => {
  formPerson.value.groupMemberships!.push({
    personId: props.person.id,
    groupId: undefined,
    prefix: undefined,
    postfix: undefined,
  });
};

const deleteGroupMembership = (index: number) => {
  formPerson.value.groupMemberships!.splice(index, 1);
};

const groupMemberships = computed(() => {
  return sortBy(formPerson.value.groupMemberships, "id");
});

const save = async () => {
  saveStatus.value = "saving";
  personErrors.value = null;
  const personId = props.person.id;

  const personData = cloneDeep(formPerson.value) as types.Form<types.Person> & {
    groupMembershipsAttributes: types.ChildForm<types.GroupMembership>[];
  };
  personData.groupMembershipsAttributes =
    personData.groupMemberships as types.Form<types.GroupMembership>[];
  delete personData.groupMemberships;

  const deletedIds = originalFormPerson.value
    .groupMemberships!.map((gm) => gm?.id)
    .filter(Boolean)
    .filter(
      (id) => !personData.groupMembershipsAttributes.find((gm) => gm.id === id)
    );

  for (const id of deletedIds) {
    personData.groupMembershipsAttributes.push({
      id,
      _destroy: true,
    });
  }

  try {
    const url = personId ? `api/people/${personId}.json` : "api/people.json";
    const method = personId ? "put" : "post";
    const result = await props.axios({
      method,
      url,
      data: {
        person: personData,
      },
    });

    saveStatus.value = "success";

    // If there are deleted faces, they are returned by the api, just they no longer are attached to the person
    const newFaces: schema.Face[] = result.data.faces
      .filter((f: schema.Face) => f.personId)
      .filter(Boolean);
    emit("save", result.data.person, newFaces);
  } catch (e: any) {
    // If they switched to another person, ignore the error
    if (props.person.id === personId) {
      saveStatus.value = "error";
      if (e.response.data.errors) {
        personErrors.value = e.response.data.errors;
      } else {
        personErrors.value = { base: ["Something went wrong"] };
      }
    }
  }
};

const remove = async () => {
  const confirmed = window.confirm(
    `Are you sure you want to delete ${props.person.name}? This action cannot be undone.`
  );
  if (!confirmed) return;

  saveStatus.value = "saving";
  personErrors.value = null;
  const id = props.person.id;

  try {
    await props.axios({
      method: "delete",
      url: `api/people/${id}.json`,
    });

    emit("remove", id!);
  } catch (e) {
    if (saveStatus.value === "saving") saveStatus.value = "error";
  } finally {
    saveStatus.value = "idle";
  }
};

watch(
  () => props.person,
  () => {
    nextTick(() => {
      formPerson.value = loadForm(props.person);
      originalFormPerson.value = loadForm(props.person);
      allFaces.value = props.faces;
      personErrors.value = null;
      noFaceErrorCount.value = 0;
      failedToProcessErrorCount.value = 0;
      incorrectImageFormatErrorCount.value = 0;
    });
  },
  { deep: true }
);

// Reference Faces
const referenceFaces = computed(() => {
  return allFaces.value.filter((f) => f.rekognitionId);
});

const orderedReferenceFaces = computed(() => {
  return sortBy(referenceFaces.value, "id");
});

const formClean = computed(() => {
  if (formPerson.value.groupMemberships?.find((gm) => !gm!.groupId))
    return true;
  return isEqual(formPerson.value, originalFormPerson.value);
});

const freeFaceSlots = computed(() => {
  return 9 - referenceFaces.value.length;
});

const associatedUsers = computed(() => {
  return uniq(referenceFaces.value.map((f) => f.userName).filter(Boolean));
});

// Image processing
let noFaceErrorCount = ref(0);
let failedToProcessErrorCount = ref(0);
let incorrectImageFormatErrorCount = ref(0);

const anyFaceErrors = computed(
  () =>
    noFaceErrorCount.value ||
    failedToProcessErrorCount.value ||
    incorrectImageFormatErrorCount.value
);

const handleFileInput = async (files: File[] | FileList | null) => {
  if (!files) return;
  failedToProcessErrorCount.value = 0;
  noFaceErrorCount.value = 0;
  incorrectImageFormatErrorCount.value = 0;
  const allowedFiles = Array.from(files).slice(0, freeFaceSlots.value);
  processingFaceCount.value += allowedFiles.length;

  for (const file of allowedFiles) {
    processImage(
      file,
      props.userId,
      props.s3Bucket,
      props.s3!,
      props.rekognitionCollectionId,
      props.rekognition!,
      props.axios,
      props.railsCollectionId
    )
      .then((face) => {
        if (face) {
          allFaces.value.push(face);
          formPerson.value.faceIds!.push(face.id);
        } else {
          noFaceErrorCount.value += 1;
        }
      })
      .catch((e) => {
        console.error(e);
        if (e.message.includes("invalid image format")) {
          incorrectImageFormatErrorCount.value += 1;
        } else if (e.message.match(/.*(expired|token).*/i)) {
          emit("credentialsexpired");
          failedToProcessErrorCount.value += 1;
        } else {
          failedToProcessErrorCount.value += 1;
        }
      })
      .finally(() => {
        processingFaceCount.value -= 1;
      });
  }
};

// Image upload drop zone
const imageDropZone = ref<HTMLDivElement>();
const { isOverDropZone } = useDropZone(imageDropZone, handleFileInput);

// Face removal
const canRemoveFace = computed(() => {
  return referenceFaces.value.length > 1;
});

const removeFace = (faceId: number) => {
  if (!canRemoveFace.value) return;

  allFaces.value = allFaces.value.filter((f) => f.id !== faceId);
  formPerson.value.faceIds = formPerson.value.faceIds!.filter(
    (id) => id !== faceId
  );
};

// Celebrity face
const celebFace = computed(() => {
  return allFaces.value.find((f) => f.celebrityId);
});

const removeCelebrityFace = () => {
  if (celebFace.value) {
    formPerson.value.faceIds = formPerson.value.faceIds!.filter(
      (id) => id !== celebFace.value!.id
    );
    allFaces.value = allFaces.value.filter((f) => f.id !== celebFace.value!.id);
  }
};

const visitCelebUrl = () => {
  if (celebUrl.value) window.open(celebUrl.value, "_blank");
};

const celebUrl = computed(() => {
  if (props.person.imdbUrl) {
    if (props.person.imdbUrl.includes("https")) {
      return props.person.imdbUrl;
    } else {
      return `https://${props.person.imdbUrl}`;
    }
  } else {
    return null;
  }
});
</script>
