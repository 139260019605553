<template>
  <div
    data-name="Loader"
    class="spinner pointer-events-none before:border-2 relative"
    :class="classes"
  ></div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  shade?: "dark" | "light";
}>();

const classes = computed(() => {
  return {
    "before:border-white/50 before:border-t-white": props.shade === "light",
    "before:border-gray-900/20 before:border-t-gray-800":
      props.shade === "dark",
  };
});
</script>

<style scoped lang="scss">
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  animation: spinner 0.6s linear infinite;
}
</style>
