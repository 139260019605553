<template>
  <th
    scope="col"
    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
  >
    <slot />
  </th>
</template>

<script setup lang="ts"></script>
