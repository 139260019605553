<template>
  <div class="flex flex-col space-between h-full overflow-hidden">
    <div class="divide-y text-sm flex-grow overflow-y-auto">
      <slot />
    </div>

    <div
      class="flex-shrink-0 p-4 flex justify-between items-center gap-x-3 border-t border-gray-200"
      v-if="slots['buttons-left'] || slots['buttons-right']"
    >
      <div class="flex justify-start items-center gap-x-3 justify-self-start">
        <slot name="buttons-left" />
      </div>

      <div class="flex justify-end items-center gap-x-3 justify-self-end">
        <slot name="buttons-right" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from "vue";

const slots = useSlots();
</script>
