<template>
  <div class="flex justify-start select-none">
    <div
      v-for="(label, id) of tabs"
      :key="id"
      class="p-4 border-b-2 cursor-pointer hover:opacity-80 transition-all"
      :class="{
        'border-purple-500': id === modelValue,
      }"
      @click="modelValue = id"
      role="button"
    >
      {{ label }}
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  tabs: Record<string, string>;
}>();

const modelValue = defineModel<string>();
</script>
