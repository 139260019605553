<template>
  <div>
    <div class="mb-2">
      <FormControl>
        <template #label>Billing Period</template>
        <CustomSelect
          :options="billingPeriods"
          :hide-blank="true"
          v-model="selectedBillingPeriodId"
        >
          <template #placeholder
            ><div class="text-gray-400 text-sm">
              Custom date range
            </div></template
          >
          <template #option="{ option: billingPeriod }">
            <div class="items-center gap-4">
              <div>
                {{ niceDate(billingPeriod.startAt) }} -
                {{ niceDate(billingPeriod.endAt) }}
              </div>
            </div>
          </template>
        </CustomSelect>
      </FormControl>
    </div>

    <div class="flex gap-x-4">
      <FormControl>
        <template #label>Start Date</template>
        <input
          class="input"
          type="datetime-local"
          v-model="startDate"
          @change="handleRangeChange"
        />
      </FormControl>

      <FormControl>
        <template #label>End Date</template>
        <input
          class="input"
          type="datetime-local"
          v-model="endDate"
          @change="handleRangeChange"
        />
      </FormControl>

      <FormControl>
        <template #label>Interval</template>
        <select
          class="input"
          v-model="dateInterval"
          @change="handleRangeChange"
        >
          <option value="month">Month</option>
          <option value="day">Day</option>
        </select>
      </FormControl>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from "vue";
import { formatDateForInput, niceDate } from "../../../utility/time";
import CustomSelect from "../../global/CustomSelect.vue";
import FormControl from "../../global/FormControl.vue";

const props = defineProps<{
  billingPeriods: types.BillingPeriod[];
}>();

const startDate = defineModel<string>("startDate");
const endDate = defineModel<string>("endDate");
const dateInterval = defineModel<"month" | "day">("dateInterval");
const selectedBillingPeriodId = defineModel<number | null>(
  "selectedBillingPeriodId"
);

watch(selectedBillingPeriodId, (id) => {
  if (id) {
    const billingPeriod = props.billingPeriods.find((bp) => bp.id === id);
    if (billingPeriod) {
      startDate.value = formatDateForInput(billingPeriod.startAt);
      endDate.value = formatDateForInput(billingPeriod.endAt);
      dateInterval.value = "month";
    }
  }
});

const handleRangeChange = () => {
  selectedBillingPeriodId.value = null;
};
</script>
