<template>
  <div class="text-sm" v-if="show">
    <span class="text-green-600" v-if="status === 'success'">✓ success</span>
    <span class="text-red-700" v-else-if="status === 'error'">✖ failed</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  status: types.SaveStatus;
}>();

const show = computed(() => {
  return props.status === "success" || props.status === "error";
});
</script>
