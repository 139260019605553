<template>
  <span class="text-white/95 font-mono leading-none rounded" :class="classes"
    ><slot
  /></span>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  color: "purple" | "red" | "grey" | "yellow" | "orange";
  size: "sm" | "md" | "lg";
}>();

const classes = computed(() => {
  return {
    "text-xs px-1 pb-0.5 pt-[3px]": props.size === "sm",
    "text-sm px-1.5 pb-0.5 pt-[3px]": props.size === "md",
    "text-base px-2 pb-1 pt-[6px]": props.size === "lg",
    "bg-purple-500": props.color === "purple",
    "bg-red-700": props.color === "red",
    "bg-gray-700": props.color === "grey",
    "bg-yellow-700": props.color === "yellow",
    "bg-orange-700": props.color === "orange",
    [`bg-${props.color}`]: true,
  };
});
</script>
