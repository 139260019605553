<template>
  <div class="p-4">
    <h4
      v-if="slots.title"
      class="text-xs uppercase font-bold tracking-wide text-gray-500 mb-2"
    >
      <slot name="title" />
    </h4>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { useSlots } from "vue";

defineProps<{
  title?: string;
}>();

const slots = useSlots();
</script>
