<template>
  <Filter
    :active="active"
    @open="active = true"
    @close="active = false"
    data-name="SortField"
  >
    <template #button>
      <div :class="{ 'text-gray-400': sort.field }">Sort</div>
      <div>{{ selectedLabel }}</div>
      <ArrowsUpDownIcon class="w-5 h-5 text-gray-400" v-if="!sort.field" />
    </template>
    <template #panel>
      <div class="space-y-1 px-2 py-2" data-name="SortField->Results">
        <div
          v-for="(label, field) of sort.availableFields"
          class="px-3 py-2 cursor-pointer hover:bg-purple-100/50 rounded-xl flex justify-between gap-x-4 items-center font-bold"
          :class="{ 'bg-purple-100/50': sort.field === field }"
          @click="select(field as keyof T)"
        >
          {{ label }}
        </div>
      </div>
    </template>
  </Filter>
</template>

<script setup lang="ts" generic="T extends Record<string, any>">
import { computed, ref } from "vue";
import Filter from "./Filter.vue";
import { ArrowsUpDownIcon } from "@heroicons/vue/24/solid";

const props = defineProps<{
  sort: types.Sort<T>;
}>();

const active = ref(false);

const select = (value: keyof T) => {
  if (props.sort.field === value) {
    props.sort.field = null;
    active.value = false;
  } else {
    props.sort.field = value;
    if (!props.sort.direction) props.sort.direction = "desc";
    active.value = false;
  }
};

const selectedLabel = computed(() => {
  if (!props.sort.field) return "";
  return props.sort.availableFields[props.sort.field];
});
</script>
