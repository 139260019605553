<template>
  <div>
    <label :for="id" :class="classes" class="select-none">
      <Loader v-if="busy" :shade="loaderShade" class="px-2" />
      <span :class="textClasses"> <slot /> </span
    ></label>
    <input
      :id="id"
      class="hidden"
      type="file"
      @change="emit('change', ($event.target as HTMLInputElement).files)"
      :disabled="disabled || busy"
      multiple
      :accept="accept || '*'"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Loader from "./Loader.vue";
import useBtn, { BtnFill } from "./useBtn";
const emit = defineEmits<{
  (e: "change", files: FileList | null): void;
}>();

const props = defineProps<{
  color?: types.Color;
  fill?: BtnFill;
  size?: types.Size;
  disabled?: boolean;
  busy?: boolean;
  accept?: string;
}>();

const { classes, textClasses, loaderShade } = useBtn(props);

const id = computed(() => {
  return `files-${crypto.randomUUID()}`;
});
</script>
