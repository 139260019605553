<template>
  <AdminTable>
    <template #head>
      <TableHeader
        class="py-1 px-0 text-center cursor-pointer hover:opacity-75 select-none"
        id="bulk-selection"
        @click="emit('bulkselect')"
      >
        <div
          class="relative w-5 h-5 ml-auto mr-auto border-gray-500 border-2 rounded px-px before:h-[2px] before:bg-gray-500 before:absolute before:left-0 before:right-0 before:top-[7px] before:mx-[3px]"
          v-if="multiSelectedUserIds.length > 0"
        />
        <div
          v-else
          class="relative w-5 h-5 ml-auto mr-auto border-gray-500 border-2 rounded px-px flex justify-center items-center"
        >
          <PlusSmallIcon class="w-5 h-5" />
        </div>
      </TableHeader>
      <TableHeader>Email</TableHeader>
      <TableHeader>Name</TableHeader>
      <TableHeader>Monthly Process Limit</TableHeader>
      <TableHeader
        >Processed Within Period (Total
        {{ niceNumber(totalWithinPeriod) }})</TableHeader
      >
      <TableHeader>Force Lock People Setting</TableHeader>
      <TableHeader>Last Active</TableHeader>
      <TableHeader>Created At</TableHeader>
    </template>

    <template #body>
      <UserRow
        v-for="user in users"
        :key="user.id"
        :user="user"
        :selected="selectedUserId === user.id"
        :multi-selected="multiSelectedUserIds.includes(user.id)"
        @select="emit('select', user.id)"
        @multiselect="emit('multiselect', user.id, $event)"
      />
    </template>
  </AdminTable>
</template>

<script setup lang="ts">
import { PlusSmallIcon } from "@heroicons/vue/24/solid";
import AdminTable from "../../global/AdminTable.vue";
import TableHeader from "../../global/AdminTable/TableHeader.vue";
import UserRow from "./UserRow.vue";
import { computed } from "vue";
import { niceNumber } from "../../../utility/helpers";

const props = defineProps<{
  users: types.AgencyUser[];
  selectedUserId?: number;
  multiSelectedUserIds: number[];
}>();

const emit = defineEmits<{
  (event: "select", userId: number | null): void;
  (event: "multiselect", userId: number, selected: boolean): void;
  (event: "bulkselect"): void;
}>();

const totalWithinPeriod = computed(() => {
  return props.users.reduce((acc, user) => acc + user.processCount, 0);
});
</script>
