<template>
  <div
    ref="el"
    class="shadow-xl shadow-black/30 rounded-lg absolute bg-white z-30 divide-y divide-gray-200 border border-gray-200"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";

const emit = defineEmits<{
  (event: "close"): void;
}>();

const el = ref(null);
onClickOutside(el, (event) => {
  emit("close");
});
</script>
