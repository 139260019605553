<template>
  <div>
    <div class="mb-3">
      <label for="user_password">Password</label>
      <input
        class="input"
        type="password"
        id="user_password"
        name="user[password]"
        v-model="password"
      />
      <div class="rounded bg-gray-100 p-2 mt-1 text-sm border border-gray-200">
        <Requirement :passing="lengthOk"
          >At least {{ minLength }} characters long</Requirement
        >
        <Requirement v-if="confirmationRequired" :passing="matchingOk"
          >Matches</Requirement
        >
        <template v-if="complex">
          <Requirement :passing="lowerCaseOk"
            >Contains at least 1 lower case letter</Requirement
          >
          <Requirement :passing="upperCaseOk"
            >Contains at least 1 upper case letter</Requirement
          >
          <Requirement :passing="specialCharOk"
            >Contains at least 1 special character</Requirement
          >
        </template>
      </div>
    </div>

    <div class="mb-3" v-if="confirmationRequired">
      <label for="password">Password Confirmation</label>
      <input
        class="input"
        type="password"
        id="user_password_confirmation"
        name="user[password_confirmation]"
        v-model="confirmation"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import Requirement from "./PasswordInputs/Requirement.vue";

const props = withDefaults(
  defineProps<{
    minLength: number;
    complex?: boolean;
    passwordErrors?: string;
    confirmationErrors?: string;
    confirmationRequired?: boolean;
  }>(),
  {
    complex: false,
    confirmationRequired: true,
  }
);

const password = ref("");
const confirmation = ref("");

const lengthOk = computed(() => password.value.length >= props.minLength);
const matchingOk = computed(() => confirmation.value === password.value);
const lowerCaseOk = computed(
  () => props.complex && /[a-z]/.test(password.value)
);
const upperCaseOk = computed(
  () => props.complex && /[A-Z]/.test(password.value)
);
const specialCharOk = computed(
  () => props.complex && /[^a-zA-Z0-9]/.test(password.value)
);
</script>
