<template>
  <div
    class="group flex flex-col overflow-hidden border rounded-md border-gray-200 bg-white shadow-md border-b-0 select-none transition-all"
    :class="{
      'cursor-pointer hover:scale-105 hover:shadow-xl': saveStatus !== 'saving',
      'animate-pulse': saveStatus === 'saving',
    }"
    @click="toggleActive"
    data-name="Device"
  >
    <div
      class="px-2 py-px pt-1 text-sm font-mono flex-grow-0 flex justify-between items-center transition-all duration-500"
      :class="{
        'bg-purple-700 text-purple-100': device.active,
        'bg-white text-gray-500 border-b border-gray-200': !device.active,
      }"
    >
      <div>#{{ device.displayId }}</div>

      <template v-if="saveStatus === 'saving'">
        <span>Saving<AnimatedEllipsis /></span>
      </template>

      <template v-else>
        <div class="group-hover:hidden" data-name="active-status">
          {{ device.active ? "Active" : "Inactive" }}
        </div>

        <div class="group-hover:block hidden">
          {{ device.active ? "Click to deactivate" : "Click to activate" }}
        </div>
      </template>
    </div>
    <div class="px-3 py-3.5 flex-grow flex justify-start gap-3 items-center">
      <i class="fa fa-2x fa-apple" v-if="deviceMac"></i>
      <i class="fa fa-2x fa-windows" v-else-if="deviceWindows"></i>
      <i class="fa fa-2x fa-linux" v-else-if="deviceLinux"></i>
      <div>
        <div class="text-lg leading-tight">
          {{ device.os }}
        </div>
        <div class="text-sm text-gray-400">
          <template v-if="device.lastUsedAt">
            Last used {{ timeAgo(device.lastUsedAt) }}
          </template>
          <template v-else> Never used </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { timeAgo } from "../../utility/time";
import useModelApi from "../global/useModelApi";
import AnimatedEllipsis from "../global/AnimatedEllipsis.vue";

const props = defineProps<{
  device: types.Device;
  maxActivations: number;
}>();

const emit = defineEmits<{
  (e: "update:device", device: types.Device): void;
  (e: "update:activationsCount", activationsCount: number): void;
}>();

const { api, errors, saveStatus } = useModelApi<types.Device>();

const toggleActive = async () => {
  if (saveStatus.value === "saving") return;
  if (
    !props.device.active &&
    !confirm(
      `Are you sure you want to activate this device? This will use one of your ${props.maxActivations} device activations for the month.`
    )
  )
    return;

  if (
    props.device.active &&
    !confirm(
      `Are you sure you want to deactivate this device? Reactivating it will use one of your ${props.maxActivations} device activations for the month.`
    )
  )
    return;

  const data = await api({
    method: "put",
    url: `/dashboard/api/devices/${props.device.id}`,
    data: {
      device: {
        active: !props.device.active,
      },
    },
  });

  if (data && saveStatus.value === "success") {
    emit("update:device", data.device);
    emit("update:activationsCount", data.activationsCount);
  } else {
    Object.values(errors.value!);
    let message = `Failed to ${
      props.device.active ? "deactivate" : "activate"
    } device`;
    const errorMessage = Object.values(errors.value!).join("\n");
    if (errorMessage) {
      message += `:\n\n${errorMessage}`;
    }
    alert(message);
  }
};

const deviceMac = computed(() => {
  if (!props.device.os) return null;
  return props.device.os.toLowerCase().includes("mac");
});

const deviceWindows = computed(() => {
  if (!props.device.os) return null;
  return props.device.os.toLowerCase().includes("windows");
});

const deviceLinux = computed(() => {
  if (!props.device.os) return null;
  return props.device.os.toLowerCase().includes("ubuntu");
});
</script>
