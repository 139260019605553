<template>
  <div
    class="flex justify-between gap-x-5 gap-y-2 flex-wrap"
    data-name="Filters"
  >
    <div class="flex justify-start gap-x-2 flex-shrink-0">
      <SortFilters :sort="sort" />
      <div>
        <select v-model="search.status" class="input w-auto">
          <option :value="null">All Statuses</option>
          <option v-for="(label, value) of statuses" :value="value">
            {{ label }}
          </option>
        </select>
      </div>

      <div>
        <select v-model="search.exceedingLimits" class="input w-auto">
          <option :value="false">All Records</option>
          <option :value="true">AI processes limit exceeded</option>
        </select>
      </div>
    </div>

    <SearchBox
      @update:query="search.query = $event"
      :searching="searchActive"
      :initial-query="search.query"
      class="xl:max-w-[50%]"
    />
  </div>
</template>

<script setup lang="ts">
import SearchBox from "../../global/SearchBox.vue";
import SortFilters from "../../global/SortFilters.vue";

defineProps<{
  sort: types.Sort<types.User>;
  search: types.UserSearch;
  searchActive: boolean;
}>();

const statuses = {
  prospect: "Prospect",
  active: "Active",
  free_trial: "Free Trial",
  no_longer_active: "No Longer Active",
  agency_disabled: "Agency Disabled",
  invoice_unpaid: "Invoice Unpaid",
};
</script>
