<template>
  <div class="mb-2 form-control" :data-has-errors="errors" ref="el">
    <label class="text-sm text-gray-500" v-if="slots.label" :for="uuid"
      ><slot name="label" />
      <span class="text-gray-500 italic font-bold ml-1" v-if="required"
        >*</span
      ></label
    >
    <slot />

    <div v-if="errors" class="text-xs text-red-700 mt-px">
      {{ errors.join(", ") }}
    </div>

    <div v-if="slots.helper" class="text-xs text-gray-500 mt-px">
      <slot name="helper" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, useSlots } from "vue";

defineProps<{
  errors?: string[];
  required?: boolean;
}>();

const slots = useSlots();
const uuid = crypto.randomUUID();
const el = ref<HTMLElement | null>(null);

// Ensures that the form inputs have unique IDs matched to the label, for testing and making it easier to select the input
onMounted(() => {
  el.value
    ?.querySelectorAll("input, select, textarea")
    ?.forEach((formInput) => {
      formInput.id = uuid;
    });
});
</script>

<style>
.form-control[data-has-errors] {
  .input {
    @apply !border-red-500;
  }
}
</style>
