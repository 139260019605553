<template>
  <AdminLayout>
    <div class="flex flex-col">
      <div class="mb-4">
        <Filters
          :sort="modelSort"
          :search="search"
          :search-active="fetchStatus === 'fetching'"
        />
      </div>

      <FetchLoader :fetch-status="fetchStatus">
        <AdminTable
          :pagination="pagination"
          :max-per-page="250"
          @paginate="fetchUsers"
        >
          <template #head>
            <TableHeader>Email</TableHeader>
            <TableHeader>Agency</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>AI Processes</TableHeader>
            <TableHeader>Affiliate</TableHeader>
            <TableHeader>Last Used Version</TableHeader>
            <TableHeader>Last Active</TableHeader>
          </template>

          <template #body>
            <UserRow
              v-for="user in users"
              :key="user.id"
              :user="user"
              :selected="selectedUserId === user.id"
              :intercom-app-id="intercomAppId"
              @select="selectedUserId = user.id"
            />
          </template>
        </AdminTable>
      </FetchLoader>
    </div>

    <template #sidebar>
      <SelectedUser
        :user="selectedUser"
        v-if="selectedUser"
        :intercom-app-id="intercomAppId"
        :current-super-admin="currentSuperAdmin"
        @update="updateModel"
        @delete="deleteModel"
      />
    </template>
  </AdminLayout>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue";
import UserRow from "./Users/UserRow.vue";
import Filters from "./Users/Filters.vue";
import SelectedUser from "./Users/SelectedUser.vue";
import AdminLayout from "../global/AdminLayout.vue";
import AdminTable from "../global/AdminTable.vue";
import TableHeader from "../global/AdminTable/TableHeader.vue";
import useModelList from "../global/useModelList";
import { snakeCase } from "lodash";
import usePagination from "../global/usePagination";
import { useUrlSearchParams } from "@vueuse/core";
import useFetchApi from "../global/useFetchApi";
import FetchLoader from "../global/FetchLoader.vue";

defineProps<{
  intercomAppId: string;
  currentSuperAdmin: schema.User;
}>();

const {
  models: users,
  deleteModel,
  updateModel,
  modelSort,
} = useModelList<types.User>({
  sortFields: {
    email: "Email",
    createdAt: "Created",
    status: "Status",
    lastActiveAt: "Last Active",
    lastUsedVersion: "Last Used Version",
  },
});

modelSort.value.field = "lastActiveAt";
modelSort.value.direction = "desc";

const params = useUrlSearchParams();
const selectedUserId = ref<number | null>(null);

const search = reactive<types.UserSearch>({
  query: (params.userEmail as string) ?? "",
  status: null,
  exceedingLimits: false,
});

watch(
  [modelSort, search],
  () => {
    pagination.value.currentPage = 1;
    fetchUsers();
  },
  { deep: true }
);

const { fetchStatus, fetchApi } = useFetchApi();

const fetchUsers = async () => {
  const data = await fetchApi({
    url: "dashboard/super_admin/api/users.json",
    params: {
      ...search,
      page: pagination.value.currentPage,
      per: pagination.value.perPage,
      sortDirection: modelSort.value.direction,
      sortField: modelSort.value.field
        ? snakeCase(modelSort.value.field)
        : null,
    },
  });

  if (data) {
    users.value = data.users;
    pagination.value = data.pagination;
  }
};

onMounted(async () => {
  await fetchUsers();
  if (params.userEmail) {
    selectedUserId.value =
      users.value.find((user) => user.email === params.userEmail)?.id ?? null;
  }
});

const selectedUser = computed(() => {
  return users.value.find((user) => user.id === selectedUserId.value);
});

const pagination = usePagination("SuperAdmin.Users");
</script>
