import { S3Client, S3ClientConfig } from "@aws-sdk/client-s3";
import { Credentials, HttpRequest } from "@aws-sdk/types";
import { SignatureV4 } from "@smithy/signature-v4";
import { Sha256 } from "@aws-crypto/sha256-browser";

const REGION = "eu-west-1";

export default function (railsCredentials: Credentials, endpoint?: string) {
  const credentials = {
    accessKeyId: railsCredentials.accessKeyId,
    secretAccessKey: railsCredentials.secretAccessKey,
    sessionToken: railsCredentials.sessionToken,
  };

  const config = {
    region: REGION,
    credentials,
  } as S3ClientConfig;

  // If endpoint is provided, its for minio rather than s3. act accordingly.
  if (endpoint) {
    config.endpoint = endpoint;
    config.forcePathStyle = true;
    // Fixes the issue with the S3 client not signing the request correctly for localhost minio due to ignoring port
    // https://github.com/aws/aws-sdk-js-v3/issues/3858
    config.signer = async () => ({
      sign: async (request: HttpRequest) => {
        if (request.port) {
          request.headers["host"] = `${request.hostname}:${request.port}`;
        }

        const signatureV4 = new SignatureV4({
          credentials,
          region: REGION,
          service: "s3",
          sha256: Sha256,
        });

        const authorizatedRequest = await signatureV4.sign(request);

        return authorizatedRequest;
      },
    });
  }

  return new S3Client(config);
}
