<template>
  <Panel @close="emit('close')" data-name="GroupPanel">
    <div class="px-4 py-3 font-bold flex justify-between items-center">
      <div>{{ group.id ? "Update" : "Create" }} Group</div>

      <Btn color="secondary" size="small" fill="none" @click="emit('close')"
        ><XMarkIcon class="w-5 h-5"
      /></Btn>
    </div>
    <div class="px-4 py-3">
      <FormControl>
        <template #label for="group-name">Name</template>
        <input
          v-model="groupForm.name"
          class="input"
          id="group-name"
          placeholder="e.g My Town United"
        />
      </FormControl>

      <FormControl>
        <template #label for="group-user-label"
          >User label
          <small>(not displayed in metadata formulas)</small></template
        >
        <input
          v-model="groupForm.userLabel"
          class="input"
          id="group-user-label"
          placeholder='e.g "Men&apos;s Team" or "Under 21s"'
        />
      </FormControl>
    </div>
    <div class="px-4 py-3 justify-between flex flex-row-reverse">
      <Btn @click="save" color="primary" :busy="saving" size="small">Save</Btn>
      <Btn
        v-if="group.id"
        color="secondary"
        size="small"
        fill="underline"
        @click="destroy"
        :busy="deleting"
        >Delete</Btn
      >
    </div>
  </Panel>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import Panel from "../../global/Panel.vue";
import type { AxiosInstance } from "axios";
import Btn from "../../global/Btn.vue";
import { XMarkIcon } from "@heroicons/vue/24/solid";
import FormControl from "../../global/FormControl.vue";

const props = defineProps<{
  group: Partial<types.Group>;
  axios: AxiosInstance;
}>();

const emit = defineEmits<{
  (event: "save", group: types.Group): void;
  (event: "close"): void;
  (event: "delete"): void;
}>();

const groupForm = ref<Partial<types.Group>>({});

onMounted(() => {
  groupForm.value.name = props.group.name;
  groupForm.value.userLabel = props.group.userLabel;
});

const saving = ref(false);

const url = computed(() => {
  return props.group.id
    ? `api/groups/${props.group.id}.json`
    : "api/groups.json";
});

const method = computed(() => {
  return props.group.id ? "put" : "post";
});

const save = async () => {
  saving.value = true;
  try {
    const result = await props.axios({
      method: method.value,
      url: url.value,
      data: {
        group: groupForm.value,
      },
    });

    emit("save", result.data.group);
  } catch (e: any) {
    if (e.response?.status === 422 && e.response.data?.errors) {
      alert(e.response.data.errors.join(", "));
    } else {
      console.error(e);
      alert(
        "Error saving group, please check your internet connection and try again."
      );
    }
  } finally {
    saving.value = false;
  }
};

const deleting = ref(false);

const destroy = async () => {
  if (props.group.peopleCount && props.group.peopleCount > 0) {
    const confirmed = window.confirm(
      `Are you sure you want to delete ${props.group.name}? It currently contains ${props.group.peopleCount} people.`
    );
    if (!confirmed) return;
  }

  deleting.value = true;

  try {
    await props.axios({
      method: "delete",
      url: `api/groups/${props.group.id}.json`,
    });

    emit("delete");
  } catch (e) {
    console.error(e);
    window.alert(
      "Failed to delete group, please check your internet connection and try again"
    );
  } finally {
    deleting.value = false;
  }
};
</script>
