import { Component } from "vue";
import mountApp from "../utility/mountApp";

// Allow importing all vue components in the components folder, or any component in a subfolder of components only 1 deep though, and excluding the global folder as these are not vue apps, just helper components.
const modules = import.meta.glob(
  [
    "../components/*.vue",
    "../components/*/*.vue",
    "!../components/global/*.vue",
  ],
  { eager: true }
);

let mounted = false;

window.addEventListener("DOMContentLoaded", () => {
  mountApps();
});

mountApps();

async function mountApps() {
  if (mounted) return;
  mounted = true;
  for (const element of Array.from(
    document.querySelectorAll(".vue-app-mount")
  )) {
    const componentName = element.id;
    const module = modules[`../components/${componentName}.vue`] as null | {
      default: Component;
    };
    if (!module || !module.default) {
      console.error("No vue app found for", componentName);
      continue;
    }
    await mountApp(componentName, module.default);
  }
}
