import {
  IndexFacesCommand,
  IndexFacesRequest,
  IndexFacesResponse,
  RekognitionClient,
  SearchFacesCommand,
  SearchFacesRequest,
  SearchFacesResponse,
} from "@aws-sdk/client-rekognition";
import { Credentials } from "@aws-sdk/types";

const REGION = "eu-west-1";

export default class RekognitionInterface {
  #client!: RekognitionClient;

  constructor(credentials: Credentials) {
    this.#client = new RekognitionClient({
      region: REGION,
      credentials,
    });
  }

  async indexFaces(data: IndexFacesRequest): Promise<IndexFacesResponse> {
    const command = new IndexFacesCommand(data);
    return this.#client.send(command);
  }

  async searchFaces(data: SearchFacesRequest): Promise<SearchFacesResponse> {
    const command = new SearchFacesCommand(data);
    return this.#client.send(command);
  }
}
