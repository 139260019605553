<template>
  <AdminLayout>
    <div class="space-y-6 max-w-3xl ml-auto mr-auto mt-6 px-4">
      <div>
        <div class="font-bold text-2xl">Device Management</div>
        <div class="text-gray-500">
          <div>
            <b>{{ activeDevicesCount }}/{{ maxDevices }}</b> active devices
          </div>
          <div
            :class="{
              'text-red-700': remainingActivations <= 0,
            }"
          >
            <b>{{ remainingActivations }}</b> device
            {{ remainingActivations === 1 ? "activation" : "activations" }}
            remaining for
            {{ currentMonth }}
          </div>
        </div>
      </div>

      <div class="grid gap-6 md:grid-cols-2">
        <Device
          v-for="device of devices"
          @update:device="updateModel"
          @update:activationsCount="activationsCount = $event"
          :key="device.id"
          :device="device"
          :max-activations="maxActivations"
        />
      </div>
    </div>
  </AdminLayout>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import Device from "./Devices/Device.vue";
import useModelList from "./global/useModelList";

const props = defineProps<{
  devices: types.Device[];
  maxDevices: number;
  maxActivations: number;
  activationsCount: number;
}>();

const activationsCount = ref(props.activationsCount);

const { models: devices, updateModel } = useModelList<types.Device>({
  initialModels: props.devices,
});

const currentMonth = computed(() => {
  return new Date().toLocaleString("default", { month: "long" });
});

const remainingActivations = computed(() => {
  return Math.max(props.maxActivations - activationsCount.value, 0);
});

const activeDevicesCount = computed(() => {
  return devices.value.filter((device) => device.active).length;
});
</script>
