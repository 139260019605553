import { ref, watch } from "vue";

export default function usePagination(
  storageKey: string,
  defaultPerPage: number = 25
) {
  const key = `${storageKey}.perPage`;
  const pagination = ref<types.Pagination>({
    currentPage: 1,
    perPage: Number(localStorage.getItem(key)) || defaultPerPage,
  });

  watch(pagination, () => {
    localStorage.setItem(key, pagination.value.perPage.toString());
  });

  return pagination;
}
