<template>
  <div>
    <h5 class="font-bold text-lg mb-2">{{ label }}</h5>
    <div
      class="rounded bg-gray-50 p-2 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
    >
      <LineChart
        v-if="chartData"
        id="b"
        :options="chartOptions"
        :data="(chartData as unknown as any)"
        class="h-80"
      />
      <Loader v-if="loading" shade="dark" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Line as LineChart } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Filler,
} from "chart.js";
import Loader from "../../global/Loader.vue";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Filler
);

const props = defineProps<{
  label: string;
  chartData?: Record<string, any>;
  loading: boolean;
  legend?: boolean;
}>();

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: props.legend,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
</script>
