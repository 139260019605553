<template>
  <span>
    <template v-for="i of MAX_DOTS">
      <span :class="{ invisible: i > currentDots }">.</span>
    </template>
  </span>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";

const MAX_DOTS = 3;
const INTERVAL_MS = 500;

const intervalId = ref<number | null>(null);
const currentDots = ref(0);

onMounted(() => {
  intervalId.value = setInterval(() => {
    if (currentDots.value >= MAX_DOTS) {
      currentDots.value = 0;
    } else {
      currentDots.value++;
    }
  }, INTERVAL_MS);
});

onUnmounted(() => {
  if (intervalId.value) clearInterval(intervalId.value);
});
</script>
