<template>
  <label
    class="flex items-center gap-2 cursor-pointer text-lg select-none leading-snug mt-1"
  >
    <input
      v-model="modelValue"
      class="w-4 h-4 accent-purple-500 cursor-pointer"
      type="checkbox"
      v-bind="$attrs"
    />
    <div v-if="slots.label || slots.helper">
      <div>
        <slot name="label" />
      </div>

      <div v-if="slots.helper" class="text-sm text-gray-500 leading-4">
        <slot name="helper" />
      </div>
    </div>
  </label>
</template>

<script setup lang="ts">
import { useSlots } from "vue";

const modelValue = defineModel();

defineOptions({
  inheritAttrs: false,
});

const slots = useSlots();
</script>
