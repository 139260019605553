import { cloneDeep } from "lodash";
import { Ref, ref, toRaw } from "vue";

export default function useModelList<T extends types.Model>(
  {
    initialModels,
    sortFields,
    initialSort,
    defaultModel,
  }: {
    initialModels?: T[];
    sortFields?: Partial<Record<keyof T, string>>;
    initialSort?: any;
    defaultModel?: Partial<T> | Function;
  } = {
    initialModels: [],
    sortFields: {},
    initialSort: {
      field: null,
      direction: null,
    },
    defaultModel: {},
  }
) {
  const models = ref(initialModels ?? []) as Ref<T[]>;
  const modelForm = ref(null) as Ref<types.Form<T> | null>;
  const multiSelectedModelIds = ref<number[]>([]);
  const modelSort = ref<types.Sort<T>>({
    ...initialSort,
    availableFields: sortFields,
  });

  const initModelForm = () => {
    const model =
      defaultModel instanceof Function ? defaultModel() : defaultModel ?? {};
    modelForm.value = cloneDeep(model);
  };

  const createModel = (model: T) => {
    models.value.unshift(model);
  };

  const updateModel = (model: T) => {
    const index = models.value.findIndex((u) => u.id === model.id);
    if (index !== -1) {
      models.value[index] = { ...models.value[index], ...model };
    }
  };

  const saveModel = (model: T) => {
    if (models.value.find((u) => u.id === model.id)) {
      updateModel(model);
    } else {
      createModel(model);
    }
  };

  const deleteModel = (modelId: number) => {
    const index = models.value.findIndex((u) => u.id === modelId);
    if (index !== -1) {
      models.value.splice(index, 1);
    }
  };

  const createModels = (newModels: T[]) => {
    models.value = [...newModels, ...models.value];
  };

  const updateModels = (newModels: T[]) => {
    for (const model of newModels) {
      const index = models.value.findIndex((m) => m.id === model.id);
      if (index >= 0) {
        models.value[index] = model;
      }
    }
  };

  const deleteModels = (modelIds: number[]) => {
    for (const modelId of modelIds) {
      models.value = models.value.filter((p) => p.id !== modelId);
    }
  };

  const selectModel = (id?: number | null) => {
    multiSelectedModelIds.value = [];
    if (id) {
      const model = models.value.find((model) => model.id === id);
      modelForm.value = structuredClone(toRaw(model)) ?? null;
    } else {
      modelForm.value = null;
    }
  };

  const multiSelectModel = (modelId: number, selected: boolean) => {
    if (selected) {
      modelForm.value = null;
      multiSelectedModelIds.value = [...multiSelectedModelIds.value, modelId];
    } else {
      multiSelectedModelIds.value = multiSelectedModelIds.value.filter(
        (id) => id !== modelId
      );
    }
  };

  const clearMultiSelectedModels = () => {
    multiSelectedModelIds.value = [];
  };

  const selectAllModels = () => {
    multiSelectedModelIds.value = models.value.map((model) => model.id);
  };

  const toggleBulkSelectModel = () => {
    modelForm.value = null;
    if (multiSelectedModelIds.value.length > 0) {
      clearMultiSelectedModels();
    } else {
      selectAllModels();
    }
  };

  return {
    models,
    modelForm,
    initModelForm,
    createModel,
    updateModel,
    saveModel,
    deleteModel,
    createModels,
    updateModels,
    deleteModels,
    selectModel,
    multiSelectedModelIds,
    multiSelectModel,
    clearMultiSelectedModels,
    selectAllModels,
    toggleBulkSelectModel,
    modelSort,
  };
}
