import { ref } from "vue";
import createAxiosClient from "../../utility/axios_client";
import { AxiosInstance } from "axios";

export default function useFetchApi() {
  const fetchStatus = ref<types.FetchStatus>("idle");

  const fetchApi = async (config: {
    axios?: AxiosInstance;
    url: string;
    params?: any;
    method?: "get" | "post" | "put" | "delete";
  }): Promise<false | any> => {
    fetchStatus.value = "fetching";
    const axios = config.axios ?? createAxiosClient();

    try {
      const response = await axios(config);
      fetchStatus.value = "idle";
      return response.data;
    } catch (e: any) {
      console.error(e);
      fetchStatus.value = "error";
    }
    return false;
  };

  return {
    fetchStatus,
    fetchApi,
  };
}
