<template>
  <SidePanel id="new-person">
    <PanelSection>
      <label class="text-gray-500 text-sm mb-1 block">
        No person record selected, start typing a name to create one.
      </label>

      <div>
        <SearchBox
          @update:query="query = $event"
          :searching="searching"
          placeholder="Enter a name"
          ref="searchBox"
        />
        <Btn
          class="mt-2 w-full"
          size="small"
          color="primary"
          v-if="query"
          @click="emit('init', query)"
        >
          <i class="fa fa-plus-circle mr-0.5" /> Create new '{{ query }}'
        </Btn>
        <div
          v-if="query.length && !people.length"
          class="px-4 py-2 flex justify-center text-gray-400 text-sm mt-2"
        >
          No existing records found
        </div>
        <div
          class="shadow rounded divide-y divide-gray-200 overflow-hidden mt-2"
        >
          <PersonResult
            v-for="person of people"
            :person="person"
            :s3="s3"
            :s3-bucket="s3Bucket"
            :reference-face="referenceFaceFor(person)"
            :key="person.id"
            @click="emit('select', person, facesFor(person))"
          />
        </div>
      </div>
    </PanelSection>
  </SidePanel>
</template>

<script setup lang="ts">
import { S3Client } from "@aws-sdk/client-s3";
import { AxiosInstance } from "axios";
import { onMounted, ref, watch } from "vue";
import SearchBox from "../../global/SearchBox.vue";
import PersonResult from "./SearchPerson/PersonResult.vue";
import Btn from "../../global/Btn.vue";
import SidePanel from "../../global/SidePanel.vue";
import PanelSection from "../../global/SidePanel/PanelSection.vue";

const emit = defineEmits<{
  (event: "select", person: schema.Person, faces: schema.Face[]): void;
  (event: "init", name: string): void;
}>();

const props = defineProps<{
  axios: AxiosInstance;
  s3?: S3Client;
  s3Bucket: string;
}>();

const query = ref("");
const people = ref<schema.Person[]>([]);
const faces = ref<schema.Face[]>([]);
const searching = ref(false);
const searchBox = ref<typeof SearchBox>();

const fetchPeople = async () => {
  searching.value = true;
  try {
    const result = await props.axios({
      method: "get",
      url: "/api/people.json",
      params: {
        query: query.value,
        page: 1,
        per: 15,
      },
    });
    people.value = result.data.people;
    faces.value = result.data.faces;
  } finally {
    searching.value = false;
  }
};

watch(query, () => {
  if (!query.value) {
    people.value = [];
    faces.value = [];
  } else {
    fetchPeople();
  }
});

const referenceFaceFor = (person: schema.Person) => {
  return facesFor(person).find((f) => f.s3Name);
};

const facesFor = (person: schema.Person) => {
  return faces.value.filter((face) => face.personId === person.id);
};

onMounted(() => {
  if (searchBox.value) searchBox.value.$el.focus();
});
</script>
