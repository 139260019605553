import { format, parseISO } from "date-fns";
import { partition, sortBy } from "lodash";

export function currentTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function timeAgo(date: string | Date): string {
  const today = new Date().getTime();
  const created = new Date(date).getTime();
  const differenceMs = Math.max(today - created, 0); // Ensure its in the past
  const differenceDays = Math.floor(differenceMs / (1000 * 3600 * 24));

  const formatter = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
    style: "long",
  });

  if (differenceDays > 365) {
    return formatter.format(-Math.round(differenceDays / 365), "year");
  } else if (differenceDays === 0) {
    return "today";
  } else {
    return formatter.format(-differenceDays, "day");
  }
}

export function niceDate(date: string | Date, timeZone?: string): string {
  return new Date(date).toLocaleDateString("en-GB", {
    timeZone: timeZone ?? currentTimezone(),
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function niceDateTime(date: string | Date, timeZone?: string): string {
  return new Date(date).toLocaleDateString("en-GB", {
    timeZone: timeZone ?? currentTimezone(),
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

export function orderedByTimeField<T>(
  array: T[],
  field: keyof T,
  direction?: types.SortDirection | null
): T[] {
  if (!direction) direction = "desc";

  let [withTime, withoutTime] = partition(array, (item: T) => item[field]);
  withTime = sortBy(withTime, (item: T) => new Date(item[field] as string));

  if (direction === "desc") {
    withTime = withTime.reverse();
  }

  return [...withTime, ...withoutTime];
}

const pad = (num: number, size: number) => {
  let s = num + "";
  while (s.length < size) {
    s = "0" + s;
  }
  return s;
};

export function formatUTCDateForInput(date: Date) {
  const year = date.getUTCFullYear();
  const month = pad(date.getUTCMonth() + 1, 2); // Months are 0-based in JS
  const day = pad(date.getUTCDate(), 2);
  const hours = pad(date.getUTCHours(), 2);
  const minutes = pad(date.getUTCMinutes(), 2);

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function formatDateForInput(date: Date | string) {
  date = typeof date === "string" ? parseISO(date) : date;
  return format(date, "yyyy-MM-dd HH:mm");
}
