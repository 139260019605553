import { camelCase } from "lodash";
import { toRaw, isRef, isReactive } from "vue";

export function camelizeKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
}

export function dataUriToBlob(dataURI: string): Blob {
  var binary = atob(dataURI.split(",")[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
}

export function snakeToHuman(str: string) {
  const result = str.replace(/_/g, " ");
  return result.replace(/\b\w/g, (char) => char.toUpperCase());
}

export function niceNumber(number: number) {
  const formatter = new Intl.NumberFormat("en-UK");
  return formatter.format(number);
}

export function niceCurrency(pence: number) {
  const formatter = new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "GBP",
  });
  return formatter.format(pence / 100);
}

export function deepToRaw(obj: any) {
  // If the object is a ref, unwrap it
  if (isRef(obj)) {
    obj = obj.value;
  }

  // If the object is reactive, get its raw value
  if (isReactive(obj)) {
    obj = toRaw(obj);
  }

  // If the object is an array or a plain object, clone it deeply

  if (obj instanceof Blob || obj instanceof File || obj instanceof Date) {
    return obj;
  }

  if (Array.isArray(obj) || (obj !== null && typeof obj === "object")) {
    const clonedObj = Array.isArray(obj)
      ? ([] as any[])
      : ({} as Record<string, any>);
    for (const key in obj) {
      if (typeof obj[key] === "function") {
        continue;
      }

      clonedObj[key] = deepToRaw(obj[key]);
    }
    return clonedObj;
  }

  // Return the value for other data types (numbers, strings, etc.)
  return obj;
}
