<template>
  <div class="flex h-screen">
    <div class="py-6 flex-grow h-full overflow-y-auto nice-scroll shadow-inner">
      <div
        class="mx-auto px-4 sm:px-6 md:px-8"
        :class="{ 'max-w-7xl': !fullWidth }"
      >
        <slot />
      </div>
    </div>

    <div
      v-if="slots.sidebar"
      class="bg-white flex-shrink-0 h-full shadow-lg z-10"
      :style="{ width: `${sidebarWidth}px` }"
      id="sidebar"
    >
      <slot name="sidebar" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from "vue";

const props = defineProps<{
  sidebarWidth?: number;
  fullWidth?: boolean;
}>();

const slots = useSlots();

const sidebarWidth = computed(() => {
  return props.sidebarWidth ?? 500;
});
</script>
