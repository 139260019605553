import { FaceRecord } from "@aws-sdk/client-rekognition";
import {
  DeleteObjectCommand,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import { AxiosInstance } from "axios";
import reduceSize from "image-blob-reduce";
import { createWorker } from "./concurrency";
import RekognitionInterface from "./rekognitionInterface";
import FaceEngineInterface from "./faceEngineInterface";

const PROJECT_ID: string = "admin-dashboard";

export async function processImage(
  file: File | Blob,
  userId: number,
  s3Bucket: string,
  s3: S3Client,
  rekognitionCollectionId: string,
  rekognition: RekognitionInterface | FaceEngineInterface,
  axios: AxiosInstance,
  railsCollectionId: number
): Promise<schema.Face | undefined> {
  if (file.size > 1 * 1024 * 1024) {
    file = await reduceSize().toBlob(file, { max: 2000 });
  }

  const imageS3Key = await uploadImage(file, userId, s3Bucket, s3);
  const faceRecord = await indexImage(
    userId,
    imageS3Key,
    s3Bucket,
    rekognitionCollectionId,
    rekognition
  );

  await deleteImage(imageS3Key, s3Bucket, s3);

  if (faceRecord) {
    const faceImage = await cutUpFace(file, faceRecord);
    const faceS3Key = await uploadFace(
      faceImage,
      userId,
      railsCollectionId,
      s3Bucket,
      s3
    );
    const face = await createFaceOnRails(faceS3Key, faceRecord, axios);
    return face;
  }
}

// Uploads the image to S3
async function uploadImage(
  file: File | Blob,
  userId: number,
  s3Bucket: string,
  s3: S3Client
): Promise<string> {
  const key = `users/${userId}/${PROJECT_ID}/${crypto.randomUUID()}.jpg`;
  await upload(file, key, s3Bucket, s3);
  return key;
}

async function uploadFace(
  faceImage: Blob,
  userId: number,
  railsCollectionId: number,
  s3Bucket: string,
  s3: S3Client
): Promise<string> {
  const key = `collections/${railsCollectionId}/${userId}/${PROJECT_ID}/${crypto.randomUUID()}.jpg`;
  await upload(faceImage, key, s3Bucket, s3);
  return key;
}

async function upload(
  file: Blob | File,
  key: string,
  s3Bucket: string,
  s3: S3Client
) {
  const putObjectCommand = new PutObjectCommand({
    Bucket: s3Bucket,
    Key: key,
    Body: file,
    CacheControl: "max-age=315360000", // cache it for 10 years, since it's immutable
  });
  return s3.send(putObjectCommand);
}

// Adds the face to the Rekognition collection
async function indexImage(
  userId: number,
  s3Key: string,
  s3Bucket: string,
  rekognitionCollectionId: string,
  rekognition: RekognitionInterface | FaceEngineInterface
): Promise<FaceRecord | undefined> {
  // Must be unique, and must have the timestamp in the third position for orphaned face worker
  const externalImageId = `${userId}-dashboard-${new Date().getTime()}-${crypto.randomUUID()}`;

  const response = await rekognition.indexFaces({
    CollectionId: rekognitionCollectionId,
    MaxFaces: 1,
    ExternalImageId: externalImageId,
    Image: {
      S3Object: {
        Bucket: s3Bucket,
        Name: s3Key,
      },
    },
  });
  if (!response.FaceRecords) return;
  const faceRecord = response.FaceRecords[0];

  if (faceRecord) {
    const boundingBox = faceRecord.Face?.BoundingBox;
    if (!boundingBox) return;
    boundingBox.Left = Math.max(0, boundingBox.Left ?? 0);
    boundingBox.Top = Math.max(0, boundingBox.Top ?? 0);
    boundingBox.Width = Math.min(1, boundingBox.Width ?? 1);
    boundingBox.Height = Math.min(1, boundingBox.Height ?? 1);
    return faceRecord;
  }
}

async function cutUpFace(file: File | Blob, face: FaceRecord): Promise<Blob> {
  // This is in a worker because it's slow AF
  // Also CORS is screwed so we need to load it as a blob first
  // Also need to do the new URL thing so vite gets the correct URL in prod build
  const extractFaceWorkerUrl = new URL(
    "../entrypoints/extractFaceWorker.ts",
    import.meta.url
  ).href;
  const extractFaceWorker = await createWorker(extractFaceWorkerUrl);

  return new Promise((resolve, reject) => {
    extractFaceWorker.onmessage = (event) => {
      resolve(event.data);
    };
    extractFaceWorker.onerror = (event) => {
      reject(event);
    };
    extractFaceWorker.postMessage([file, face]);
  });
}

// Create the face on the rails backend.
// Here it will be saved as a temporary face with a remove_at, but shortly after it will be saved as a reference face
// This is so if validation fails, when too many faces exist on the person etc., it will get cleaned up
async function createFaceOnRails(
  s3Key: string,
  face: FaceRecord,
  axios: AxiosInstance
): Promise<schema.Face> {
  const response = await axios({
    method: "post",
    url: "api/faces.json",
    data: {
      s3Name: s3Key,
      rekognitionId: face.Face!.FaceId,
      confidence: face.Face!.Confidence,
      sharpness: face.FaceDetail!.Quality!.Sharpness,
    },
  });
  return response.data.face;
}

async function deleteImage(s3Key: string, s3Bucket: string, s3: S3Client) {
  const deleteObjectCommand = new DeleteObjectCommand({
    Bucket: s3Bucket,
    Key: s3Key,
  });
  return s3.send(deleteObjectCommand);
}
