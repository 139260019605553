import seedrandom from "seedrandom";

export function getRandomColor(
  seed: string,
  saturation: number = 0.75,
  lightness: number = 0.5,
  alpha: number = 1
) {
  const hue = seedrandom(seed).int32();
  return `hsla(${hue}, ${saturation * 100}%, ${lightness * 100}%, ${alpha})`;
}
