<template>
  <AdminLayout>
    <div class="flex flex-col">
      <div class="mb-4">
        <Filters
          :sort="modelSort"
          :search="search"
          :search-active="fetchStatus === 'fetching'"
        />
      </div>

      <div class="flex justify-end flex-wrap items-center gap-2 mb-4">
        <Btn color="primary" @click="initModelForm"
          ><i class="fa fa-plus-circle mr-1" /> Add new agency</Btn
        >
      </div>

      <FetchLoader :fetch-status="fetchStatus">
        <AdminTable
          :pagination="pagination"
          :max-per-page="250"
          @paginate="fetchAgencies"
        >
          <template #head>
            <TableHeader>Name</TableHeader>
            <TableHeader>Users</TableHeader>
            <TableHeader>People Records</TableHeader>
            <TableHeader>AI Processes</TableHeader>
            <TableHeader>Created</TableHeader>
          </template>

          <template #body>
            <AgencyRow
              v-for="agency in agencies"
              :key="agency.id"
              :agency="agency"
              :selected="modelForm?.id === agency.id"
              @select="selectModel(agency.id)"
            />
          </template>
        </AdminTable>
      </FetchLoader>
    </div>

    <template #sidebar v-if="modelForm">
      <NewAgency
        :agency-form="modelForm"
        v-if="modelForm && !modelForm.id"
        @create="handleCreate"
        @close="modelForm = null"
      />

      <EditAgency
        :agency-form="modelForm"
        v-if="modelForm && modelForm.id"
        @update="updateModel"
        @close="modelForm = null"
      />
    </template>
  </AdminLayout>
</template>

<script setup lang="ts">
import { onMounted, reactive, watch } from "vue";
import AgencyRow from "./Agencies/AgencyRow.vue";
import Filters from "./Agencies/Filters.vue";
import NewAgency from "./Agencies/NewAgency.vue";
import AdminLayout from "../global/AdminLayout.vue";
import AdminTable from "../global/AdminTable.vue";
import TableHeader from "../global/AdminTable/TableHeader.vue";
import useModelList from "../global/useModelList";
import { snakeCase } from "lodash";
import usePagination from "../global/usePagination";
import useFetchApi from "../global/useFetchApi";
import Btn from "../global/Btn.vue";
import EditAgency from "./Agencies/EditAgency.vue";
import { useUrlSearchParams } from "@vueuse/core";
import FetchLoader from "../global/FetchLoader.vue";

const {
  models: agencies,
  modelSort,
  modelForm,
  selectModel,
  createModel,
  updateModel,
  initModelForm,
} = useModelList<types.Agency>({
  sortFields: {
    createdAt: "Created",
    name: "Name",
    usersCount: "Users",
    peopleCount: "People Records",
  },
  initialSort: {
    field: "name",
    direction: "asc",
  },
  defaultModel: {
    name: "",
  },
});

const pagination = usePagination("SuperAdmin.Agencies");
const params = useUrlSearchParams();
const search = reactive<types.AgencySearch>({
  query: (params.agencyName as string) ?? "",
  exceedingLimits: "none",
});

const { fetchStatus, fetchApi } = useFetchApi();

const fetchAgencies = async () => {
  const data = await fetchApi({
    url: "/dashboard/super_admin/api/agencies.json",
    params: {
      ...search,
      page: pagination.value.currentPage,
      per: pagination.value.perPage,
      sortDirection: modelSort.value.direction,
      sortField: modelSort.value.field
        ? snakeCase(modelSort.value.field)
        : null,
    },
  });
  if (data) {
    agencies.value = data.agencies;
    pagination.value = data.pagination;
  }
};

onMounted(async () => {
  await fetchAgencies();

  if (params.agencyId) {
    selectModel(parseInt(params.agencyId as string));
  }
});

watch(
  [modelSort, search],
  () => {
    pagination.value.currentPage = 1;
    fetchAgencies();
  },
  { deep: true }
);

const handleCreate = (agency: types.Agency) => {
  createModel(agency);
  selectModel(agency.id);
};
</script>
