<template>
  <SidePanel id="selected-people">
    <PanelSection>
      <div class="text-lg mb-2">
        Bulk update
        <small class="text-sm text-gray-500"
          >{{ peopleIds.size }}
          {{ peopleIds.size > 1 ? "people" : "person" }}</small
        >
      </div>

      <FormErrors :errors="errors" />

      <FormControl>
        <template #label>
          <FieldLabel
            :active="activeFields.prefix"
            @toggle="
              activeFields.prefix = $event;
              form.prefix = '';
            "
            >Prefix</FieldLabel
          ></template
        >

        <input
          v-model="form.prefix"
          class="input"
          name="prefix"
          :disabled="!activeFields.prefix"
          :placeholder="
            activeFields.prefix ? 'Leave blank to remove prefix' : ''
          "
        />
      </FormControl>

      <FormControl>
        <template #label>
          <FieldLabel
            :active="activeFields.postfix"
            @toggle="
              activeFields.postfix = $event;
              form.postfix = '';
            "
            >Postfix</FieldLabel
          >
        </template>
        <input
          v-model="form.postfix"
          class="input"
          name="postfix"
          :disabled="!activeFields.postfix"
          :placeholder="
            activeFields.postfix ? 'Leave blank to remove postfix' : ''
          "
        />
      </FormControl>

      <FormControl>
        <template #label>
          <FieldLabel
            :active="activeFields.groupId"
            @toggle="
              activeFields.groupId = $event;
              form.groupId = undefined;
            "
            >Group</FieldLabel
          >
        </template>
        <GroupSelect
          v-model="form.groupId"
          :axios="axios"
          :groups="groups"
          :disabled="!activeFields.groupId"
          :placeholder="
            activeFields.groupId ? 'Leave blank to remove group' : ''
          "
        />
      </FormControl>
    </PanelSection>

    <template #buttons-left>
      <SaveBtn
        :saveStatus="saveStatus"
        @save="updateAll"
        :disabled="formClean"
      />
    </template>

    <template #buttons-right>
      <Btn
        @click="deleteAll"
        :busy="saveStatus === 'saving'"
        color="danger"
        fill="underline"
        size="small"
        >Delete selected</Btn
      >
    </template>
  </SidePanel>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import GroupSelect from "./SelectedPerson/GroupSelect.vue";
import { AxiosInstance } from "axios";
import Btn from "../../global/Btn.vue";
import FieldLabel from "./SelectedPeople/FieldLabel.vue";
import { pick } from "lodash";
import SidePanel from "../../global/SidePanel.vue";
import PanelSection from "../../global/SidePanel/PanelSection.vue";
import FormErrors from "../../global/FormErrors.vue";
import FormControl from "../../global/FormControl.vue";
import SaveBtn from "../../global/SaveBtn.vue";
import useModelApi from "../../global/useModelApi";

const props = defineProps<{
  axios: AxiosInstance;
  groups: types.Group[];
  peopleIds: Set<number>;
}>();

const emit = defineEmits<{
  update: [people: types.Person[]];
  remove: [peopleIds: Set<number>];
}>();

type BatchPerson = { prefix?: string; postfix?: string; groupId?: number };

const { saveStatus, errors, api } = useModelApi<BatchPerson>();

const form = ref<BatchPerson>({
  prefix: "",
  postfix: "",
  groupId: undefined,
});
const activeFields = ref<Record<keyof BatchPerson, boolean>>({
  prefix: false,
  postfix: false,
  groupId: false,
});

const formClean = computed(() => {
  return !Object.values(activeFields.value).some((v) => v);
});

const updateAll = async () => {
  const activeFieldKeys = Object.keys(activeFields.value).filter(
    (k) => activeFields.value[k as keyof BatchPerson]
  );
  const peopleData = pick(form.value, activeFieldKeys) as any;
  if (activeFields.value.groupId) {
    peopleData.groupIds = [form.value.groupId];
  }
  const data = {
    people_ids: Array.from(props.peopleIds),
    people_data: peopleData,
  };

  const responseData = await api({
    axios: props.axios,
    url: "/api/people/batch_update.json",
    method: "put",
    data,
  });

  if (responseData) {
    emit("update", responseData.people);
  }
};

const deleteAll = async () => {
  const confirmed = confirm(
    "Are you sure you want to delete all selected people?"
  );
  if (!confirmed) return;

  await api({
    axios: props.axios,
    method: "delete",
    url: `api/people/batch_destroy.json`,
    data: { peopleIds: Array.from(props.peopleIds) },
  });

  if (saveStatus.value === "success") {
    emit("remove", props.peopleIds);
  }
};
</script>
