<template>
  <SidePanel>
    <PanelSection>
      <template #title>Create Agency</template>

      <FormFields :agency-form="agencyForm" :errors="errors" />
    </PanelSection>

    <template #buttons-left>
      <SaveBtn
        :saveStatus="saveStatus"
        @save="create"
        :disabled="!agencyForm.name"
      />
    </template>

    <template #buttons-right>
      <Btn
        @click="emit('close')"
        :disabled="saveStatus === 'saving'"
        color="secondary"
        fill="underline"
        >Cancel</Btn
      >
    </template>
  </SidePanel>
</template>

<script setup lang="ts">
import SidePanel from "../../global/SidePanel.vue";
import SaveBtn from "../../global/SaveBtn.vue";
import Btn from "../../global/Btn.vue";
import PanelSection from "../../global/SidePanel/PanelSection.vue";
import useModelApi from "../../global/useModelApi";
import FormFields from "./FormFields.vue";

const props = defineProps<{
  agencyForm: types.Form<types.Agency>;
}>();

const emit = defineEmits<{
  (event: "close"): void;
  (event: "create", agency: types.Agency): void;
}>();

const { saveStatus, errors, api } = useModelApi<types.Agency>();

const create = async () => {
  const url = "dashboard/super_admin/api/agencies.json";
  const method = "post";
  const data = {
    agency: props.agencyForm,
  };

  const responseData = await api({ url, method, data });

  if (responseData) {
    const agency = responseData.agency;
    emit("create", agency);
  }
};
</script>
