<template>
  <label class="text-sm text-gray-500 flex justify-start gap-x-2 mb-1"
    ><slot />
    <Btn
      size="tiny"
      color="secondary"
      :fill="fill"
      @click="emit('toggle', !props.active)"
      class="activation-btn"
      >{{ text }}</Btn
    >
  </label>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Btn from "../../../global/Btn.vue";

const props = defineProps<{
  active: boolean;
}>();

const emit = defineEmits<{
  (e: "toggle", active: boolean): void;
}>();

const fill = computed(() => {
  return props.active ? "outline" : "filled";
});

const text = computed(() => {
  return props.active ? "Deactivate" : "Activate";
});
</script>
