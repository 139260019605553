<template>
  <AdminLayout :sidebar-width="384">
    <div class="flex flex-col">
      <div class="flex justify-between flex-wrap items-center gap-2 mb-4">
        <div class="flex justify-start gap-2">
          <Btn color="primary" @click="handleShowNewPersonSearch"
            ><i class="fa fa-plus-circle mr-1" /> Add new person</Btn
          >
          <Link
            color="secondary"
            fill="outline"
            href="/dashboard/agency_admin/people/bulk"
          >
            <ArrowUpTrayIcon class="w-4 h-4 mr-1" />
            Bulk add people</Link
          >
        </div>

        <div class="max-w-full w-96" data-name="PeopleSearchBox">
          <SearchBox
            @update:query="searchQuery = $event"
            :searching="searching"
          />
        </div>
      </div>

      <div class="mb-4 flex flex-wrap gap-2 justify-between">
        <Filters
          :group-ids="filteredGroupIds"
          :sort="sort"
          :groups="groups"
          :axios="axios"
          :people="people"
        />

        <LimitTracker
          v-if="agency"
          :count="agency.peopleCount"
          :limit="agency.peopleLimit"
          limit-tooltip="You have reached the face database limit for your current package. Please contact us using to upgrade if you require more capacity."
          >Total Records</LimitTracker
        >
      </div>

      <AdminTable
        :pagination="pagination"
        :max-per-page="100"
        @paginate="fetchPeople"
      >
        <template #head>
          <TableHeader
            class="py-1 px-0 text-center cursor-pointer hover:opacity-75 select-none"
            id="bulk-selection"
            @click="handleBulkSelectionClick"
          >
            <div
              class="relative w-5 h-5 ml-auto mr-auto border-gray-500 border-2 rounded px-px before:h-[2px] before:bg-gray-500 before:absolute before:left-0 before:right-0 before:top-[7px] before:mx-[3px]"
              v-if="selectedPeopleIds.size > 0"
            />
            <div
              v-else
              class="relative w-5 h-5 ml-auto mr-auto border-gray-500 border-2 rounded px-px flex justify-center items-center"
            >
              <PlusSmallIcon class="w-5 h-5" />
            </div>
          </TableHeader>
          <TableHeader
            class="pl-4 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
          ></TableHeader>
          <TableHeader>Name</TableHeader>
          <TableHeader>Prefix</TableHeader>
          <TableHeader>Postfix</TableHeader>
          <TableHeader>Groups</TableHeader>
          <TableHeader>First Seen</TableHeader>
          <TableHeader>Faces</TableHeader>
        </template>

        <template #body>
          <PersonRow
            v-for="person in visiblePeople"
            :key="person.id"
            :person="person"
            :all-faces="faces"
            :s3-bucket="s3Bucket"
            :s3="s3"
            :selected="selectedPersonId === person.id"
            :multi-selected="selectedPeopleIds.has(person.id)"
            :groups="groups"
            :selectable="selectedPeopleIds.size === 0"
            :filtered-group-ids="filteredGroupIds"
            @credentialsexpired="refreshCredentials"
            @select="selectedPersonId = person.id"
            @multiselect="handleMultiSelect(person.id, $event)"
          />
        </template>
      </AdminTable>
    </div>

    <template #sidebar>
      <SelectedPeople
        v-if="selectedPeople.length > 0"
        :axios="axios"
        :groups="groups"
        :people-ids="selectedPeopleIds"
        @update="handleBatchUpdate"
        @remove="handleBatchRemove"
      />
      <SelectedPerson
        v-else-if="selectedPerson ?? newPerson"
        :person="(selectedPerson ?? newPerson)!"
        :faces="facesForPerson(selectedPerson?.id)"
        :s3="s3"
        :s3-bucket="s3Bucket"
        :axios="axios"
        :user-id="userId"
        :rekognition-collection-id="rekognitionCollectionId"
        :rails-collection-id="railsCollectionId"
        :rekognition="rekognition"
        :groups="groups"
        @credentialsexpired="refreshCredentials"
        @save="handleSave"
        @remove="handleRemove"
      />
      <NewPerson
        v-else
        :s3="s3"
        :s3-bucket="s3Bucket"
        :axios="axios"
        @select="handleSave"
        @init="handleNewPerson"
      />
    </template>
  </AdminLayout>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, reactive, ref, watch } from "vue";
import createAxiosClient from "../../utility/axios_client";
import PersonRow from "./People/PersonRow.vue";
import s3Interface from "../../utility/s3Interface";
import SearchBox from "../global/SearchBox.vue";
import SelectedPerson from "./People/SelectedPerson.vue";
import { snakeCase } from "lodash";
import NewPerson from "./People/NewPerson.vue";
import Btn from "../global/Btn.vue";
import Filters from "./People/Filters.vue";
import { useLocalStorage } from "@vueuse/core";
import SelectedPeople from "./People/SelectedPeople.vue";
import { ArrowUpTrayIcon, PlusSmallIcon } from "@heroicons/vue/24/solid";
import AdminLayout from "../global/AdminLayout.vue";
import TableHeader from "../global/AdminTable/TableHeader.vue";
import AdminTable from "../global/AdminTable.vue";
import usePagination from "../global/usePagination";
import LimitTracker from "../global/LimitTracker.vue";
import useAwsCredentials from "./useAwsCredentials";
import Link from "../global/Link.vue";
import RekognitionInterface from "../../utility/rekognitionInterface";
import FaceEngineInterface from "~/utility/faceEngineInterface";

const props = defineProps<{
  tokenClient: string;
  accessToken: string;
  tokenUid: string;
  s3Bucket: string;
  initialAwsCredential: schema.AwsCredential;
  userId: number;
  rekognitionCollectionId: string;
  railsCollectionId: number;
  faceStorageUrl: string;
  faceEngineUrl: string;
  rekognitionCollectionKind: string;
  groups: types.Group[];
  agency?: types.Agency;
}>();
const axios = createAxiosClient({
  headers: {
    uid: props.tokenUid,
    client: props.tokenClient,
    accessToken: props.accessToken,
  },
});

const { awsCredential, refreshCredentials } = useAwsCredentials(
  props.initialAwsCredential,
  axios
);

const groups = ref<types.Group[]>(props.groups);

// People / Faces Fetching
const people = ref<types.Person[]>([]);
let faces = ref<schema.Face[]>([]);
let selectedPersonId = ref<number | null>(null);
let newPerson = ref<types.Form<types.Person> | null>();
const selectedPeopleIds = ref<Set<number>>(new Set());

const selectedPeople = computed(() => {
  return people.value.filter((person) =>
    selectedPeopleIds.value.has(person.id)
  );
});

const selectedPerson = computed(() => {
  return people.value.find((person) => person.id === selectedPersonId.value);
});

const handleMultiSelect = (personId: number, selected: boolean) => {
  if (selected) {
    if (selectedPersonId.value) {
      selectedPersonId.value = null;
    }
    selectedPeopleIds.value.add(personId);
  } else {
    selectedPeopleIds.value.delete(personId);
  }
};

const handleBulkSelectionClick = () => {
  if (selectedPeopleIds.value.size > 0) {
    selectedPeopleIds.value.clear();
  } else {
    selectedPeopleIds.value = new Set(people.value.map((p) => p.id));
  }
};

watch(selectedPerson, () => {
  if (!selectedPerson.value) {
    selectedPersonId.value = null;
  } else {
    newPerson.value = null;
  }
});

const fetchPeople = async () => {
  searching.value = true;
  try {
    const result = await axios({
      method: "get",
      url: "/dashboard/agency_admin/api/people.json",
      params: {
        query: searchQuery.value,
        page: pagination.value.currentPage,
        per: pagination.value.perPage,
        sortDirection: sort.direction,
        sortField: sort.field ? snakeCase(sort.field) : null,
        groupIds: filteredGroupIds.value,
      },
    });
    people.value = result.data.people;
    faces.value = result.data.faces;
    pagination.value = result.data.pagination;
  } finally {
    searching.value = false;
  }
};
onBeforeMount(fetchPeople);

const s3 = computed(() => {
  if (awsCredential.value) {
    return s3Interface(awsCredential.value as any, props.faceStorageUrl);
  } else {
    return undefined;
  }
});

const rekognition = computed(() => {
  if (awsCredential.value) {
    if (awsCredential.value.kind === "face_engine") {
      return new FaceEngineInterface(
        awsCredential.value as any,
        props.faceEngineUrl
      );
    } else {
      return new RekognitionInterface(awsCredential.value as any);
    }
  } else {
    return undefined;
  }
});

const handleSave = async (person: types.Person, newFaces: schema.Face[]) => {
  const index = people.value.findIndex((p) => p.id === person.id);
  if (index >= 0) {
    people.value[index] = person;
  } else {
    people.value.push(person);
    if (props.agency) props.agency.peopleCount++;
  }
  faces.value = faces.value.filter((f) => f.personId !== person.id);
  faces.value = faces.value.concat(newFaces);
  selectedPersonId.value = person.id;

  refreshGroups();
};

const handleRemove = async (personId: number) => {
  people.value = people.value.filter((p) => p.id !== personId);
  faces.value = faces.value.filter((f) => f.personId !== personId);
  if (selectedPersonId.value === personId) {
    selectedPersonId.value = null;
  }

  if (props.agency) props.agency.peopleCount--;
  refreshGroups();
};

const handleBatchUpdate = async (newPeople: types.Person[]) => {
  for (const person of newPeople) {
    const index = people.value.findIndex((p) => p.id === person.id);
    if (index >= 0) {
      people.value[index] = person;
    }
  }
  refreshGroups();
};

const handleBatchRemove = async (oldPeopleIds: Set<number>) => {
  for (const personId of oldPeopleIds) {
    people.value = people.value.filter((p) => p.id !== personId);
    faces.value = faces.value.filter((f) => f.personId !== personId);
  }

  selectedPeopleIds.value.clear();
  refreshGroups();
};

const handleShowNewPersonSearch = async () => {
  selectedPersonId.value = null;
  newPerson.value = null;
};

const handleNewPerson = async (name: string) => {
  newPerson.value = {
    name,
    groupMemberships: [],
    faceIds: [],
  };
};

// People data
const facesForPerson = (personId?: number) => {
  if (!personId) return [];
  return faces.value.filter(
    (face) => face.celebrityId === personId || face.personId === personId
  );
};

// Only show people that have a reference face. not just celebrity people
const visiblePeople = computed(() => {
  return people.value.filter((person) => {
    const personFaces = facesForPerson(person.id);
    return personFaces.some((face) => face.s3Name);
  });
});

// Filtering
const searchQuery = ref("");
const searching = ref(false);
watch(searchQuery, async () => {
  pagination.value.currentPage = 1;
  selectedPeopleIds.value.clear();
  await fetchPeople();
});

const sort = reactive<types.Sort<types.Person>>({
  field: null,
  direction: null,
  availableFields: {
    name: "Name",
    createdAt: "First seen",
    referenceFaceCount: "Reference face count",
  },
});
const filteredGroupIds = useLocalStorage(
  `${props.userId}:filtered-group-ids`,
  []
);

watch(
  [filteredGroupIds, sort],
  () => {
    selectedPeopleIds.value.clear();
    pagination.value.currentPage = 1;
    fetchPeople();
  },
  { deep: true }
);

const refreshGroups = async () => {
  const result = await axios({
    method: "get",
    url: "/api/groups",
  });
  if (result.data.groups) {
    groups.value.length = 0;
    groups.value.push(...result.data.groups);
  }
};

const pagination = usePagination("AgencyAdmin.People", 25);
watch(pagination, () => {
  selectedPeopleIds.value.clear();
});
</script>
