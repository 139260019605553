<template>
  <div
    class="border-l-[3px] py-2 text-sm space-y-1 mb-2 rounded px-4"
    :class="classes"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  color: types.Color;
}>();

const classes = computed(() => {
  return {
    "border-l-red-600 bg-red-200 text-red-900 ": props.color === "danger",
    "border-l-purple-600 bg-purple-200 text-purple-900 ":
      props.color === "primary",
    "border-l-gray-600 bg-gray-200 text-gray-900 ": props.color === "secondary",
  };
});
</script>
