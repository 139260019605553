<template>
  <TableRow :selectable="true" :selected="selected">
    <TableCell
      class="text-center cursor-pointer"
      @click.prevent.stop="emit('multiselect', !multiSelected)"
    >
      <input
        type="checkbox"
        :checked="multiSelected"
        class="cursor-pointer pointer-events-none accent-purple-500"
      />
    </TableCell>
    <TableCell class="flex items-center gap-1"
      >{{ user.email }}
      <Tag v-if="user.isAgencyAdmin" color="purple" size="sm">Admin</Tag>
      <Tag v-if="user.status === 'agency_disabled'" color="red" size="sm"
        >Disabled</Tag
      >
    </TableCell>
    <TableCell>{{ user.firstName }} {{ user.lastName }}</TableCell>
    <TableCell>{{
      user.agencyMaxProcessesPerMonth
        ? niceNumber(user.agencyMaxProcessesPerMonth)
        : "unlimited"
    }}</TableCell>
    <TableCell>{{ niceNumber(user.processCount ?? 0) }}</TableCell>
    <TableCell>
      <input
        type="checkbox"
        :checked="!!user.forceLockPeopleSetting"
        class="cursor-pointer pointer-events-none accent-purple-500 ml-2"
      />
    </TableCell>
    <TableCell>{{ lastActiveAt }}</TableCell>
    <TableCell>{{ timeAgo(user.createdAt) }}</TableCell>
  </TableRow>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { niceNumber } from "../../../utility/helpers";
import TableCell from "../../global/AdminTable/TableCell.vue";
import TableRow from "../../global/AdminTable/TableRow.vue";
import Tag from "../../global/Tag.vue";
import { timeAgo } from "../../../utility/time";

const props = defineProps<{
  user: types.AgencyUser;
  selected: boolean;
  multiSelected: boolean;
}>();

const emit = defineEmits<{
  (event: "multiselect", selected: boolean): void;
}>();

const lastActiveAt = computed(() => {
  if (props.user.lastActiveAt) {
    return timeAgo(props.user.lastActiveAt);
  } else {
    return "Never";
  }
});
</script>
