<template>
  <AdminLayout>
    <div class="flex flex-col gap-6 lg:grid lg:grid-cols-2">
      <AnalyticsChart
        label="Monthly Active Users"
        :loading="monthlyActiveUsersActive"
        :chart-data="monthlyActiveUsersData"
        :legend="false"
      />

      <AnalyticsChart
        label="AI Processes"
        :loading="aiProcessesActive"
        :chart-data="aiProcessesData"
        :legend="true"
      />

      <AnalyticsChart
        label="Rekognition AI Process Costs (last 12 months)"
        :loading="awsAiProcessCostsActive"
        :chart-data="awsAiProcessCostsData"
        :legend="true"
      />

      <AnalyticsTable
        label="Getty Image Out of Bounds Checks"
        :headers="[
          'Check Type',
          'Total',
          'Pending',
          'Success',
          'Failed',
          'Errored',
        ]"
        :data="outOfBoundsChecksData"
        :loading="requestIpChecksActive && imageLocationChecksActive"
      />
    </div>
  </AdminLayout>
</template>

<script setup lang="ts">
import AdminLayout from "../global/AdminLayout.vue";
import { Ref, computed, onMounted, ref } from "vue";
import createAxiosClient from "../../utility/axios_client";
import AnalyticsChart from "./Analytics/AnalyticsChart.vue";
import AnalyticsTable from "./Analytics/AnalyticsTable.vue";

const aiProcesses = ref<types.DateData | null>(null);
const aiProcessesActive = ref<boolean>(false);

onMounted(() => {
  fetchData("ai_processes", aiProcessesActive, aiProcesses);
});

const aiProcessesData = computed(() => {
  if (!aiProcesses.value || !awsAiProcessCosts.value) return;

  const allTimeAwsAiProcesses = aiProcesses.value.map(([month, _]) => {
    let amount = awsAiProcessCosts.value?.find(
      ([awsMonth, _]) => awsMonth === month
    )?.[1];
    if (amount) amount *= 1000;
    return [month, amount];
  });

  return {
    labels: aiProcesses.value.map((month) => month[0]),
    datasets: [
      {
        data: aiProcesses.value.map((month) => month[1]),
        backgroundColor: "#9f46fe11",
        borderColor: "#9f46febb",
        fill: "start",
        label: "Recorded AI Processes",
      },
      {
        data: allTimeAwsAiProcesses,
        backgroundColor: "#fbbf2411",
        borderColor: "#fbbf24bb",
        fill: "start",
        label: "AWS Rekognition Actual Processes",
      },
    ],
  };
});

const monthlyActiveUsers = ref<types.DateData | null>(null);
const monthlyActiveUsersActive = ref<boolean>(false);

onMounted(() => {
  fetchData(
    "monthly_active_users",
    monthlyActiveUsersActive,
    monthlyActiveUsers
  );
});

const monthlyActiveUsersData = computed(() => {
  if (!monthlyActiveUsers.value) return;
  return {
    labels: monthlyActiveUsers.value.map((month) => month[0]),
    datasets: [
      {
        data: monthlyActiveUsers.value.map((month) => month[1]),
        backgroundColor: "#9f46fe11",
        borderColor: "#9f46febb",
        fill: "start",
      },
    ],
  };
});

const awsAiProcessCosts = ref<types.DateData | null>(null);
const awsAiProcessCostsActive = ref<boolean>(false);

onMounted(() =>
  fetchData("aws_ai_process_costs", awsAiProcessCostsActive, awsAiProcessCosts)
);

const awsAiProcessCostsData = computed(() => {
  if (!awsAiProcessCosts.value) return;
  return {
    labels: awsAiProcessCosts.value.map((month) => month[0]),
    datasets: [
      {
        data: awsAiProcessCosts.value.map((month) => month[1]),
        backgroundColor: "#9f46fe11",
        borderColor: "#9f46febb",
        fill: "start",
        label: "Cost in dollars",
      },
    ],
  };
});

const requestIpChecks = ref<types.OutOfBoundsCheckAnalytics | null>(null);
const requestIpChecksActive = ref<boolean>(false);

onMounted(() =>
  fetchData("request_ip_checks", requestIpChecksActive, requestIpChecks)
);

const imageLocationChecks = ref<types.OutOfBoundsCheckAnalytics | null>(null);
const imageLocationChecksActive = ref<boolean>(false);

onMounted(() =>
  fetchData(
    "image_location_checks",
    imageLocationChecksActive,
    imageLocationChecks
  )
);

const outOfBoundsChecksData = computed(() => {
  const rows = [];
  if (requestIpChecks.value) {
    rows.push([
      "IP Address",
      requestIpChecks.value.all,
      requestIpChecks.value.pending,
      requestIpChecks.value.success,
      requestIpChecks.value.failed,
      requestIpChecks.value.error,
    ]);
  }

  if (imageLocationChecks.value) {
    rows.push([
      "Image GPS Location",
      imageLocationChecks.value.all,
      imageLocationChecks.value.pending,
      imageLocationChecks.value.success,
      imageLocationChecks.value.failed,
      imageLocationChecks.value.error,
    ]);
  }

  return rows;
});

const axios = createAxiosClient({
  baseUrl: "/dashboard/super_admin/api/analytics",
});
const fetchData = async (
  action: string,
  activeStatus: Ref<boolean>,
  container: any
) => {
  activeStatus.value = true;
  try {
    const result = await axios({
      method: "get",
      url: `${action}.json`,
    });
    container.value = result.data;
  } finally {
    activeStatus.value = false;
  }
};
</script>
