<template>
  <FormControl>
    <GroupSelect
      :groups="groups"
      :allowed-group-ids="allowedGroupIds"
      v-model="groupMembership.groupId"
      :axios="axios"
    />
  </FormControl>

  <template v-if="allowAffixes">
    <FormControl>
      <template #label>Group Prefix</template>
      <input
        v-model="groupMembership.prefix"
        class="input"
        name="group_membership[prefix]"
      />
    </FormControl>

    <FormControl>
      <template #label>Group Postfix</template>
      <input
        v-model="groupMembership.postfix"
        class="input"
        name="group_membership[postfix]"
      />
    </FormControl>

    <FormControl>
      <FullName
        :prefix="groupMembership.prefix"
        :name="person.name"
        :postfix="groupMembership.postfix"
      />
    </FormControl>
  </template>

  <FormControl class="flex justify-end">
    <Btn size="small" color="secondary" @click="emit('delete')">Remove</Btn>
  </FormControl>
</template>

<script setup lang="ts">
import type { AxiosInstance } from "axios";
import GroupSelect from "./GroupSelect.vue";
import FormControl from "../../../global/FormControl.vue";
import Btn from "../../../global/Btn.vue";
import FullName from "../../../global/FullName.vue";
import { computed } from "vue";

const props = defineProps<{
  axios: AxiosInstance;
  person: types.Form<types.Person>;
  groupMembership: types.Form<types.GroupMembership>;
  groups: types.Group[];
  personErrors?: types.FormErrors<types.Person>;
  allowAffixes: boolean;
}>();

const emit = defineEmits<{
  (event: "delete"): void;
}>();

const allowedGroupIds = computed(() => {
  return props.groups
    .filter((group) => {
      if (props.groupMembership.groupId === group.id) return true;

      return !props.person.groupMemberships!.some(
        (membership) => membership!.groupId === group.id
      );
    })
    .map((group) => group.id);
});
</script>
