<template>
  <div class="overflow-x-auto -my-2 -mx-4 sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr>
              <slot name="head" />
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <slot name="body" />
          </tbody>
        </table>

        <Pagination
          v-if="pagination"
          v-bind="pagination"
          :max-per-page="maxPerPage ?? 250"
          @pagechange="handlePageChange"
          @perchange="handlePerChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Pagination from "./Pagination.vue";

const props = defineProps<{
  pagination?: types.Pagination;
  maxPerPage?: number;
}>();

const emit = defineEmits<{
  (e: "paginate"): void;
}>();

const handlePageChange = (page: number) => {
  if (!props.pagination) return;
  props.pagination.currentPage = page;
  emit("paginate");
};

const handlePerChange = (perPage: number) => {
  if (!props.pagination) return;
  props.pagination.perPage = perPage;
  emit("paginate");
};
</script>
